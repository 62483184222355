/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import '../../styles/DashboardPage.scss';
import { Button, Table } from 'reactstrap';
import { DashboardPageProps } from './typings';
import { FirebaseReducer, FirestoreReducer, getFirebase, isLoaded } from 'react-redux-firebase';
import { Icon } from 'react-icons-kit';
import { RouteComponentProps } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { power } from 'react-icons-kit/feather';
// import CompletedClaims from './tiles/WhatsappCompletedClaims';
import Helmet from 'react-helmet';
import IncomingClaims from './tiles/IncomingClaims';
import LoadingPage from '../loading/LoadingPage';
import OutgoingClaims from './tiles/OutgoingClaims';
import React, { Component } from 'react';
import WhatsappAuthorized from './tiles/WhatsappAuthorized';
import WhatsappCompletedClaims from './tiles/WhatsappCompletedClaims';
import WhatsappOutgoingClaims from './tiles/WhatsappOutgoingClaims';
import WhatsappQuotePending from './tiles/WhatsappQuotePending';
import WhatsappQuoteReceived from './tiles/WhatsappQuoteReceived';

class DashboardPage extends Component<
  RouteComponentProps & FirebaseReducer.Reducer & DashboardPageProps
> {
  constructor(props: any) {
    super(props);
    this.logout = this.logout.bind(this);
  }
  logout() {
    this.props.firebase.logout();
  }
  render() {
    const { whatsappOutgoingClaims, whatsappQuotePending, whatsappCompletedClaims, whatsappAuthorized, whatsappQuoteReceived, partner, loading, profile } = this.props;
    if (loading) {
      return <LoadingPage />;
    }
    return (
      <div className="dashboard-page-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Quick Claims | Dashboard</title>
        </Helmet>
        <div className="user-container">
          Hi, {this.props.auth.displayName || this.props.auth.email}
          <Button className="logout-button" onClick={this.logout}>
            <Icon icon={power} />
          </Button>
        </div>
        <div className="dashboard-tile-container">
          <Table className="table table-borderless">
            {/*<colgroup>
               <col width="30%" />
                        <col width="70%" /> 
            </colgroup>*/}
            { profile.userType === 'Broker' ?
            (<tbody>
              <tr>
                <td>
                  <WhatsappOutgoingClaims whatsappclaims={whatsappOutgoingClaims} partner={partner} userProfile={profile} />
                </td>
                <td>
                  <WhatsappQuotePending whatsappclaims={whatsappQuotePending} partner={partner} userProfile={profile} />
                </td>
                <td>
                  <WhatsappQuoteReceived whatsappclaims={whatsappQuoteReceived} partner={partner} userProfile={profile} />
                </td>
              </tr>
            </tbody>)
            :
            (<tbody>
              <tr>
                <td>
                  <WhatsappOutgoingClaims whatsappclaims={whatsappOutgoingClaims} partner={partner} userProfile={profile} />
                </td>
                <td>
                  <WhatsappQuotePending whatsappclaims={whatsappQuotePending} partner={partner} userProfile={profile} />
                </td>
                <td>
                  <WhatsappQuoteReceived whatsappclaims={whatsappQuoteReceived} partner={partner} userProfile={profile} />
                </td>
              </tr>
              &nbsp;
              <tr>
                <td>
                  <WhatsappAuthorized whatsappclaims={whatsappAuthorized} partner={partner} userProfile={profile} />
                </td>
                <td>
                </td>
                <td>
                  <WhatsappCompletedClaims whatsappclaims={whatsappCompletedClaims} partner={partner} userProfile={profile} />
                </td>
              </tr>
            </tbody>)
            }            
          </Table>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  firestore: { data },
  firebase: { auth, profile },
}: {
  firestore: FirestoreReducer.Reducer;
  firebase: FirebaseReducer.Reducer;
}) => {
  const loading =
    !isLoaded(data.whatsappQuotePending) ||
    !isLoaded(data.whatsappOutgoingClaims) ||
    !isLoaded(data.whatsappCompletedClaims) ||
    !isLoaded(data.whatsappQuoteReceived) ||
    !isLoaded(data.whatsappAuthorized) ||
    !isLoaded(data.partner);
  const whatsappQuotePending = data.whatsappQuotePending;
  const whatsappOutgoingClaims = data.whatsappOutgoingClaims;
  const whatsappCompletedClaims = data.whatsappCompletedClaims;
  const whatsappQuoteReceived = data.whatsappQuoteReceived;
  const whatsappAuthorized = data.whatsappAuthorized;
  const partner = data.partner;
  return {
    auth,
    loading,
    profile,
    partner,
    whatsappQuotePending,
    whatsappOutgoingClaims,
    whatsappCompletedClaims,
    whatsappQuoteReceived,
    whatsappAuthorized,
    firebase: getFirebase(),
  };
};

export default compose(connect(mapStateToProps))(DashboardPage);
