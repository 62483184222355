/* eslint-disable prettier/prettier */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/named */
/* eslint-disable react/jsx-key */
import '../../../styles/Checkboxes.scss';
import '../../../styles/image-gallery.scss';
import "react-datepicker/dist/react-datepicker.css";
import 'react-image-gallery/styles/scss/image-gallery.scss';
import {
    Badge, Button, Card,
    CardBody,
    CardFooter,
    CardHeader, Col, Collapse, FormGroup, Input, Label, Row, Table
} from 'reactstrap';
import { IWhatsappClaimPayload } from '../../../../../typings';
import { IWhatsappsClaim, WhatsappQuotePendingSidePanelProps } from '../whatsapp-typing';
import { checkSquare, chevronDown, chevronUp, loader, x } from 'react-icons-kit/feather';
import { connect } from 'react-redux';
import { deleteClaim, updateWhatsappPendingClaim } from '../../../actions/update-claim';
import { toast } from 'react-toastify';
import { trash } from 'react-icons-kit/iconic/trash'
import CancelModal from '../../modals/CancelModal';
import DatePicker from "react-datepicker"
import EmptyState from '../empty-state/EmptyState';
import FileDropzone from '../side-panel/inputs/WhatsappFileDropzone';
import Icon from 'react-icons-kit';
import ImageGallery from "react-image-gallery";
import Modal from "../whatsapp-quotes-pending/addPartsModal";
import NotesComponent from '../shared/WhatsappNotes';
import React, { ReactElement, useEffect, useState } from "react";
import TableRows from '../whatsapp/inputs/TableRows';
import _ from 'lodash';
import arrowDown from "../../../images/icons8-chevron-down-48.png";
import arrowUp from "../../../images/icons8-chevron-up-48.png";
import history from '../../../helpers/history';
import moment from 'moment';
import qcIcon from "../../../images/QC.svg";

const updatetable: any = [];
let addRows: any = [];

const initialState: { loading: boolean; saving: boolean; hasChanges: boolean; isDisabled: boolean, cancelling: boolean } = {
    saving: false,
    hasChanges: false,
    loading: false,
    isDisabled: false,
    cancelling: false,
};

const WhatsappQuotePendingSidePanel: React.FC<WhatsappQuotePendingSidePanelProps> = ({
    claim,
    onClose,
    userProfile,
    updateWhatsappPendingClaim,
    deleteClaim
}) => {
    const claimSansAudit: IWhatsappClaimPayload = _.pick(claim, [
        'whatsappinsurer',
    ]);
    const [updatedClaim, updateClaimState] = useState({});
    const [state, updateState] = useState(initialState);
    const [modalOpen, setModalOpen] = useState(false);
    const [insuredEditOpen, setInsuredEditOpen] = useState(false);
    const [incidentEditOpen, setIncidentEditOpen] = useState(false);
    const [imagesEditOpen, setImagesEditOpen] = useState(false);
    const [damageEditOpen, setDamageEditOpen] = useState(false);
    const [noteEditOpen, setNoteEditOpen] = useState(false);
    const [insuredChatOpen, setInsuredChatOpen] = useState(true);
    const [incidentChatOpen, setIncidentChatOpen] = useState(true);
    const [imagesChatOpen, setImagesChatOpen] = useState(true);
    const [damageChatOpen, setDamageChatOpen] = useState(true);
    const [noteChatOpen, setNoteChatOpen] = useState(true);
    const [cost, setCost] = useState(0);
    const [totalCost, setTotalCost] = useState(0);
    const [vehicleMake, setVehicleMake] = useState('');
    const [vehicleModel, setVehicleModel] = useState('');

    const [glassDecription, setGlassDecription] = useState('');
    const [glassSizeThickness, setglassSizeThickness] = useState('');
    const [glassCrackeShattered, setglassCrackeShattered] = useState('');
    const [glassSignwriting, setglassSignwriting] = useState('');

    const [startDate, setStartDate] = useState(new Date('01/01/1900 12:00'));
    const [endDate, setEndDate] = useState(new Date());
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedDamagedOption, setSelectedDamagedOption] = useState("");

    const [tableRows, setTableRows] = useState(["0"]);
    const [counter, setCounter] = useState(0);

    const [rowsData, setRowsData] = useState([]);

    const [qNumber, setQuoteNumber] = useState(5);
    const [minVal1, setMinVal] = useState(0);
    const [maxVal1, setMaxVal] = useState(999999);

    const [isModalOpen, setModalOpen1] = useState(false);

    const [partNumber, setPartNumber] = useState('');
    const [partDescription, setPartDescription] = useState('');
    const [partQuantity, setPartQuantity] = useState(1);

    const [count, setCount] = useState(1);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(100);
    // const [updatetable, setUpdatetable] = useState<Array<any>>([]);

    const [partList, updatePartsList] = useState(addRows);

    // const [state, setState] = useState(initialState);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [reason, setReason] = useState('');
    const [cancelClaimLoading, setCancelClaimLoading] = useState(true);
    const [submitQuote, setSubmitQuote] = useState(true);

    const handleIncrementCounter = () => {
        if (count < maxValue) {
            setCount((prevState) => prevState + 1);
        }
    };

    const handleDecrementCounter = () => {
        if (count > minValue) {
            setCount((prevState) => prevState - 1);
        }
    };

    const IncrementDecrementBtn = (minValue = 0, maxValue = 100) => {
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#fff',
                borderRadius: '18px',
                overflow: 'hidden',
                border: '1px solid #F5F8FB',
                transition: 'border 0.3s ease'
            }} >
                <button style={{
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#4097fe',
                    backgroundColor: '#fff',
                    border: 'none',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease'
                }} type="button" onClick={handleIncrementCounter}>
                    <span className="material-symbols-outlined">+</span></button>
                <p style={{
                    width: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderLeft: '1px solid #F5F8FB',
                    borderRight: '1px solid #F5F8FB'
                }}>{count}</p>
                <button style={{
                    width: '30px',
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#4097fe',
                    backgroundColor: '#fff',
                    border: 'none',
                    cursor: 'pointer',
                    transition: 'all 0.3s ease'
                }} type="button" onClick={handleDecrementCounter}>
                    <span className="material-symbols-outlined">-</span>
                </button>
            </div>
        );
    };

    const collapseConfig = {
        show: 100,
        hide: 100,
    };

    useEffect(() => {
        if (claim?.whatsappinsurer?.name !== updatedClaim.whatsappinsurer?.name) {
            reset();
        }
        // we don't need exhaustive hook dep check, shut the linter up.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claim]);

    useEffect(() => {
        checkForChanges();
        // we don't need exhaustive hook dep check, shut the linter up.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedClaim]);

    const checkForChanges = (): boolean => {
        const claimSansAudit: IWhatsappClaimPayload = _.pick(claim, [
            'whatsappinsurer',
        ]);
        const hasChanges = !_.isEqual(updatedClaim, claimSansAudit);
        updateState({ ...state, hasChanges });
        return hasChanges;
    };

    const onChange = () => {
        state.isDisabled = true;
        state.hasChanges = true;
        const randumNumber = Math.floor(Math.random() * (maxVal1 - minVal1 + 1) + minVal1);
        // setQuoteNumber(randumNumber);
        const quoteNumber = `SO-${randumNumber}`;
        const updatedData =
        {
            addRows,
            totalCost,
            vehicleMake,
            vehicleModel,
            quoteNumber,
            glassDecription,
            glassSizeThickness,
            glassCrackeShattered,
            glassSignwriting
        };
        return updatedData;
    }

    const saveChanges = (e: any) => {
        if (vehicleMake !== undefined && vehicleModel !== undefined && vehicleMake !== '' && vehicleModel !== '') {
            const updatedClaim = onChange();
            updateState({ ...state, saving: true });
            setSubmitQuote(false);
            updateWhatsappPendingClaim(String(claim.id), updatedClaim, (err: Error) => {
                updateState({ ...initialState });
                updateState({ ...state, cancelling: false });
                setSubmitQuote(true);
                if (err) {
                    toast.error('Could not update claim. Please try again later');
                    console.error(err);
                    return;
                }
                onClose(e);
                const successMessage = (
                    <div className="toast-message">
                        <Icon icon={checkSquare} size={36} /> Successfully updated claim.
                    </div>
                );
                // history.push('/');
                return toast.success(successMessage, {
                    autoClose: 3000,
                    closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
                });
            });
        } else {
            const errorMessage = (
                <div className="toast-message">
                    <Icon icon={checkSquare} size={36} /> Kindly fill the mandetory data.
                </div>
            );
            return toast.error(errorMessage, {
                autoClose: 3000,
                closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
            });
        }
    };

    const reset = () => {
        setCost(0);
        setTotalCost(0);
        state.isDisabled = false;
        state.hasChanges = false;
    };

    const addTableRows = () => {
        const rowsInput = {
            partNumber: '',
            partDescription: '',
            cost: 0
        }
        setRowsData([...rowsData, rowsInput])
    }

    const deleteTableRows = (e: any, id: any) => {
        const array = [addRows];
        // console.log(array);

        if (addRows.length === 1) {
            addRows.splice(0, 1);
            const asd = array[0].filter((item: { id: Number; }) => item.id !== id);
            addRows = asd;
            updatePartsList(addRows);
            // const rowsUpdateTable = [...updatetable];
            // rowsUpdateTable.splice(0, 1);
            // updatetable = rowsUpdateTable;

            // updatetable = [];
            addRows = [];
            setTotalCost(0);
            state.isDisabled = false;
            state.hasChanges = false;
        } else {
            // setUpdatetable(array.filter((item: { id: any; }) => item.id !== index));
            // const name = e.target.getAttribute("id")
            const asd = array[0].filter((item: { id: Number; }) => item.id !== id);
            addRows = asd;
            updatePartsList(addRows);

            // const rowsUpdateTable = [...updatetable];
            // rowsUpdateTable.splice(id, 1);
            // updatetable = rowsUpdateTable;

            let grandTotal = 0;
            if (addRows.length > 0) {
                for (let i = 0; i < addRows.length; i++) {
                    if (addRows[i].cost !== 0 && addRows[i].cost !== '') {
                        grandTotal = grandTotal + Number(addRows[i].cost);
                        setTotalCost(Math.abs(grandTotal.toFixed(2)));
                    }
                }
            }
            // console.log(updatetable);
            // if (updatetable.length > 0) {
            //     for (let i = 0; i < updatetable.length; i++) {
            //         const asd = updatetable[i].props.children;
            //         for (let j = 0; j < asd.length; j++) {
            //             if (j === 3) {
            //                 grandTotal = grandTotal + Number(asd[3].props.children);
            //                 setTotalCost(grandTotal);
            //             }
            //         }
            //     }
            // }
            // setTotalCost(grandTotal);
        }

    }

    const handleChange = (index: any, evnt: any) => {
        const { name, value } = evnt.target;
        if (name === 'partNumber') {
            setPartNumber(value);
        }
        if (name === 'partDescription') {
            setPartDescription(value);
        }
        if (name === 'partQuantity') {
            setPartQuantity(value);
        }
        if (name === 'cost') {
            setCost(value);
        }
    }

    const grandTotal = () => {
        let grandTotal = 0;
        const rowTotals = rowsData.map(
            (row: { cost: any; }) => (row.cost) || 0
        );
        if (rowTotals.length > 0) {
            for (let j = 0; j < rowTotals.length; j++) {
                grandTotal = Number(grandTotal) + Number(rowTotals[j]);
            }
            //   grandTotal = rowTotals.reduce((acc, val) => acc + val);
        }
        return Number(grandTotal) + Number(cost);
    };

    const handleFirstRowChange = (index: string | number, evnt: { target: { name: any; value: any; }; }) => {

        const rowsInput = {
            partNumber: '',
            partDescription: '',
            cost: 0
        }
        setTableRows([...rowsData, rowsInput])

        const { name, value } = evnt.target;
        const rowsInputFirst = [rowsInput];
        rowsInputFirst[index][name] = value;
        setTableRows([rowsInputFirst])
        if (selectedOption === '') {
            setSelectedOption(rowsInputFirst[0].partNumber);
        }
        if (selectedDamagedOption === '') {
            setSelectedDamagedOption(rowsInputFirst[0].partDescription);
        }

        setCost(rowsInputFirst[0].cost);
        setTotalCost(Number(rowsInputFirst[0].cost));
    }

    const handleChangeVehicleMake = (event: { target: { value: any; }; }) => {
        setVehicleMake(event.target.value);
    };

    const handleChangeVehicleModel = (event: { target: { value: any; }; }) => {
        setVehicleModel(event.target.value);
    };

    const handleChangeGlassDecription = (event: { target: { value: any; }; }) => {
        setGlassDecription(event.target.value);
    };

    const handleChangeGlassSizeThickness = (event: { target: { value: any; }; }) => {
        setglassSizeThickness(event.target.value);
    };

    const handleChangeGlassCrackeShattered = (event: { target: { value: any; }; }) => {
        setglassCrackeShattered(event.target.value);
    };

    const handleChangeGlassSignwriting = (event: { target: { value: any; }; }) => {
        setglassSignwriting(event.target.value);
    };

    const showModalPopupAddParts = () => {
        setModalOpen1(true);
        setPartDescription('');
        setPartNumber('');
        setCost(0);
        setPartQuantity(1);
        setCount(1);
    }

    const saveParts = (partNumber: string | undefined, partDescription: string | undefined, partQuantity: number | undefined, cost: undefined) => {
        setPartQuantity(count);
        if (partNumber !== '' && partDescription !== '' && cost !== '') {
            const calculateTotalCostQuantity = parseFloat(Number(count) * Number(cost));
            // const asd = [];
            const rowsInput = {
                partNumber: partNumber,
                partDescription: partDescription,
                partQuantity: count,
                cost: calculateTotalCostQuantity,
            };
            addRows.push(rowsInput);
            // let total = 0; 
            let grandTotal = 0;
            for (let i = 0; i < addRows.length; i++) {
                addRows[i].id = i + 1;
                if (addRows[i].cost !== 0 && addRows[i].cost !== '') {
                    grandTotal = grandTotal + Number(addRows[i].cost);
                    setTotalCost(Math.abs(grandTotal.toFixed(2)));
                }
            }
            // asd.push(rowsInput);
            // updatetable = [];
            // for (let i = 0; i < addRows.length; i++) {
            //     addRows[i].id = i + 1;
            //     updatetable.push(retrnTableBody(addRows[i]));
            // }
            // setTotalCost(total);
            setModalOpen1(false);
            state.isDisabled = true;
            state.hasChanges = true;
        } else {
            const errorMessage = (
                <div className="toast-message">
                    <Icon icon={checkSquare} size={36} /> Kindly fill the mandetory data.
                </div>
            );
            return toast.error(errorMessage, {
                autoClose: 3000,
                closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
            });
        }
    }

    const retrnTableBody = (data: any) => {
        if (data !== undefined) {
            return (
                <tr key={data.id}>
                    <td style={{ width: '40%' }}>
                        {data.partNumber}
                    </td>
                    <td style={{ width: '40%' }}>
                        {data.partDescription}
                    </td>
                    <td style={{ width: '20%' }}>
                        {data.partQuantity}
                    </td>
                    <td style={{ width: '50%' }}>
                        {data.cost}
                    </td>
                    <td style={{ width: '50%' }}>
                        <button className="btn btn-outline-danger btn-sm" onClick={(event) => deleteTableRows(event, data.id)}>
                            x
                        </button>
                    </td>
                </tr>
            );
        }
    }

    const cancel = (e: any) => {
        if (!claim) {
            return false;
        }
        updateState({ ...state, cancelling: true });
        setCancelClaimLoading(false);
        deleteClaim(String(claim.id), reason, (err: Error) => {
            updateState({ ...state, cancelling: false });
            setCancelClaimLoading(true);
            onClose(e);
            if (err) {
                console.error(err);
                toast.error('Could not cancel claim, please try again later.');
                return;
            }
            const successMessage = (
                <div className="toast-message">
                    <Icon icon={checkSquare} size={36} /> Successfully Cancel the claim.
                </div>
            );
            // history.push('/');
            return toast.success(successMessage, {
                autoClose: 3000,
                closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
            });
        });
    };

    const showModal = () => {
        setModalOpen2(true);
    };

    const confirmCancel = () => {
        setModalOpen2(false);
        cancel(true);
    };

    const rejectCancel = () => {
        setModalOpen2(false);
    };

    const onChangeReason = (value: string) => {
        setReason(value);
    };

    const renderClaim = (claim: IWhatsappsClaim): ReactElement | false => {
        const profile = userProfile;
        const index = 0;
        let vinDisc = null;
        let vehicleFront = null;
        let driversLicense = null;
        let scensors = null;
        let damagePhotos = null;

        const partsList = partList;

        let breakageDate = null;
        if (claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.incidentDate !== undefined && claim.whatsappinsurer.incidentDate !== null) {
            breakageDate = moment(claim?.whatsappinsurer?.incidentDate).format("DD/MM/YYYY");
        }

        let damageImages: Array<string> = [];
        if (claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.damagePhotos !== undefined) {
            damageImages = claim.whatsappinsurer.damagePhotos;
        }

        if (claim !== undefined && claim.files !== undefined && claim.files.vin_disc !== undefined) {
            const vinDiscImage = claim.files.vin_disc[0];
            if (vinDiscImage !== undefined) {
                vinDisc = <img src={`${vinDiscImage.file}`} />
            } else {
                vinDisc = <img src={`${qcIcon}`} />
            }
        }
        if (claim !== undefined && claim.files !== undefined && claim.files.vehicle_front !== undefined) {
            const vehicleFrontImage = claim.files.vehicle_front[0];
            if (vehicleFrontImage !== undefined) {
                vehicleFront = <img src={`${vehicleFrontImage.file}`} />
            } else {
                vehicleFront = <img src={`${qcIcon}`} />
            }
        }
        if (claim !== undefined && claim.files !== undefined && claim.files.drivers_license !== undefined) {
            const driversLicenseImage = claim.files.drivers_license[0];
            if (driversLicenseImage !== undefined) {
                driversLicense = <img src={`${driversLicenseImage.file}`} />
            } else {
                driversLicense = <img src={`${qcIcon}`} />
            }
        }
        if (claim !== undefined && claim.files !== undefined && claim.files.scensors !== undefined) {
            const scensorsImage = claim.files.scensors[0];
            if (scensorsImage !== undefined) {
                scensors = <img src={`${scensorsImage.file}`} />
            } else {
                scensors = <img src={`${qcIcon}`} />
            }
        }
        if (claim !== undefined && claim.files !== undefined && claim.files.damage_photos !== undefined) {
            const damagePhotosImage = claim.files.damage_photos[0];
            if (damagePhotosImage !== undefined) {
                damagePhotos = <img src={`${damagePhotosImage.file}`} />
            } else {
                damagePhotos = <img src={`${qcIcon}`} />
            }
        }

        const images = [
            claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.licenceDisc !== undefined ?
                {
                    original: `${claim.whatsappinsurer.licenceDisc}`,
                    thumbnail: `${claim.whatsappinsurer.licenceDisc}`,
                }
                :
                claim !== undefined && claim.files !== undefined && claim.files.vin_disc !== undefined ?
                    {
                        original: vinDisc?.props.src,
                        thumbnail: vinDisc?.props.src,
                    }
                    :
                    {
                        original: `${qcIcon}`,
                        thumbnail: `${qcIcon}`,
                    },
            claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.vehicleFront !== undefined ?
                {
                    original: `${claim.whatsappinsurer.vehicleFront}`,
                    thumbnail: `${claim.whatsappinsurer.vehicleFront}`,
                }
                :
                claim !== undefined && claim.files !== undefined && claim.files.vehicle_front !== undefined ?
                    {
                        original: vehicleFront?.props.src,
                        thumbnail: vehicleFront?.props.src,
                    }
                    :
                    {
                        original: `${qcIcon}`,
                        thumbnail: `${qcIcon}`,
                    },
            claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.driversLicense !== undefined ?
                {
                    original: `${claim.whatsappinsurer.driversLicense}`,
                    thumbnail: `${claim.whatsappinsurer.driversLicense}`,
                }
                :
                claim !== undefined && claim.files !== undefined && claim.files.drivers_license !== undefined ?
                    {
                        original: driversLicense?.props.src,
                        thumbnail: driversLicense?.props.src,
                    }
                    :
                    {
                        original: `${qcIcon}`,
                        thumbnail: `${qcIcon}`,
                    },
            claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.scensor !== undefined ?
                {
                    original: `${claim.whatsappinsurer.scensor}`,
                    thumbnail: `${claim.whatsappinsurer.scensor}`,
                }
                :
                claim !== undefined && claim.files !== undefined && claim.files.scensors !== undefined ?
                    {
                        original: scensors?.props.src,
                        thumbnail: scensors?.props.src,
                    }
                    :
                    {
                        original: `${qcIcon}`,
                        thumbnail: `${qcIcon}`,
                    },
        ];

        if (claim !== undefined && claim.files !== undefined && claim.files.damage_photos !== undefined) {
            images.push({
                original: damagePhotos?.props.src,
                thumbnail: damagePhotos?.props.src,
            })
        } else if (damageImages !== null && damageImages !== undefined) {
            if (damageImages.length === 0) {
                images.push({
                    original: qcIcon,
                    thumbnail: qcIcon
                })
            } else {
                for (const index in damageImages) {
                    images.push({
                        original: damageImages[index],
                        thumbnail: damageImages[index]
                    })
                }
            }
        }

        if (!claim) {
            return false;
        }
        if (profile.userType !== 'Broker') {
            return (
                <div>
                    <div className="header-panel" >
                        <div>
                            <Button className="close-btn" onClick={(e) => onClose(e)}>
                                <Icon icon={x} size={28} />
                            </Button>
                            <h2>
                                {claim?.whatsappinsurer?.name}
                                <Badge className="status-badge">{claim?.status}</Badge>
                            </h2>
                        </div>
                    </div>
                    <div>
                        {state.isDisabled && state.hasChanges ? (
                            <div className={`position-fixed-btns edit-buttons ${state.hasChanges ? '' : 'disabled'}`}>
                                <button
                                    color="secondary"
                                    type="button"
                                    size="md"
                                    onClick={saveChanges}
                                    disabled={
                                        false
                                    }>
                                    {!submitQuote? 'Submiting...' : 'Submit Quote'}
                                    {!submitQuote && <Icon icon={loader} size={16} className="spin" />}
                                </button>
                            </div>
                        ) : null}
                    </div>
                    <div className="animated fadeIn">
                        <Row className="claim" style={{ display: 'flex', width: '100 %' }}>
                            <Col sm={6} className="todo-list-container">
                                <div className="static">
                                    <div className="todo-list">
                                        <h3>To Do List</h3>
                                        <CardBody>
                                            <FormGroup row className="my-0">
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <input className="checkbox" disabled type="checkbox" id="checkbox1" />
                                                        <label htmlFor="checkbox1">Confirm cost estimate</label>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <Input
                                                            value={totalCost}
                                                            type='number'
                                                            placeholder=""
                                                            className="form-checkbox"
                                                            required
                                                            disabled={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row className="my-0">
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <input className="checkbox" disabled type="checkbox" id="checkbox2" />
                                                        <label htmlFor="checkbox2">Claim Number</label>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <Input
                                                            type='text'
                                                            placeholder="Enter Claim Number"
                                                            className="form-checkbox"
                                                            required
                                                            disabled={true}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.underwriter !== undefined && claim.whatsappinsurer.underwriter !== 'GEMVAS' && profile.partnerId !== 'EXCLUSIVE' ?
                                                <FormGroup row className="my-0">
                                                    <Col xs="6" sm="6" md="6">
                                                        <FormGroup>
                                                            <input className="checkbox" disabled type="checkbox" id="checkbox3" />
                                                            <label htmlFor="checkbox3">Collect excess from client</label>
                                                            <span style={{ color: 'red' }}> (Excluding VAT)</span>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col xs="6" sm="6" md="6">
                                                        <FormGroup>
                                                            <Input
                                                                type='number'
                                                                placeholder="Enter Excess"
                                                                className="form-checkbox"
                                                                required
                                                                disabled={true}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </FormGroup>
                                                :
                                                null
                                            }
                                            <FormGroup row className="my-0">
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <input className="checkbox" disabled type="checkbox" id="checkbox4" />
                                                        <label htmlFor="checkbox4">Fitment date</label>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <div className="picker">
                                                            <DatePicker
                                                                showTimeSelect
                                                                dateFormat="dd/MM/yyyy h:mmaa"
                                                                selected={startDate}
                                                                selectsStart
                                                                startDate={startDate}
                                                                endDate={endDate}
                                                                onChange={(date: any) => setStartDate(date)}
                                                                showIcon={true}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row className="my-0">
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <input className="checkbox" disabled type="checkbox" id="checkbox5" />
                                                        <label htmlFor="checkbox5">Upload invoice</label>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <div id='fileUpload'>
                                                            <FileDropzone
                                                                loading={false}
                                                                onError={toast.error}
                                                                hideText={false}
                                                                onDrop={null}
                                                                className="drop-file-disabled"
                                                            />
                                                        </div>
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                        &nbsp;&nbsp;
                                        <FormGroup row className="my-0">
                                            <Col xs="4" sm="4" md="4"></Col>
                                            <Col xs="4" sm="4" md="4"></Col>
                                            <Col xs="4" sm="4" md="4">
                                                <FormGroup>
                                                    <button type="button" className="btn-danger" onClick={showModal}>
                                                        {!cancelClaimLoading ?
                                                            null :
                                                            <Icon icon={trash} size={20} />}
                                                        {!cancelClaimLoading ? 'Canceling...' : ' Cancel Claim'}
                                                        {!cancelClaimLoading && <Icon icon={loader} size={16} className="spin" />}
                                                    </button>
                                                </FormGroup>
                                            </Col>
                                        </FormGroup>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div>
                                    <Button
                                        className="accordion-btn"
                                        outline={insuredChatOpen ? false : true}
                                        size="sm"
                                        onClick={() => {
                                            setInsuredChatOpen(!insuredChatOpen);
                                            setInsuredEditOpen(!insuredEditOpen);
                                        }}>
                                        <div>
                                            Insured Info<Icon icon={insuredChatOpen ? chevronUp : chevronDown} size={20} />{' '}
                                        </div>
                                    </Button>
                                    <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={insuredChatOpen}>
                                        <CardBody>
                                            <FormGroup row className="my-0">
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Registration</Label>
                                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.registrationNumber !== undefined ? claim?.whatsappinsurer.registrationNumber : ''}
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="name" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer Name</Label>
                                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.name !== undefined ? claim?.whatsappinsurer.name : ''}
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row className="my-0">
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="customerEmail" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer Email Address</Label>
                                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.customerEmail !== undefined ? claim?.whatsappinsurer.customerEmail : ''}
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="mobileNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer preferred Contact No.</Label>
                                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.mobileNumber !== undefined ? claim?.whatsappinsurer.mobileNumber : ''}
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row className="my-0">
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="isLandlineNumberExist" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Does the Insured/Customer have a Landline No?</Label>
                                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isLandlineNumberExist !== undefined ? claim?.whatsappinsurer.isLandlineNumberExist : ''}
                                                    </FormGroup>
                                                </Col>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isLandlineNumberExist !== undefined ? (
                                                    <>
                                                        {claim.whatsappinsurer.isLandlineNumberExist === 'Yes' ?
                                                            (<Col xs="6" sm="6" md="6">
                                                                <FormGroup>
                                                                    <Label htmlFor="landlineNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer Landline No.</Label>
                                                                    {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.landlineNumber !== undefined ? claim?.whatsappinsurer.landlineNumber : ''}
                                                                </FormGroup>
                                                            </Col>)
                                                            : null}
                                                    </>
                                                )

                                                    : ('')
                                                }
                                            </FormGroup>
                                        </CardBody>
                                    </Collapse>
                                </div>
                                <div>
                                    <Button
                                        className="accordion-btn"
                                        outline={incidentEditOpen ? false : true}
                                        size="sm"
                                        onClick={() => {
                                            setIncidentEditOpen(!incidentEditOpen);
                                            setIncidentChatOpen(!incidentChatOpen);
                                        }}>
                                        <div>
                                            Incident Details<Icon icon={incidentEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                        </div>
                                    </Button>
                                    <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={incidentEditOpen}>
                                        <CardBody>
                                            <FormGroup row className="my-0">
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Date of Breakage (DD/MM/YYYY)</Label>
                                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.incidentDate !== undefined ? breakageDate : ''}
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Cause of Breakage</Label>
                                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.causeOfBreakage !== undefined ? claim?.whatsappinsurer.causeOfBreakage : ''}
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>

                                            <FormGroup row className="my-0">
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isDriversNameSameInsured !== undefined ? (
                                                    <>
                                                        {claim.whatsappinsurer.isDriversNameSameInsured !== 'Yes' ?
                                                            <><Col xs="6" sm="6" md="6">
                                                                <FormGroup>
                                                                    <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Is the driver’s name same as the insured?</Label>
                                                                    {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isDriversNameSameInsured !== undefined ? claim?.whatsappinsurer.isDriversNameSameInsured : ''}
                                                                </FormGroup>
                                                            </Col><Col xs="6" sm="6" md="6">
                                                                    <FormGroup>
                                                                        <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Driver Name</Label>
                                                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.driversName !== undefined ? claim?.whatsappinsurer.driversName : ''}
                                                                    </FormGroup>
                                                                </Col></>
                                                            : <Col xs="12" sm="12" md="12">
                                                                <FormGroup>
                                                                    <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Is the driver’s name same as the insured?</Label>
                                                                    {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isDriversNameSameInsured !== undefined ? claim?.whatsappinsurer.isDriversNameSameInsured : ''}
                                                                </FormGroup>
                                                            </Col>}
                                                    </>
                                                )
                                                    : (
                                                        ''
                                                    )}
                                            </FormGroup>

                                            <FormGroup row className="my-0">
                                                <Col xs="6" sm="6" md="6">
                                                    <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Was there a witness to the incident?</Label>
                                                    {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isWitnessToIncident !== undefined ? claim?.whatsappinsurer.isWitnessToIncident : ''}
                                                </Col>
                                            </FormGroup>

                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isWitnessToIncident !== undefined ? (
                                                <>
                                                    {claim.whatsappinsurer.isWitnessToIncident === 'Yes' ?

                                                        <><FormGroup row className="my-0">
                                                            <Col xs="6" sm="6" md="6">
                                                                <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Witness Name</Label>
                                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.witnessName !== undefined ? claim?.whatsappinsurer.witnessName : ''}
                                                            </Col>
                                                            <Col xs="6" sm="6" md="6">
                                                                <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Witness Mobile No.</Label>
                                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.witnessMobileNumber !== undefined ? claim?.whatsappinsurer.witnessMobileNumber : ''}
                                                            </Col>
                                                        </FormGroup></>
                                                        : null}
                                                </>
                                            )
                                                : (
                                                    ''
                                                )}
                                        </CardBody>
                                        <CardHeader style={{ textAlign: 'center', fontSize: '0.8em', color: 'black', fontWeight: 'bold' }}>
                                            Fill in the Details of Broken Glass (<span style={{ color: 'blue' }}>Optional</span>)
                                        </CardHeader>
                                        <CardBody>
                                            <div className="scrollbar-container sidebar-info-scrollbar ps">
                                                <div className="claim-group">
                                                    <Table className="sidepanel-info-table table table-borderless">
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Full decription of broken Glass</td>
                                                                <td>
                                                                    <Input
                                                                        value={glassDecription}
                                                                        type='text'
                                                                        placeholder="Enter glass decription"
                                                                        className="user-cost-table-input"
                                                                        required
                                                                        disabled={false}
                                                                        onChange={(glassDecription: { target: { value: any; }; }) => handleChangeGlassDecription(glassDecription)}
                                                                    />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Size and thickness in millimetres</td>
                                                                <td><Input
                                                                    value={glassSizeThickness}
                                                                    type='text'
                                                                    placeholder="Enter glass size and thickness"
                                                                    className="user-cost-table-input"
                                                                    required
                                                                    disabled={false}
                                                                    onChange={(glassSizeThickness: { target: { value: any; }; }) => handleChangeGlassSizeThickness(glassSizeThickness)}
                                                                /></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Is the Glass Cracked or shattered?</td>
                                                                <td>
                                                                    <select
                                                                        id="glassCrackeShattered"
                                                                        className="form-control"
                                                                        style={{
                                                                            backgroundColor: 'Azure',
                                                                            border: '1px groove #00A36C',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                        value={glassCrackeShattered}
                                                                        onChange={(glassCrackeShattered: { target: { value: any; }; }) => handleChangeGlassCrackeShattered(glassCrackeShattered)}
                                                                    >
                                                                        <option />
                                                                        <option value="Cracked" key="Cracked">Cracked</option>
                                                                        <option value="Shattered" key="Shattered">Shattered</option>
                                                                    </select>

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Any signwriting on broken glass?</td>
                                                                <td>
                                                                    <select
                                                                        id="glassSignwriting"
                                                                        className="form-control"
                                                                        style={{
                                                                            backgroundColor: 'Azure',
                                                                            border: '1px groove #00A36C',
                                                                            fontWeight: 'bold',
                                                                        }}
                                                                        value={glassSignwriting}
                                                                        onChange={(glassSignwriting: { target: { value: any; }; }) => handleChangeGlassSignwriting(glassSignwriting)}
                                                                    >
                                                                        <option />
                                                                        <option value="OEM Logo" key="OEM Logo">OEM Logo</option>
                                                                        <option value="None" key="None">None</option>
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Collapse>
                                </div>
                                <div>
                                    <Button
                                        className="accordion-btn"
                                        outline={imagesEditOpen ? false : true}
                                        size="sm"
                                        onClick={() => {
                                            setImagesEditOpen(!imagesEditOpen);
                                            setImagesChatOpen(!imagesChatOpen);
                                        }}>
                                        <div>
                                            Images<Icon icon={imagesEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                        </div>
                                    </Button>
                                    <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={imagesEditOpen}>
                                        <div className="scrollbar-container sidebar-info-scrollbar ps">
                                            <div className="claim-group">
                                                <Table className="sidepanel-info-table table table-borderless">
                                                    <tbody>
                                                        <tr>
                                                            <div className="image-gallery-wrapper">
                                                                <ImageGallery items={images} />
                                                            </div>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </Collapse>
                                </div>
                                <div>
                                    <Button
                                        className="accordion-btn"
                                        outline={damageEditOpen ? false : true}
                                        size="sm"
                                        onClick={() => {
                                            setDamageEditOpen(!damageEditOpen);
                                            setDamageChatOpen(!damageChatOpen);
                                        }}>
                                        <div>
                                            Damage Windscreen/Glass Details <Icon icon={damageEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                        </div>
                                    </Button>
                                    <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={damageEditOpen}>
                                        <CardBody>
                                            <FormGroup row className="my-0">
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Make<span className="text-danger">*</span></Label>
                                                        <Input
                                                            value={vehicleMake}
                                                            type='text'
                                                            placeholder="Enter vehicle make"
                                                            className="user-cost-table-input"
                                                            required
                                                            disabled={false}
                                                            onChange={(vehicleMake: { target: { value: any; }; }) => handleChangeVehicleMake(vehicleMake)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col xs="6" sm="6" md="6">
                                                    <FormGroup>
                                                        <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Model<span className="text-danger">*</span></Label>
                                                        <Input
                                                            value={vehicleModel}
                                                            type='text'
                                                            placeholder="Enter vehicle model"
                                                            className="user-cost-table-input"
                                                            required
                                                            disabled={false}
                                                            onChange={(vehicleModel: { target: { value: any; }; }) => handleChangeVehicleModel(vehicleModel)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                        <div style={{ paddingLeft: '380px' }}>
                                            <button onClick={() => showModalPopupAddParts()}>Add Parts</button>
                                            {isModalOpen && (
                                                <Modal onClose={() => setModalOpen1(false)}>
                                                    <div className="modal-header">
                                                        <center>
                                                            <h4 className="modal-title" id="myModalLabel">Add Parts</h4>
                                                        </center>
                                                    </div>
                                                    {/* <h2>Modal Title</h2>
                                                    <p>This is modal content.</p> */}
                                                    <div className="modal-body">
                                                        <div className="container-fluid">
                                                            <tr>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-4">
                                                                        <label className="control-label modal-label">Part #: </label>
                                                                        <span className="text-danger">*</span> </div>
                                                                    <div className="col-sm-8">
                                                                        <input
                                                                            type="text"
                                                                            value={partNumber}
                                                                            onChange={(evnt: any) => handleChange(index, evnt)}
                                                                            name="partNumber"
                                                                            className="form-control"
                                                                            disabled={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </tr>

                                                            <tr>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-4">
                                                                        <label className="control-label modal-label">Description: </label>
                                                                        <span className="text-danger">*</span> </div>
                                                                    <div className="col-sm-8">
                                                                        <input
                                                                            type="text"
                                                                            value={partDescription}
                                                                            onChange={(evnt: any) => handleChange(index, evnt)}
                                                                            name="partDescription"
                                                                            className="form-control"
                                                                            disabled={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </tr>

                                                            <tr>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-4">
                                                                        <label className="control-label modal-label">Quantity: </label>
                                                                        <span className="text-danger">*</span> </div>
                                                                    <div className="col-sm-8">
                                                                        {IncrementDecrementBtn(1, 25)}
                                                                    </div>
                                                                </div>
                                                            </tr>

                                                            <tr>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-4">
                                                                        <label className="control-label modal-label">Cost: </label>
                                                                        <span className="text-danger">*</span> </div>
                                                                    <div className="col-sm-8">
                                                                        <input
                                                                            type="number"
                                                                            value={cost}
                                                                            onChange={(evnt: any) => handleChange(index, evnt)}
                                                                            name="cost"
                                                                            className="form-control"
                                                                            disabled={false}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </tr>

                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-outline-dark" data-dismiss="modal" onClick={() => setModalOpen1(false)}><span className="fa fa-eject"></span> Cancel</button>
                                                                <button type="submit" className="btn btn-primary add-modal-waste" onClick={() => saveParts(partNumber, partDescription, partQuantity, cost)}><span className="fa fa-save"></span> Save</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            )}
                                        </div>
                                        <br />
                                        <div style={{
                                            width: "100%",

                                            overflowY: "scroll",
                                            maxHeight: "180px",
                                        }}>
                                            <Table className="main-edit-table" align="right">
                                                <thead>
                                                    <tr>
                                                        <th>Part Number</th>
                                                        <th>Description</th>
                                                        <th>Quantity</th>
                                                        <th>Cost <span style={{ color: 'red' }}> (Excluding VAT)</span></th>
                                                        <th>Delete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {/* {updatetable  ? updatetable : null} */}
                                                    {partsList.map((item: { partNumber: string | number | readonly string[] | undefined; partDescription: string | number | readonly string[] | undefined; partQuantity: string | number | readonly string[] | undefined; cost: string | number | readonly string[] | undefined; id: any; }, index: React.Key | null | undefined) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td style={{ width: '30%' }}>
                                                                    <input
                                                                        type="text"
                                                                        value={item.partNumber}
                                                                        // onChange={(evnt: any) => handleChange(index, evnt)}
                                                                        name="partNumber"
                                                                        className="form-control"
                                                                        disabled={true}
                                                                    />{' '}
                                                                </td>
                                                                <td style={{ width: '40%' }}>
                                                                    <input
                                                                        type="text"
                                                                        value={item.partDescription}
                                                                        // onChange={(evnt: any) => handleChange(index, evnt)}
                                                                        name="partDescription"
                                                                        className="form-control"
                                                                        disabled={true}
                                                                    />{' '}
                                                                </td>
                                                                <td style={{ width: '5%' }}>
                                                                    <input
                                                                        type="number"
                                                                        value={item.partQuantity}
                                                                        // onChange={(evnt: any) => handleChange(index, evnt)}
                                                                        name="cost"
                                                                        className="form-control"
                                                                        disabled={true}
                                                                    />{' '}
                                                                </td>
                                                                <td style={{ width: '50%' }}>
                                                                    <input
                                                                        type="number"
                                                                        value={item.cost}
                                                                        // onChange={(evnt: any) => handleChange(index, evnt)}
                                                                        name="cost"
                                                                        className="form-control"
                                                                        disabled={true}
                                                                    />{' '}
                                                                </td>
                                                                <td style={{ width: '20%' }}>
                                                                    <button className="btn btn-outline-danger btn-sm" onClick={() => deleteTableRows(index, item.id)}>
                                                                        x
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <br />
                                        <div>
                                            <Table style={{ width: '100%', overflow: 'auto' }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ width: 'auto' }}>
                                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Total Claim Cost</Label>
                                                        </td>
                                                        <td style={{ width: 'auto' }}>
                                                            <Input
                                                                value={totalCost}
                                                                type='number'
                                                                placeholder="Total Claim Cost"
                                                                className="user-cost-table-input"
                                                                required
                                                                disabled
                                                            />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Collapse>
                                </div>

                                <div>
                                    <Button
                                        className="accordion-btn"
                                        outline={noteEditOpen ? false : true}
                                        size="sm"
                                        onClick={() => {
                                            setNoteEditOpen(!noteEditOpen);
                                            setNoteChatOpen(!noteChatOpen);
                                        }}>
                                        <div>
                                            Claim Notes <Icon icon={noteEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                        </div>
                                    </Button>
                                    <Collapse delay={collapseConfig} isOpen={noteEditOpen}>
                                        <div className="static">
                                            <NotesComponent claim={claim} />
                                        </div>
                                    </Collapse>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            );
        } else {
            return <EmptyState message="Sorry, you are not authorized to view or generate the quote." />
        }
    };

    if (!claim) {
        return <div></div>;
    }

    return (
        <div>
            <CancelModal
                claim={claim}
                onConfirm={confirmCancel}
                onCancel={rejectCancel}
                isOpen={modalOpen2}
                reason={reason}
                onChangeReason={onChangeReason}
            />
            <div className={`side-panel-overlay ${claim ? 'open' : ''}`} ></div>
            <div id="incoming-side-panel" className={`side-panel ${claim ? 'open' : ''}`}>
                {claim ? renderClaim(claim) : false}
            </div>
        </div>
    );
};

export default connect(null, { updateWhatsappPendingClaim, deleteClaim })(WhatsappQuotePendingSidePanel);
