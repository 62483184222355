/* eslint-disable prettier/prettier */
import '../../../styles/InfoPanel.scss';
import { Button, Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { info, x } from 'react-icons-kit/feather';
import Modal from 'react-modal';
import PartnersDetails from './PartnersDetails';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useState } from 'react';
import classnames from 'classnames';
const modalStyle = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '80%',
    maxWidth: '1500px',
    transform: 'translate(-50%, -50%)',
  },
};

const InfoPanel: React.FC<InfoPanelProps> = ({ isOpen, toggle }) => {
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  // Toggle active state for Tab
  const _toggle = (tab: React.SetStateAction<string>) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  return (
    <Modal isOpen={isOpen} style={modalStyle} ariaHideApp={false} contentLabel="Example Modal">
      <PerfectScrollbar className="info-panel-container">
        <div
          style={{
            display: 'block',
            padding: 30,
          }}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === '1',
                })}
                onClick={() => {
                  _toggle('1');
                }}>
                Pricing Model
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === '2',
                })}
                onClick={() => {
                  _toggle('2');
                }}>
                Partner Information
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <div className="pricing-section">
                    <h3>1. International Jobs Pricing</h3>
                    <Row className="group">
                      <Col sm={4} className="labels">
                        <Label>&nbsp;</Label>
                        <Label>Purchase Price</Label>
                        <Label>Fitting price cars</Label>
                        <Label>Fitting price vans</Label>
                        <Label>Fitting price trucks</Label>
                        <Label>Fitting price rooftops/sunroof</Label>
                      </Col>
                      <Col sm={8} className="pricing-table">
                        <Row className="table-header blue">
                          <Col sm={4}>Windscreen/Rear Windows</Col>
                          <Col sm={4}>Side glass</Col>
                          <Col sm={4}>Rooftops/Sunroof</Col>
                        </Row>
                        <Row className="table-body">
                          <Col sm={4}>x 1,5</Col>
                          <Col sm={4}>x 1,5</Col>
                          <Col sm={4}></Col>
                          <Col sm={4}>87.86 €</Col>
                          <Col sm={4}>54.39 €</Col>
                          <Col sm={4}></Col>
                          <Col sm={4}>98.85 €</Col>
                          <Col sm={4}>54.92 €</Col>
                          <Col sm={4}></Col>
                          <Col sm={4}>164.75 €</Col>
                          <Col sm={4}>87.86 €</Col>
                          <Col sm={4}></Col>
                          <Col sm={4}></Col>
                          <Col sm={4}></Col>
                          <Col sm={4}>230.12 €</Col>
                        </Row>
                      </Col>
                    </Row>
                    {/* <Row className="group">
            <Col sm={4} className="labels">
              <Label>Replacement part – labour cost</Label>
            </Col>
            <Col sm={8} className="pricing-table">
              <Row className="table-body">
                <Col sm={4}></Col>
                <Col sm={4}></Col>
                <Col sm={4}>220 €</Col>                
              </Row>
            </Col>
          </Row> */}
                    <Row className="group">
                      <Col sm={4} className="labels">
                        <Label>ADAs Calibration cars/vans</Label>
                        <Label>ADAs Calibration trucks</Label>
                      </Col>
                      <Col sm={8} className="pricing-table">
                        <Row className="table-body">
                          <Col sm={4}>87.86 €</Col>
                          <Col sm={4}></Col>
                          <Col sm={4}></Col>
                          <Col sm={4}>164.75 €</Col>
                          <Col sm={4}></Col>
                          <Col sm={4}></Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="group example">
                      <Col sm={12} className="header">
                        <div className="heading">
                          <Icon icon={info} /> Example
                        </div>
                      </Col>
                      <Col sm={4} className="labels">
                        <Label>Purchase Price</Label>
                        <Label>Fitting price cars</Label>
                        <Label>ADAs Calibration</Label>
                        <Label>Fitting price rooftops/sunroof</Label>
                        <Label>&nbsp;</Label>
                      </Col>
                      <Col sm={8} className="pricing-table example">
                        <Row className="table-body">
                          <Col sm={4}>135.00 €</Col>
                          <Col sm={4}>202.50 €</Col>
                          <Col sm={4}>202.50 €</Col>
                          <Col sm={4}>&nbsp;</Col>
                          <Col sm={4}>87.86 €</Col>
                          <Col sm={4}>87.86 €</Col>
                          <Col sm={4}>&nbsp;</Col>
                          <Col sm={4}>87.86 €</Col>
                          <Col sm={4}>87.86 €</Col>
                          <Col sm={4}></Col>
                          <Col sm={4}></Col>
                          <Col sm={4}>230.12 €</Col>
                          <Col sm={4}>&nbsp;</Col>
                          <Col sm={4}>378.23 €</Col>
                          <Col sm={4}>608.34 €</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="pricing-section">
                    <h3>2. Delivering From Car Dealers</h3>
                    <Row className="group">
                      <Col sm={4} className="labels">
                        <Label>&nbsp;</Label>
                        <Label>Purchase Price</Label>
                        <Label>Fitting price cars</Label>
                        <Label>Fitting price vans</Label>
                        <Label>Fitting price trucks</Label>
                      </Col>
                      <Col sm={8} className="pricing-table">
                        <Row className="table-header red">
                          <Col sm={6}>Windscreen/Rear Windows</Col>
                          <Col sm={6}>Side glass</Col>
                        </Row>
                        <Row className="table-body">
                          <Col sm={6}>x 1,2</Col>
                          <Col sm={6}>x 1,2</Col>
                          <Col sm={6}>87.86 €</Col>
                          <Col sm={6}>54.92 €</Col>
                          <Col sm={6}>98.85 €</Col>
                          <Col sm={6}>54.92 €</Col>
                          <Col sm={6}>164.75 €</Col>
                          <Col sm={6}>87.86 €</Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="group">
                      <Col sm={4} className="labels">
                        <Label>ADAs Calibration cars/vans</Label>
                        <Label>ADAs Calibration trucks</Label>
                      </Col>
                      <Col sm={8} className="pricing-table">
                        <Row className="table-body">
                          <Col sm={6}>87.86 €</Col>
                          <Col sm={6}></Col>
                          <Col sm={6}>164.75 €</Col>
                          <Col sm={6}></Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="group example red">
                      <Col sm={12} className="header">
                        <div className="heading">
                          <Icon icon={info} /> Example
                        </div>
                      </Col>
                      <Col sm={4} className="labels">
                        <Label>Purchase Price</Label>
                        <Label>Fitting price cars</Label>
                        <Label>ADAs Calibration</Label>
                        <Label>&nbsp;</Label>
                      </Col>
                      <Col sm={8} className="pricing-table example">
                        <Row className="table-body">
                          <Col sm={6}>205.00 €</Col>
                          <Col sm={6}>246.00 €</Col>
                          <Col sm={6}>&nbsp;</Col>
                          <Col sm={6}>87.86 €</Col>
                          <Col sm={6}>&nbsp;</Col>
                          <Col sm={6}>87.86 €</Col>
                          <Col sm={6}>&nbsp;</Col>
                          <Col sm={6}>421.72 €</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                  <div className="pricing-section">
                    <h3>Other Services</h3>
                    <Row className="group example other">
                      <Col sm={4} className="labels">
                        <Label>Windscreen Repair Fee</Label>
                        <Label>Mobile Service</Label>
                        <Label>Emergency Glass (Plexiglass)</Label>
                      </Col>
                      <Col sm={8} className="pricing-table example">
                        <Row className="table-body">
                          <Col sm={6}>60.41 €</Col>
                          <Col sm={6}>(Max 2 repairs)</Col>
                          <Col sm={6}>27.46 €</Col>
                          <Col sm={6}>(30km from the nearest branch)</Col>
                          <Col sm={6}>32.95 €</Col>
                          <Col sm={6}>&nbsp;</Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <PartnersDetails></PartnersDetails>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </PerfectScrollbar>
      <Button className="modal-close-btn" onClick={() => toggle()}>
        <Icon icon={x} size={48} />
      </Button>
    </Modal>
  );
};

export default InfoPanel;
