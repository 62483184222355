/* eslint-disable prettier/prettier */
import 'firebase/auth';
import 'firebase/firestore'; // <- needed if using firestore
import 'firebase/functions';
import fb from 'firebase/app';

import { FirebaseOptions } from '@firebase/app-types';

const env = process.env.NODE_ENV;

const isDevDeploy = false;

// Replace this with your own config details
let appConfig: FirebaseOptions = {};

if (env === 'production' && !isDevDeploy) {
  appConfig = {
    apiKey: "AIzaSyAZEWn8JmAncA5Fm7vzLMeuiD7UQZxLbvg",
    authDomain: "botswanaclaim.firebaseapp.com",
    databaseURL: "https://botswanaclaim-cff48.firebaseio.com",
    projectId: "botswanaclaim",
    storageBucket: "botswanaclaim.appspot.com",
    messagingSenderId: "858365121250",
    appId: "1:858365121250:web:4bb8362cf54652f53984bb",
    measurementId: "G-VR2M5578VF"
  };
} else {
  appConfig = {
    apiKey: "AIzaSyAZEWn8JmAncA5Fm7vzLMeuiD7UQZxLbvg",
    authDomain: "botswanaclaim.firebaseapp.com",
    databaseURL: "https://botswanaclaim-cff48.firebaseio.com",
    projectId: "botswanaclaim",
    storageBucket: "botswanaclaim.appspot.com",
    messagingSenderId: "858365121250",
    appId: "1:858365121250:web:4bb8362cf54652f53984bb",
    measurementId: "G-VR2M5578VF"
  };
}

fb.initializeApp(appConfig);

// Init firestore
fb.firestore();

if (env !== 'production') {
  fb.functions().useFunctionsEmulator('http://localhost:5001');
}

export const firebase = fb;
export const auth = firebase.auth();
export const functions = fb.functions();
