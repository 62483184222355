/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import '../../styles/Sidebar.scss';
import { Button } from 'reactstrap';
import {
  FirebaseReducer,
  ReduxFirestoreQueries,
  WithFirebaseProps,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { IProfile } from '../claims/new/typings';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  arrowDownLeft,
  arrowUpRight,
  checkSquare,
  inbox,
  info,
  power,
  users,
} from 'react-icons-kit/feather';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {fingerprint} from 'react-icons-kit/entypo/fingerprint';
import {mobile} from 'react-icons-kit/entypo/mobile';
import {shield} from 'react-icons-kit/entypo/shield';
import {spinner6} from 'react-icons-kit/icomoon/spinner6'
import { trash } from 'react-icons-kit/iconic/trash'
import AsyncCSV from './AsyncCSV';
import Icon, { IconProp } from 'react-icons-kit';
import InfoPanel from '../modals/infopanel/InfoPanel';
import React, { Component, ReactElement } from 'react';
import _ from 'lodash';
import fleetIcon from "../../images/fleet.png";
import history from '../../helpers/history';
import qcIcon from "../../images/QC.svg";
import whatappIcon from "../../images/Whatsapp.svg";

const initialState: SideBarState = {
  isOpen: {
    infoPanel: false,
  },
};
class Sidebar extends Component<
  WithFirebaseProps<any> & RouteComponentProps & SidebarProps,
  SideBarState
> {
  constructor(props: any) {
    super(props);
    this.logout = this.logout.bind(this);
    this.state = { ...initialState };
    this.navigateTo = this.navigateTo.bind(this);
    this.toggleInfoPanel = this.toggleInfoPanel.bind(this);
    this.renderMeta = this.renderMeta.bind(this);
  }

  navigateTo(route: string) {
    return () => {
      history.push(route);
    };
  }

  renderMeta(label: string) {
    const metaPages = ["whatsappOutgoing", "whatsappQuotePending", "whatsappQuoteReceived", "whatsappAuthorized", "whatsappCompleted"];
    if (!_.includes(metaPages, label) || !this.props.ready) {
      return false;
    }
    const key = label as "whatsappOutgoing" | "whatsappQuotePending" | "whatsappQuoteReceived" | "whatsappAuthorized" | "whatsappCompleted";
    const counts = this.props[key];
    return (
      <div className="sidebar-btn-meta">
        <div className="meta-text">{counts.count} Claims</div>
        <div className="meta-text">{counts.unclaimed} Unassigned</div>
      </div>
    );
  }

  renderButton(
    icon: IconProp,
    label: string,
    color: string,
    action: Function,
    routes?: string[]
  ): ReactElement {
    const {
      match: { path },
    } = this.props;
    const activeClass = routes && _.includes(routes, path) ? "active" : "";
    return (
      <Button
        className={`sidebar-button ${activeClass}`}
        onClick={() => action()}
      >
        <Icon icon={icon} size={24} className="sidebar-btn-icon" />
        <div className="sidebar-btn-main">
          <div className="sidebar-btn-text">{label}</div>
          {this.renderMeta(label.toLowerCase())}
        </div>
      </Button>
    );
  }

  toggleInfoPanel() {
    const { isOpen } = this.state;
    this.setState({ isOpen: { ...isOpen, infoPanel: !isOpen.infoPanel } });
  }

  logout() {
    this.props.firebase.logout();
  }

  render() {
    const { isOpen } = this.state;

    if (!this.props.profile.partnerId) {
      return <></>;
    }

    return (
      <div>
        <button
          className="logo"
          onClick={
            this.navigateTo("/")
          }
        >
          <img src={qcIcon} alt="qc icon" style={{ borderRadius: '150px', width: '1090', height: '1090' }} />
          <h2>Dashboard</h2>
        </button>
        <div className="sidebar-buttons">
          <div className="spacer"></div>
          <InfoPanel isOpen={isOpen.infoPanel} toggle={this.toggleInfoPanel} />
          {this.props.profile.userType === "Admin" || this.props.profile.userType === "Autoboys" ? (
            <>
              {this.renderButton(
                mobile,
                "Whatsapp Sent",
                "default",
                this.navigateTo("/whatsapp-outgoing"),
                ["/whatsapp-outgoing"]
              )}
              {this.renderButton(
                spinner6,
                "Quotes Pending",
                "default",
                this.navigateTo("/whatsapp-quotes-pending"),
                ["/whatsapp-quotes-pending"]
              )}
              {this.renderButton(
                fingerprint,
                "Auth Required",
                "default",
                this.navigateTo("/whatsapp-quotes-received"),
                ["/whatsapp-quotes-received"]
              )}
              {this.renderButton(
                shield,
                "Authorized",
                "default",
                this.navigateTo("/whatsapp-authorized"),
                ["/whatsapp-authorized"]
              )}
              {this.renderButton(
                checkSquare,
                "Completed",
                "default",
                this.navigateTo("/whatsapp-completed"),
                ["/whatsapp-completed"]
              )}
              {this.renderButton(
                trash,
                "Canceled Claims",
                "default",
                this.navigateTo("/whatsapp-cancel"),
                ["/whatsapp-cancel"]
              )}
              {/* {this.renderButton(
                inbox,
                "All Jobs",
                "default",
                this.navigateTo("/all"),
                ["/all"]
              )} */}
            </>
          ) : (
            <>
              {this.renderButton(
                mobile,
                "Whatsapp Sent",
                "default",
                this.navigateTo("/whatsapp-outgoing"),
                ["/whatsapp-outgoing"]
              )}
              {this.renderButton(
                fingerprint,
                "Auth Required",
                "default",
                this.navigateTo("/whatsapp-quotes-received"),
                ["/whatsapp-quotes-received"]
              )}
              {this.renderButton(
                trash,
                "Canceled Claims",
                "default",
                this.navigateTo("/whatsapp-cancel"),
                ["/whatsapp-cancel"]
              )}
              {/* {this.renderButton(
                inbox,
                "All Jobs",
                "default",
                this.navigateTo("/all"),
                ["/all"]
              )} */}
              {/* <div className="spacer"></div>
              {this.renderButton(
                info,
                "Resources",
                "default",
                this.toggleInfoPanel
              )} */}
            </>
          )}
        </div>
        <div className="sidebar-bottom">
          {this.props.profile.partnerId &&
            (this.props.profile.userType === "Admin" || this.props.profile.userType === "Autoboys" || this.props.profile.userType === "Broker" || this.props.profile.userType === "Underwriter") ? (
            <>
              <Button
                className="new-claim-btn"
                color="new-claim"
                onClick={this.navigateTo("/manualClaim")}
              >
                <div className="btn-icon">
                  <img
                    src={fleetIcon}
                    alt="" />
                </div>
                <div className="sidebar-btn-text">Manual Claim
                </div>
              </Button>
              <Button
                className="new-claim-btn"
                color="new-claim"
                onClick={this.navigateTo("/whatsapp-journey")}
              >
                <div className="btn-icon">
                  <img
                    src={whatappIcon}
                    alt="" />
                </div>
                <div className="sidebar-btn-text">Whatsapp
                </div>
              </Button>
            </>
          ) : null}
          <div className="version">
            <div>
              {" "}
              {!this.props.isCompact ? "Quick Claims Dashboard v1.0.3" : "v1.0.3"}
            </div>
            {!this.props.isCompact ? (
              <Button className="btn btn-sm logout-btn" onClick={this.logout}>
                Logout
              </Button>
            ) : (
              <Button className="logout-button-sidebar" onClick={this.logout}>
                <Icon icon={power} />
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const preMapStateToProps = ({
  firebase: { profile },
}: {
  firebase: FirebaseReducer.Reducer;
}) => {
  return {
    profile: profile,
  };
};

const mapQueryToProps = ({
  profile,
}: {
  profile: IProfile;
}): ReduxFirestoreQueries => {
  if (!profile || !profile.isLoaded || profile.isEmpty) {
    return [];
  }

  if (profile.userType === 'Admin' || profile.userType === 'Autoboys') {
    return [
      {
        collection: 'partners',
        doc: profile.partnerId,
        storeAs: 'partner',
      },
      {
        collection: 'whatsappMessages',
        where: [
          // ['assign.partner', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'SENT'],
        ],
        storeAs: 'whatsappOutgoingClaims',
      },
      {
        collection: 'whatsappMessages',
        where: [
          // ['assign.partner', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'COMPLETED'],
        ],
        storeAs: 'whatsappCompletedClaims',
      },
      {
        collection: 'whatsappMessages',
        where: [
          // ['assign.partner', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'AUTHREQUIRED'],
        ],
        storeAs: 'whatsappQuoteReceived',
      },
      {
        collection: 'whatsappMessages',
        where: [
          // ['assign.partner', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'PENDING'],
        ],
        storeAs: 'whatsappQuotePending',
      },
      {
        collection: 'whatsappMessages',
        where: [
          // ['assign.partner', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'AUTHORIZED'],
        ],
        storeAs: 'whatsappAuthorized',
      },
      {
        collection: 'whatsappMessages',
        where: [
          ['active', '==', false],
        ],
        storeAs: 'whatsappCanceledClaims',
      },
    ];
  } else if (profile.userType === "Broker" || profile.userType === "Underwriter") {
    return [
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'SENT'],
        ],
        storeAs: 'whatsappOutgoingClaims',
      },
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'COMPLETED'],
        ],
        storeAs: 'whatsappCompletedClaims',
      },
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'AUTHREQUIRED'],
        ],
        storeAs: 'whatsappQuoteReceived',
      },
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'PENDING'],
        ],
        storeAs: 'whatsappQuotePending',
      },
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'AUTHORIZED'],
        ],
        storeAs: 'whatsappAuthorized',
      },
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['active', '==', false],
        ],
        storeAs: 'whatsappCanceledClaims',
      },
      {
        collection: 'config',
        doc: 'whatsapp',
        storeAs: 'whatsappConfig',
      },
      {
        collection: 'config',
        doc: 'whatsapptodos',
        storeAs: 'todosConfig',
      },
      {
        collection: 'partners',
        doc: profile.partnerId,
        storeAs: 'partner',
      },
    ];
  } else {
    return [
      {
        collection: 'partners',
        doc: profile.partnerId === 'Admin' ? profile.partnerId : profile.partnerId,
        storeAs: 'partner',
      },
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'SENT'],
        ],
        storeAs: 'whatsappOutgoingClaims',
      },
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'COMPLETED'],
        ],
        storeAs: 'whatsappCompletedClaims',
      },
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'AUTHREQUIRED'],
        ],
        storeAs: 'whatsappQuoteReceived',
      },
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'PENDING'],
        ],
        storeAs: 'whatsappQuotePending',
      },
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['active', '==', true],
          ['status', '==', 'AUTHORIZED'],
        ],
        storeAs: 'whatsappAuthorized',
      },
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['active', '==', false],
        ],
        storeAs: 'whatsappCanceledClaims',
      },
    ];
  }
};

const mapStateToProps = ({ firestore }: any) => {
  const whatsappOutgoingClaims = _.pickBy(
    firestore.data.whatsappOutgoingClaims,
    (c) => !_.isNull(c)
  );
  const whatsappCompletedClaims = _.pickBy(
    firestore.data.whatsappCompletedClaims,
    (c) => !_.isNull(c)
  );
  const whatsappQuotePending = _.pickBy(
    firestore.data.whatsappQuotePending,
    (c) => !_.isNull(c)
  );
  const whatsappQuoteReceived = _.pickBy(
    firestore.data.whatsappQuoteReceived,
    (c) => !_.isNull(c)
  );
  const whatsappAuthorized = _.pickBy(
    firestore.data.whatsappAuthorized,
    (c) => !_.isNull(c)
  );
  const ready = isLoaded(whatsappOutgoingClaims);
  let unclaimedWhatsappOutgoing = 0;
  let unclaimedWhatsappCompleted = 0;
  let unclaimedWhatsappQuotePending = 0;
  let unclaimedWhatsappQuoteReceived = 0;
  let unclaimedWhatsappAuthorized = 0;
  if (ready) {
    unclaimedWhatsappOutgoing = _.reduce(
      _.map(whatsappOutgoingClaims, (c) =>
        c?.assign.owners && !_.isString(c?.assign.owners.partner) ? 1 : 0
      ),
      (sum, n) => sum + n,
      0
    );
    unclaimedWhatsappCompleted = _.reduce(
      _.map(whatsappCompletedClaims, (c) =>
        c?.assign.owners && !_.isString(c?.assign.owners.partner) ? 1 : 0
      ),
      (sum, n) => sum + n,
      0
    );
    unclaimedWhatsappQuotePending = _.reduce(
      _.map(whatsappQuotePending, (c) =>
        c?.assign.owners && !_.isString(c?.assign.owners.partner) ? 1 : 0
      ),
      (sum, n) => sum + n,
      0
    );
    unclaimedWhatsappQuoteReceived = _.reduce(
      _.map(whatsappQuoteReceived, (c) =>
        c?.assign.owners && !_.isString(c?.assign.owners.partner) ? 1 : 0
      ),
      (sum, n) => sum + n,
      0
    );
    unclaimedWhatsappAuthorized = _.reduce(
      _.map(whatsappAuthorized, (c) =>
        c?.assign.owners && !_.isString(c?.assign.owners.partner) ? 1 : 0
      ),
      (sum, n) => sum + n,
      0
    );
  }
  return {
    ready,
    whatsappOutgoing: {
      count: _.size(whatsappOutgoingClaims),
      unclaimed: unclaimedWhatsappOutgoing,
    },
    whatsappCompleted: {
      count: _.size(whatsappCompletedClaims),
      unclaimed: unclaimedWhatsappCompleted,
    },
    whatsappQuotePending: {
      count: _.size(whatsappQuotePending),
      unclaimed: unclaimedWhatsappQuotePending,
    },
    whatsappQuoteReceived: {
      count: _.size(whatsappQuoteReceived),
      unclaimed: unclaimedWhatsappQuoteReceived,
    },
    whatsappAuthorized: {
      count: _.size(whatsappAuthorized),
      unclaimed: unclaimedWhatsappAuthorized,
    },
  };
};

export default compose<any>(
  withRouter,
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps)
)(Sidebar);
