/* eslint-disable prettier/prettier */
import { Badge, Button, Card,
  CardBody,
  CardFooter,
  CardHeader, Col, Collapse, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { IWhatsappsClaim, WhatsappOutgoingSidePanelProps } from '../whatsapp-typing';
import { chevronDown, chevronUp, x } from 'react-icons-kit/feather';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Icon from 'react-icons-kit';
import React, { ReactElement, useState } from 'react';
import WhatsappClaimInfoEdit from '../shared/WhatsappClaimInfoEdit';
import _ from 'lodash';

const WhatsappOutgoingSidePanel: React.FC<WhatsappOutgoingSidePanelProps> = ({
  claim,
  partner,
  onClose,
}) => {
  const [editOpen, setEditOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(true);

  const collapseConfig = {
    show: 100,
    hide: 100,
  };

  const renderClaim = (claim: IWhatsappsClaim): ReactElement | false => {
    const template = partner.claimTemplate.claims;
    if (!claim) {
      return false;
    }
    return (
      <div>
        <div className="header-panel">
          <div>
            <Button className="close-btn" onClick={(e) => onClose(e)}>
              <Icon icon={x} size={28} />
            </Button>
            <h2>
              {claim?.whatsappinsurer?.name}
              <Badge className="status-badge">{claim?.status}</Badge>
            </h2>
          </div>
        </div>
        <Row className="claim">
          <Col sm={12}>
            <div >
              <Button
                className="accordion-btn"
                outline={chatOpen ? false : true}
                size="sm"
                onClick={() => {
                  setEditOpen(!chatOpen);
                  setChatOpen(!editOpen);
                }}>
                <div>
                  Insured Info<Icon icon={chatOpen ? chevronUp : chevronDown} size={20} />{' '}
                </div>
              </Button>
              <Collapse delay={collapseConfig} className="re-send-edit-info-collapse" isOpen={chatOpen}>
                <WhatsappClaimInfoEdit
                  queue="destination"
                  groups={template}
                  whatsappclaim={claim}
                  isDisabled={false}
                />
                &nbsp;
              </Collapse>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  if (!claim) {
    return <div></div>;
  }

  return (
    <div>
      <div className={`side-panel-overlay-sent ${claim ? 'open' : ''}`}></div>
      <div id="incoming-side-panel" className={`side-panel-sent ${claim ? 'open' : ''}`}>
        {claim ? renderClaim(claim) : false}
      </div>
    </div>
  );
};

export default connect(null)(WhatsappOutgoingSidePanel);
