/* eslint-disable prettier/prettier */
import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import DateRangeValue from './reducers/DateRangeValue';
import NewClaimReducer from './reducers/NewClaimReducer';
import PaginationReducer from './reducers/Pagination';
import SearchClaimReducer from './reducers/SearchClaim';
import WhatsappClaimReducer from './reducers/WhatsappClaimReducer';

const rootReducer = combineReducers({
  newClaim: NewClaimReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  pagination: PaginationReducer,
  searchString: SearchClaimReducer,
  dateRangeValue: DateRangeValue,
  whatsappClaim: WhatsappClaimReducer,
});

export default rootReducer;
