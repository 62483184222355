/* eslint-disable prettier/prettier */
import { ActionWithAuth, ActionWithPayload, ActionWithProfile } from '../../typings';
import { IWhatsappClaimGroup, IWhatsappClaimGroupField } from '../../components/claims/whatsapp/typings';
import { IWhatsappClaimPayload } from '../../../../typings';
import { IWhatsappClaimReducer } from './typings';
import {
  WC_INIT_VALUE,
  WC_RESET_CLAIM,
  WC_UPDATE_VALUE,
} from '../../actions/whatsapp/types';
import { actionTypes as firebaseActionTypes } from 'react-redux-firebase';
import _ from 'lodash';

const validatePayload = (
  payload: IWhatsappClaimPayload, 
  validationGroup: IWhatsappClaimGroup,
): { [key: string]: string[] } => {
  const invalid: { [key: string]: string[] } = {};
  const validationGroupFields: IWhatsappClaimGroupField[] = validationGroup.fields;
  _.each(validationGroupFields, (field) => {
    const payloadEntryGroup: any = payload[validationGroup.key];
    const payloadEntry = payloadEntryGroup[field.key];
    if (field.required === true) {
      if (!_.isBoolean(payloadEntry) && !payloadEntry) {
        invalid[validationGroup.key] = _.isArray(invalid[validationGroup.key])
          ? invalid[validationGroup.key]
          : [];
        invalid[validationGroup.key] = [...invalid[validationGroup.key], field.key];
      }
    }
  });
  return invalid;
};

const validateEmpty = (valid: string[], empty: string[]): string[] => {
  const emptyItems = _.remove(empty, (e) => !_.includes(valid, e));
  return emptyItems;
};

const INIT: IWhatsappClaimReducer = {
  valid: [],
  invalid: {},
  empty: ['whatsappinsurer'],
  claim: {
    whatsappinsurer: {
      name: null,
      policyNumber: null,
      mobileNumber: null,
      underwriter: null,
      broker: null,
      authorized: false,
      email: null
    },
  },
};

export default function (state = { ...INIT }, action: ActionWithPayload) {
  switch (action.type) {
    case firebaseActionTypes.SET_PROFILE:
      const firebaseProfileAction = action as unknown as ActionWithProfile;
      return {
        ...state,
        claim: {
          ...state.claim,
          assign: {
            ...state.claim.whatsappinsurer,
            origin: firebaseProfileAction.profile.partnerId,
          },
        },
      };
    case firebaseActionTypes.LOGIN:
      const firebaseAuthAction = action as unknown as ActionWithAuth;
      return {
        ...state,
        claim: {
          ...state.claim,
          assign: {
            ...state.claim.whatsappinsurer,
            owners: {
              ...state.claim.whatsappinsurer,
              origin: firebaseAuthAction.auth.uid,
            },
          },
        },
      };

    case WC_UPDATE_VALUE:
      const {
        group,
        key,
        value,
        validation,
      }: {
        group: keyof IWhatsappClaimPayload;
        key: string;
        value: any;
        validation: IWhatsappClaimGroup;
      } = action.payload;
      const claim: IWhatsappClaimPayload = {
        ...state.claim,
        [group]: {
          ...state.claim[group],
          [key]: value,
        },
      };
      const invalidItems = validatePayload(claim, validation);
      const invalidRaw = {
        ...state.invalid,
        [group]: invalidItems[group],
      };

      const invalid: { [key: string]: string[] } = _.omitBy(invalidRaw, (i) => _.isEmpty(i)) as {
        [key: string]: string[];
      };
      const validGroup: string[] = _.uniq(
        _.compact(_.map(invalidRaw, (i, k) => (_.isEmpty(i) ? k : null))),
      );
      const valid = _.uniq([...state.valid, ...validGroup]);
      const empty = validateEmpty(valid, state.empty);

      return {
        ...state,
        valid: valid,
        invalid: invalid,
        claim: claim,
        empty: empty,
      };

    case WC_INIT_VALUE:
      const payload: {
        group: keyof IWhatsappClaimPayload;
        key: string;
        value: any;
        validation: IWhatsappClaimGroup;
      } = action.payload;
      const whatsappClaim: IWhatsappClaimPayload = {
        ...state.claim,
        [payload.group]: {
          ...state.claim[payload.group],
          [payload.key]: payload.value,
        },
      };
      return {
        ...state,
        claim: whatsappClaim,
      };

    case WC_RESET_CLAIM:
      return {
        ...state,
        ...INIT,
      };
      
    default:
      return { ...state };
  }
}
