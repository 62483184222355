/* eslint-disable prettier/prettier */
import { ActionWithAuth, ActionWithPayload, ActionWithProfile } from '../../typings';
import {
  CURRENCY_CHANGE_OBJ,
  CURRENCY_UPDATE_VALUE,
  NC_INIT_VALUE,
  NC_RESET_CLAIM,
  NC_UPDATE_VALUE,
} from '../../actions/new-claim/types';
import { IWhatsappClaimGroup, IWhatsappClaimGroupField } from '../../components/claims/whatsapp/typings';
import { IWhatsappClaimPayload } from '../../../../typings';
import { IWhatsappClaimReducer } from './typings';
import { actionTypes as firebaseActionTypes } from 'react-redux-firebase';
import { validateEmail } from '../../helpers/utils';
import _ from 'lodash';
import moment from 'moment';

const defaultCurrency = { label: 'EUR', value: 'EUR' };

const validatePayload = (
  payload: IWhatsappClaimPayload,
  validationGroup: IWhatsappClaimGroup,
): { [key: string]: string[] } => {
  const invalid: { [key: string]: string[] } = {};
  const validationGroupFields: IWhatsappClaimGroupField[] = validationGroup.fields;
  _.each(validationGroupFields, (field) => {
    const payloadEntryGroup: any = payload[validationGroup.key];
    const payloadEntry = payloadEntryGroup[field.key];
    if (field.required === true) {
      if (!_.isBoolean(payloadEntry) && !payloadEntry) {
        invalid[validationGroup.key] = _.isArray(invalid[validationGroup.key])
          ? invalid[validationGroup.key]
          : [];
        invalid[validationGroup.key] = [...invalid[validationGroup.key], field.key];
      } else if (field.type === 'Date' && !_.isDate(payloadEntry)) {
        invalid[validationGroup.key] = _.isArray(invalid[validationGroup.key])
          ? invalid[validationGroup.key]
          : [];
        invalid[validationGroup.key] = [...invalid[validationGroup.key], field.key];
      } else if (field.type !== 'Date' && typeof payloadEntry !== field.type) {
        invalid[validationGroup.key] = _.isArray(invalid[validationGroup.key])
          ? invalid[validationGroup.key]
          : [];
        invalid[validationGroup.key] = [...invalid[validationGroup.key], field.key];
      } else if (field.control === 'email' && !validateEmail(payloadEntry)) {
        invalid[validationGroup.key] = _.isArray(invalid[validationGroup.key])
          ? invalid[validationGroup.key]
          : [];
        invalid[validationGroup.key] = [...invalid[validationGroup.key], field.key];
      }
    }
  });
  return invalid;
};

const validateEmpty = (valid: string[], empty: string[]): string[] => {
  const emptyItems = _.remove(empty, (e) => !_.includes(valid, e));
  return emptyItems;
};

const INIT: IWhatsappClaimReducer = {
  valid: [],
  invalid: {},
  empty: ['whatsappinsurer', 'assign'],
  claim: {
    whatsappinsurer: {
      name: null,
      policyNumber: null,
      mobileNumber: null,
      broker: null,
      underwriter: null,
      email: null,
      authorized: false,
      authorizedValue: null,
      excessValue: null,
      confirmEstimate: [],
      totalEstimate: null,
      incidentDate: null,
      isDriversNameSameInsured: null,
      driversName: null,
      isWitnessToIncident: null,
      witnessName: null,
      witnessMobileNumber: null,
      registrationNumber: null,
      customerEmail: null,
      damageGlassCostDetails: null,
      partNumber: null,
      partDescription: null,
      licenceDisc: null,
      vehicleFront: null,
      isMultipleDamagedGlasses: null,
      driversLicense: null,
      damagePhotos: [],
      isLandlineNumberExist: null,
      landlineNumber: null,
      claimNumber: null,
      bookInDate: null,
      scensor: null,
      vehicleMake: null,
      vehicleModel: null,
      quoteNumber: null,
      causeOfBreakage: null
    },
    assign: {
      partner: null,
      origin: null,
      owners: {
        partner: null,
        origin: null,
      },
      notes: null
    }
  },
};

export default function (state = { ...INIT }, action: ActionWithPayload) {
  switch (action.type) {
    case firebaseActionTypes.SET_PROFILE:
      const firebaseProfileAction = action as unknown as ActionWithProfile;
      return {
        ...state,
        claim: {
          ...state.claim,
          assign: {
            ...state.claim.assign,
            origin: firebaseProfileAction.profile.partnerId,
          },
        },
      };
    case firebaseActionTypes.LOGIN:
      const firebaseAuthAction = action as unknown as ActionWithAuth;
      return {
        ...state,
        claim: {
          ...state.claim,
          assign: {
            ...state.claim.assign,
            owners: {
              ...state.claim.assign.owners,
              origin: firebaseAuthAction.auth.uid,
            },
          },
        },
      };

    case NC_UPDATE_VALUE:
      const {
        group,
        key,
        value,
        validation,
      }: {
        group: keyof IWhatsappClaimPayload;
        key: string;
        value: any;
        validation: IWhatsappClaimGroup;
      } = action.payload;
      const claim: IWhatsappClaimPayload = {
        ...state.claim,
        [group]: {
          ...state.claim[group],
          [key]: value,
        },
      };
      // const invalidItems = validatePayload(claim, validation);
      // const invalidRaw = {
      //   ...state.invalid,
      //   [group]: invalidItems[group],
      // };

      // const invalid: { [key: string]: string[] } = _.omitBy(invalidRaw, (i) => _.isEmpty(i)) as {
      //   [key: string]: string[];
      // };
      // const validGroup: string[] = _.uniq(
      //   _.compact(_.map(invalidRaw, (i, k) => (_.isEmpty(i) ? k : null))),
      // );
      // const valid = _.uniq([...state.valid, ...validGroup]);
      // const empty = validateEmpty(valid, state.empty);

      return {
        ...state,
        valid: true,
        invalid: false,
        claim: claim,
        // empty: empty,
      };

    case NC_INIT_VALUE:
      const payload: {
        group: keyof IWhatsappClaimPayload;
        key: string;
        value: any;
        validation: IWhatsappClaimGroup;
      } = action.payload;
      const newClaim: IWhatsappClaimPayload = {
        ...state.claim,
        [payload.group]: {
          ...state.claim[payload.group],
          [payload.key]: payload.value,
        },
      };
      return {
        ...state,
        claim: newClaim,
      };

    case NC_RESET_CLAIM:
      return {
        ...state,
        ...INIT,
      };

    default:
      return { ...state };
  }
}
