/* eslint-disable prettier/prettier */
import { Button, Col, Form, TabPane } from 'reactstrap';
import { IPartnerConfig } from '../../claims/whatsapp-typing';
import { IWhatsappClaimGroup, IWhatsappClaimGroupField, WhatsappClaimFormSectionProps } from '../whatsapp/typings';
import { IWhatsappClaimPayload } from '../../../../../typings';
import { connect } from 'react-redux';
import {
    initializeWhatsappClaimValue,
    resetClaim,
    submitWhatsappClaim,
    updateWhatsappClaimValue,
} from '../../../actions/whatsapp/index';
import { toast } from 'react-toastify';
import DropDownControl from './inputs/Dropdown';
import EmailInput from './inputs/EmailInput';
import PhoneNumber from './inputs/PhoneNumber';
import React, { ReactElement, useState } from 'react';
import Textbox from './inputs/Textbox';
import WhatsappConfirmModal from '../../modals/WhatsappConfirmModal';
import _ from 'lodash';
import history from '../../../helpers/history';
import moment from 'moment';

const initialState: { loading: boolean } = {
    loading: false,
};

const ClaimFormSection: React.FC<WhatsappClaimFormSectionProps> = ({
    initializeWhatsappClaimValue,
    resetClaim,
    submitWhatsappClaim,
    updateWhatsappClaimValue,
    id,
    group,
    whatsappClaim,
    next,
    prev,
    partner,
}) => {
    const [state, setState] = useState(initialState);
    const [modalOpen, setModalOpen] = useState(false);
    const [brokerValue, setBrokerValue] = useState('');
    const partnerDetails = partner;
    let broker = '';
    const submitClaim = (e: any) => {
        const claim = whatsappClaim.claim;
        setState({ loading: true });
        submitWhatsappClaim(claim, (err: Error) => {
            setState({ loading: false });
            resetClaim();
            if (err) {
                console.error(err);
                toast.error('Could not start whatsapp journey. Please try again later');
                return false;
            }
            onClose(e);
            // history.push('/');
            return true; 
        });
    };

    const confirmSubmit = () => {
        setModalOpen(false);
        submitClaim();
    };

    const showModal = async () => {
        setModalOpen(true);
    };

    const cancelSubmit = () => {
        setModalOpen(false);
    };

    const getValue = (
        value:
            | React.ChangeEvent<HTMLInputElement>
            | string
            | undefined
            | null,
        key: string,
        group: IWhatsappClaimGroup,
    ): any => {
        if (_.isUndefined(value)) {
            console.error(`No value to change for ${String(group.key)}|${key}`);
            return;
        }
        const payload: string | null = _.isString(value)
            ? value
            : value
                ? value.target.value
                : null;
        if (_.isString(payload) && _.last(payload) !== '.') {
            if (_.isNaN(payload)) {
                console.error(`Invalid value type for  ${String(group.key)}|${key}`);
            }
        }
        return payload;
    };

    const onChange = (
        value:
            | React.ChangeEvent<HTMLInputElement>
            | string
            | undefined
            | null,
        key: string,
        group: IWhatsappClaimGroup,
    ): void => {
        const payload = getValue(value, key, group);
        updateWhatsappClaimValue(group.key, key, payload, group);
    };

    const validateClaim = (): boolean => {
        whatsappClaim.claim.whatsappinsurer.broker = broker;
        const isValid = !state.loading && _.isEmpty(whatsappClaim.empty) && _.isEmpty(whatsappClaim.invalid);
        return !isValid;
    };

    const renderInput = (field: IWhatsappClaimGroupField, value: any): ReactElement | false => {
        switch (field.control) {
            case 'email':
                return (
                    <EmailInput
                        type="email"
                        value={value || ''}
                        field={field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, field.key, group)}
                    />
                );
            case 'string':
                // Text field
                return (
                    <Textbox
                        type="text"
                        value={value || ''}
                        field={field}
                        disabled={
                            (field.key === 'broker' ? true: false)
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, field.key, group)}
                    />
                );
            case 'phone':
                return (
                    <PhoneNumber
                        type="text"
                        value={value || ''}
                        field={field}
                        onChange={(phone) => onChange(phone, field.key, group)}
                    />
                );
            case 'select':
                return (
                    <DropDownControl
                        group={group}
                        onChange={onChange}
                        field={field}
                        value={value}
                        disabled={false}
                    />
                );
            default:
                // no idea, so let's play it safe;
                console.error(
                    `Invalid field control type ${field.control}, please check the type and try again`,
                );
                return false;
        }
    };

    return (
        <TabPane tabId={String(id)}>
            <Form row="true" className="claim-form-body row" autoComplete="off">
                {_.map(group.fields, (field) => {
                    // Get the value from the newClaim Reducer state that was passed down
                    const claim: IWhatsappClaimPayload = whatsappClaim.claim;
                    const partner: IPartnerConfig = partnerDetails;
                    const claimGroup: any = claim[group.key];
                    let value = claimGroup[field.key];
                    if (field.key === 'broker') {
                        if (whatsappClaim.claim.assign !== undefined) {
                            value = whatsappClaim.claim.assign.origin;
                            broker = value;
                        } else {
                            value = partner.partnerId;
                            broker = value;
                        }
                    }
                    if (field.key === 'underwriter') {
                        if (partner.partnerId !== undefined && partner !== undefined) {
                            let isGEMVASPresent = false;
                            if (partner.partnerId === 'EXCLUSIVE' || partner.partnerId === 'Autoboys' || partner.partnerId === 'Admin') {
                                for (let i=0; i<field.options?.length; i++) {
                                    const optionsValue = field.options[i];
                                    if (optionsValue === 'GEMVAS') {
                                        isGEMVASPresent = true;
                                    }
                                }
                                if (isGEMVASPresent === false) {
                                    field.options[4] = 'GEMVAS';
                                }                                
                            }
                        }
                    }
                    
                    const input = renderInput(field, value);
                    if (!input) {
                        return false;
                    }
                    return (
                        <Col key={`field-${field.key}`} md={6} xs={12}>
                            {input}
                        </Col>
                    );
                })}
            </Form>
            <div className="claim-form-buttons">
                {!next ? (
                    <WhatsappConfirmModal
                        whatsappclaim={whatsappClaim.claim}
                        onConfirm={confirmSubmit}
                        onCancel={cancelSubmit}
                        isOpen={modalOpen}
                    />
                ) : (
                    false
                )}
                <div className="claim-buttons">
                    {/* {renderSectionWarnings()} */}
                    <div>

                        <Button color="secondary" onClick={showModal} disabled={validateClaim()}>
                            {state.loading ? 'Sending...' : 'Send'}
                        </Button>
                    </div>
                </div>
            </div>
        </TabPane>
    );
};

export default connect(null, {
    initializeWhatsappClaimValue,
    resetClaim,
    submitWhatsappClaim,
    updateWhatsappClaimValue,
})(ClaimFormSection);
