/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
// import '../../../styles/IncomingClaimPage.scss';
import '../../../styles/ManualClaim.scss';
import { Badge, Button, Card, CardBody, Col, Collapse, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
// import { Button, Input } from 'reactstrap/lib';
import { DropdownOption } from '../new/typings';
import {
    FirebaseReducer,
    FirestoreReducer,
    ReduxFirestoreQueries,
    firestoreConnect,
    isLoaded,
} from 'react-redux-firebase';
import { ManualClaimPageProps, ManualClaimPageState, WhatsappIncomingPageState } from '../whatsapp-typing';
import { RouteComponentProps } from 'react-router';
import { authorizedOptions, statusOptions } from '../../../helpers/utils';
import { checkSquare, chevronDown, chevronUp, x } from 'react-icons-kit/feather';
import { compose } from 'redux';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import EmptyState from '../empty-state/EmptyState';
import FileDropzone from '../side-panel/inputs/WhatsappFileDropzone';
import HeaderComponent from '../shared/table/HeaderComponent';
import Helmet from 'react-helmet';
import Icon from 'react-icons-kit';
import ImageGallery from "react-image-gallery";
import LoadingPage from '../../loading/LoadingPage';
import NotesComponent from '../shared/WhatsappNotes';
import React, { Component } from 'react';
import WhatsappHeaderComponent from '../shared/table/WhatsappHeaderComponent';
import WhatsappOutgoingRow from '../shared/table/WhatsappOutgoingRow';
import WhatsappQuotesPendingSidePanel from '../side-panel/WhatsappQuotePendingSidePanel';

import ManualClaim from './ManualClaim';

import _ from 'lodash';

class ManualClaimPage extends Component<
    RouteComponentProps & FirebaseReducer.Reducer & ManualClaimPageProps,
    ManualClaimPageState
> {
    private static INITIAL_STATE: WhatsappIncomingPageState = {
        activeClaim: null,
      };

    constructor(props: any) {
        super(props);
        this.state = {
          ...ManualClaimPage.INITIAL_STATE,
        };
      }

      renderClaim = () => {
        const { claims, loading, partner, whatsapptodos, userProfile, partners } = this.props;
        return (
           <ManualClaim whatsapptodos={null} userProfile={userProfile} partner={partners} claim={undefined} onClose={function (event: any): void {
                throw new Error('Function not implemented.');
            } } uploadFile={undefined} cancelClaim={undefined} updateWhatsappPendingClaim={undefined}/>
          );
    }

    render() {
        return (            
        this.renderClaim()
        );
    }
}

const preMapStateToProps = (state: any) => {
    const {
        firebase: { profile },
        searchString,
    }: {
        firebase: FirebaseReducer.Reducer;
        searchString: { searchString: string };
    } = state;
    return {
        profile: profile,
        searchString,
    };
};

const mapQueryToProps = ({
    profile,
    searchString,
}: {
    searchString: { searchString: string },
    profile: any
}): ReduxFirestoreQueries => {
    if (!profile.isLoaded || profile.isEmpty) {
        return [];
    }
    if (searchString.searchString === '' || profile.partnerId === 'Admin') {
        return [
            {
                collection: 'whatsappMessages',
                where: [
                    // ['assign.origin', '==', profile.partnerId],
                    ['status', '==', 'PENDING'],
                    ['active', '==', true],
                ],
                // orderBy: ['_audit.created.timestamp', 'desc'],
            },
            {
                collection: 'config',
                doc: 'whatsapp',
                storeAs: 'whatsappConfig',
            },
            {
                collection: 'config',
                doc: 'whatsapptodos',
                storeAs: 'whatsappTodosConfig',
            },
            {
                collection: 'partners',
                doc: profile.partnerId,
                storeAs: 'partner',
            },
        ];
    } else {
        return [
            {
                collection: 'whatsappMessages',
                where: [
                    ['whatsappinsurer.name', '==', searchString.searchString],
                ],
                orderBy: ['_audit.created.timestamp', 'desc'],
            },
            {
                collection: 'whatsappMessages',
                where: [
                    ['whatsappinsurer.mobileNumber', '==', searchString.searchString],
                ],
                orderBy: ['_audit.created.timestamp', 'desc'],
                storeAs: 'claimsMobileNumber',
            },
            {
                collection: 'config',
                doc: 'whatsapptodos',
                storeAs: 'whatsappTodosConfig',
            },
            {
                collection: 'partners',
                doc: profile.partnerId,
                storeAs: 'partner',
            },
        ];
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    searchChange: (searchVal: string) => {
        dispatch({
            type: 'SEARCH_CHANGE',
            value: searchVal,
        });
    },
});

const mapStateToProps = (state: any) => {
    const {
        firestore,
        firebase: { auth },
        searchString,
        firebase: { profile },
    }: {
        firestore: FirestoreReducer.Reducer;
        firebase: FirebaseReducer.Reducer;
        pagination: { skip: number; take: number };
        searchString: { searchString: string };
    } = state;
    const userProfile = profile;
    const partner = firestore.data.partner;
    const partners = firestore.data.partners;
    let claims = firestore.data.whatsappMessages;
    if (searchString.searchString !== '') {
        if (firestore.data.claims) {
            claims = firestore.data.claims;
        } else if (firestore.data.claimsMobileNumber) {
            claims = firestore.data.claimsMobileNumber;
        }
    }
    const whatsappTodosConfig = firestore.data.whatsappTodosConfig;
    const loading = !isLoaded(claims) || !isLoaded(whatsappTodosConfig) || !isLoaded(partner);
    const whatsapptodos = null;
    // if (!loading) {
    //   const masterTodo = whatsappTodosConfig.whatsapptodos;
    //   const partnerConfig = partner.claimTemplate.whatsapptodos;
    //   whatsapptodos = _.assign({}, masterTodo, partnerConfig);
    // }
    return {
        loading,
        claims,
        whatsapptodos,
        partner,
        partners,
        userProfile,
        auth: auth as FirebaseReducer.AuthState,
    };
};

export default compose(
    connect(preMapStateToProps),
    firestoreConnect(mapQueryToProps),
    connect(mapStateToProps, mapDispatchToProps),
)(ManualClaimPage);
