/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import '../../../styles/IncomingClaimPage.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { Badge, Button, Input, Popover, PopoverBody, PopoverHeader } from 'reactstrap/lib';
import { DropdownOption, IProfile } from '../new/typings';
import {
  FirebaseReducer,
  FirestoreReducer,
  ReduxFirestoreQueries,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { RouteComponentProps } from 'react-router';
import { Table } from 'reactstrap';
import { WhatsappAllClaimsPageProps, WhatsappAllClaimsPageState, WhatsappIncomingPageState } from '../whatsapp-typing';
import { authorizedOptions, selectStyles, statusOptions } from '../../../helpers/utils';
import { calendar, check, download, loader, user, x } from 'react-icons-kit/feather';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { downloadClaimInfo } from '../../../actions/update-claim';
import CompletedSidePanel from '../side-panel/WhatsappCompletedSidePanel';
import DatePicker from 'react-datepicker';
import EmptyState from '../empty-state/EmptyState';
import Helmet from 'react-helmet';
import Icon from 'react-icons-kit';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingPage from '../../loading/LoadingPage';
import React, { Component } from 'react';
import Select, { ValueType } from 'react-select';
import WhatsappAllSidePanel from '../side-panel/WhatsappAllSidePanel';
import WhatsappHeaderComponent from '../shared/table/WhatsappHeaderComponent';
import WhatsappOutgoingRow from '../shared/table/WhatsappOutgoingRow';
import _ from 'lodash';
import moment from 'moment';

const claimsListCurrent = 0;

class AllClaimsPage extends Component<
  RouteComponentProps & FirebaseReducer.Reducer & WhatsappAllClaimsPageProps,
  WhatsappAllClaimsPageState
> {
  private static INITIAL_STATE: WhatsappIncomingPageState = {
    activeClaim: null,
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(AllClaimsPage.propKey(columnType, (event.target as any).value));
  }

  constructor(props: any) {
    super(props);
    this.state = {
      ...AllClaimsPage.INITIAL_STATE,
      statusFilter: { value: 'STATUS', label: 'ALL' },
      authorizedFilter: { value: 'Both', label: 'Both' },
      partnerFilter: { value: 'Partner', label: 'Partner' },
      countryFilter: { value: 'Country', label: 'Country' },
      searchStringVal: '',
      popoverOpen: false,
      dowloadLoading: {},
      isAdmin: false,
      partnerId: undefined,
    };
  }


  setStatusFilter = (value: DropdownOption) => {
    this.setState({ statusFilter: value });
  };

  public selectClaim(id: string | null) {
    this.setState(AllClaimsPage.propKey('activeClaim', id));
  }

  fetchMoreData = () => {
    const next = this.props?.pagination?.take || 0;
    typeof this.props.loadMore === 'function' && this.props.loadMore(next + 10);
  };

  
  setAuthorizedFilter = (value: DropdownOption) => {
    this.setState({ authorizedFilter: value });
  };

  onSearchBtnClick = () => {
    typeof this.props.searchChange === 'function' &&
      this.props.searchChange(this.state.searchStringVal || '');
  };

  onResetSearchBtnClick = () => {
    this.setState({ searchStringVal: '' });
    typeof this.props.searchChange === 'function' && this.props.searchChange('');
  };

  options = statusOptions.map((status: string) => {
    if (status === 'STATUS') {
      return {
        value: status,
        label: 'ALL',
      };
    }
    return {
      value: status,
      label: status,
    };
  });

  authorizedOpts = authorizedOptions.map((status: string) => {
    return {
      value: status,
      label: status,
    };
  });

  componentDidUpdate = () => {
    const { partnerId: partnerIdProfile = '' } = this.props?.profile || {};
    if (this.state.partnerId !== partnerIdProfile) {
      if (partnerIdProfile === 'Admin') {
        this.setState({ isAdmin: true, partnerId: partnerIdProfile });
      } else if (partnerIdProfile !== 'Admin') {
        this.setState({ isAdmin: false, partnerId: partnerIdProfile });
      }
    }
  };

  render() {
    const { claims, loading, partner, whatsapptodos } = this.props;
    const { activeClaim } = this.state;
    const claim = claims && activeClaim ? { ...claims[activeClaim], id: activeClaim } : null;
    if (loading) {
      return <LoadingPage />;
    }
    if (_.isEmpty(claims)) {
      return <EmptyState message="There are currently no claim requests" />;
    }

    let claimsList = _.pickBy(claims, (c) => {
      if (this.state.authorizedFilter.value === 'Authorized') {
        return c.whatsappinsurer.authorized === false;
      } else if (this.state.authorizedFilter.value === 'Unauthorized') {
        return c.whatsappinsurer.authorized === false;
      }
      return c;
    });

    claimsList = _.pickBy(claimsList, (c) => {
      if (this.state.statusFilter.value !== 'STATUS') {
        return c.status === this.state.statusFilter.value;
      }
      return c;
    });

    return (
      <div className={`incoming-claim-page-container ${activeClaim ? 'open' : 'closed'}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Quick Claims | Sent Jobs</title>
        </Helmet>
        <div className="search-div">
          <Input
            name="search"
            value={this.state.searchStringVal}
            placeholder="Search Claim"
            className="search-input"
            onChange={(event) => this.setState({ searchStringVal: event.target.value })}
          />
          <Button onClick={this.onSearchBtnClick} className="search-btn">
            Search
          </Button>
          <Button
            outline
            color="secondary"
            onClick={this.onResetSearchBtnClick}
            className="search-btn">
            Reset
          </Button>
        </div>
        <Table>
          <WhatsappHeaderComponent
            statusFilter={this.state.statusFilter}
            setStatusFilter={this.setStatusFilter}
            options={this.options}
            queue="Destination"
            authorizedOptions={this.authorizedOpts}
            authorizedFilter={this.state.authorizedFilter}
            setAuthorizedFilter={this.setAuthorizedFilter}
          />
          <tbody>
            {_.map(claimsList, (c, id: string) => (
              <WhatsappOutgoingRow
                key={`outgoing-row-${id}`}
                claim={c}
                onClick={() => this.selectClaim(id)}
              />
            ))}
          </tbody>
        </Table>
        <WhatsappAllSidePanel
          claim={claim}
          whatsapptodos={whatsapptodos}
          partner={partner}
          onClose={() => this.selectClaim(null)}/>
      </div>
    );
  }
}

const preMapStateToProps = (state: any) => {
  const {
    firebase: { profile },
    pagination,
    searchString,
    dateRangeValue,
  }: {
    firebase: FirebaseReducer.Reducer;
    pagination: { skip: number; take: number };
    searchString: { searchString: string };
    dateRangeValue: { toDate: string; fromDate: string; search: boolean };
  } = state;
  return {
    profile: profile,
    pagination,
    searchString,
    dateRangeValue,
  };
};

const mapQueryToProps = ({
  profile,
  pagination,
  searchString,
  dateRangeValue,
  firestore,
}: {
  profile: IProfile;
  pagination: { skip: number; take: number };
  searchString: { searchString: string };
  dateRangeValue: { toDate: string; fromDate: string; search: boolean };
  firestore: FirestoreReducer.Reducer;
}): ReduxFirestoreQueries => {
  if (!profile.isLoaded || profile.isEmpty) {
    return [];
  }
  let fromDate = moment('2000-01-01').toDate();
  let toDate = moment('2100-01-01').toDate();
  if (dateRangeValue.search) {
    fromDate = moment(dateRangeValue.fromDate).toDate();
    toDate = moment(dateRangeValue.toDate).toDate();
  }
  fromDate = firestore.Timestamp.fromDate(fromDate);
  toDate = firestore.Timestamp.fromDate(toDate);
  if (searchString.searchString === '') {
    return [
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['status', 'not-in', ['SENT']],
        //   ['active', '==', true],
        ],
        storeAs: 'allClaims',
      },
      {
        collection: 'config',
        doc: 'whatsapp',
        storeAs: 'whatsappConfig',
      },
      {
        collection: 'partners',
        storeAs: 'partners',
      },
    ];
  } else {
    return [
      {
        collection: 'whatsappMessages',
        where: [
          ['whatsappinsurer.name', '==', searchString.searchString],
        ],
        storeAs: 'allClaims',
      },
      {
        collection: 'whatsappMessages',
        where: [
          ['whatsappinsurer.mobileNumber', '==', searchString.searchString],
        ],
        storeAs: 'claimsMobileNumber',
      },
      { 
        collection: 'config',
        doc: 'whatsapptodos',
        storeAs: 'whatsappTodosConfig',
      },
      {
        collection: 'partners',
        doc: profile.partnerId,
        storeAs: 'partner',
      },
    ];
  }
};

const mapDispatchToProps = (dispatch: any) => ({
  loadMore: (skip: number) => {
    dispatch({
      type: 'LOAD_MORE',
      value: skip,
    });
  },
  searchChange: (searchVal: string) => {
    dispatch({
      type: 'SEARCH_CHANGE',
      value: searchVal,
    });
  },
  setToDateValue: (toDateValue: string) => {
    dispatch({
      type: 'SET_TO_DATE_VALUE',
      value: toDateValue,
    });
  },
  setFromDateValue: (fromDateValue: string) => {
    dispatch({
      type: 'SET_FROM_DATE_VALUE',
      value: fromDateValue,
    });
  },
  searchDateRange: (value: boolean) => {
    dispatch({
      type: 'SEARCH_DATE_RANGE',
      value: value,
    });
  },
});

const mapStateToProps = (state: any) => {
  const {
    firestore,
    firebase: { auth },
    pagination,
    searchString,
    dateRangeValue,
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
    pagination: { skip: number; take: number };
    searchString: { searchString: string };
    dateRangeValue: { toDate: string; fromDate: string; search: boolean };
  } = state;
  const partner = firestore.data.partner;
  const partners = firestore.data.partners;
  let claims = firestore.data.allClaims;
  if (searchString.searchString !== '') {
    if (firestore.data.allClaims) {
      claims = firestore.data.allClaims;
    } else if (firestore.data.claimsMobileNumber) {
      claims = firestore.data.claimsMobileNumber;
    }
  }
  const whatsappConfig = firestore.data.whatsappConfig;
  const loading = !isLoaded(claims) || !isLoaded(whatsappConfig) || !isLoaded(partners);
  const whatsapptodos = null;
  const partnerOptions: Array<{ value: string; label: string }> = [];
  const countryOptions: Array<{ value: string; label: string }> = [];
  // if (!loading) {
  //   const masterTodo = whatsappConfig.whatsapptodos;
  //   const partnerConfig = partner.claimTemplate.whatsapptodos;
  //   whatsapptodos = _.assign({}, masterTodo, partnerConfig);
  // }
  return {
    loading,
    claims,
    whatsapptodos,
    partner,
    partners,
    pagination,
    partnerOptions,
    countryOptions,
    dateRangeValue,
    auth: auth as FirebaseReducer.AuthState,
  };
};

export default compose(
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, mapDispatchToProps),
)(AllClaimsPage);
