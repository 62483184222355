/* eslint-disable prettier/prettier */
import { Button, Table } from 'reactstrap';
import { IWhatsappClaimGroup, IWhatsappClaimGroupField, WhatsappClaimInfoEditProps } from '../whatsapp/typings';
import { IWhatsappClaimPayload } from '../../../../../typings';
import { IWhatsappsClaim } from '../whatsapp-typing';
import { Icon } from 'react-icons-kit';
import { checkSquare } from 'react-icons-kit/feather';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { updateWhatsappClaim } from '../../../actions/update-claim';
import DropDownControl from '../whatsapp/inputs/Dropdown';
import EmailInput from '../whatsapp/inputs/EmailInput';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PhoneNumber from '../whatsapp/inputs/PhoneNumber';
import React, { ReactElement, useEffect, useState } from 'react';
import Textbox from '../new/ClaimForm/inputs/Textbox';
import _ from 'lodash';
import history from '../../../helpers/history';
import moment from 'moment';

const initialState: { saving: boolean; hasChanges: boolean } = {
    saving: false,
    hasChanges: false,
};

const WhatsappClaimInfoEdit: React.FC<WhatsappClaimInfoEditProps> = ({
    queue,
    groups,
    whatsappclaim,
    updateWhatsappClaim,
    isDisabled,
}) => {
    const claimSansAudit: IWhatsappClaimPayload = _.pick(whatsappclaim, [
        'whatsappinsurer',
    ]);
    const [updatedClaim, updateClaimState] = useState({ ...claimSansAudit });
    const [state, updateState] = useState(initialState);

    useEffect(() => {
        if (whatsappclaim?.whatsappinsurer?.name !== updatedClaim.whatsappinsurer.name) {
            reset();
        }
        // we don't need exhaustive hook dep check, shut the linter up.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [whatsappclaim]);

    useEffect(() => {
        checkForChanges();
        // we don't need exhaustive hook dep check, shut the linter up.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedClaim]);

    const saveChanges = () => {
        updateState({ ...state, saving: true });
        updateWhatsappClaim(String(whatsappclaim.id), updatedClaim, (err: Error) => {
            updateState({ ...initialState });
            if (err) {
                toast.error('Could not re-start the whatsapp journey. Please try again later');
                console.error(err);
                return;
            }
            const successMessage = (
                <div className="toast-message">
                    <Icon icon={checkSquare} size={36} /> Successfully Start the Whatsapp Journey.
                </div>
            );
            history.push('/');
            return toast.success(successMessage, {
                autoClose: 3000,
                closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
            });
        });
    };

    const reset = () => {
        const claimSansAudit: IWhatsappClaimPayload = _.pick(whatsappclaim, [
            'whatsappinsurer',
        ]);
        updateClaimState({
            ...updatedClaim,
            ...claimSansAudit,
        });
    };

    const checkForChanges = (): boolean => {
        const claimSansAudit: IWhatsappClaimPayload = _.pick(whatsappclaim, [
            'whatsappinsurer',
        ]);
        const hasChanges = !_.isEqual(updatedClaim, claimSansAudit);
        updateState({ ...state, hasChanges });
        return hasChanges;
    };

    const onChange = (
        value:
            | React.ChangeEvent<HTMLInputElement>
            | moment.Moment
            | string
            | boolean
            | undefined
            | null,
        key: string,
        group: IWhatsappClaimGroup,
        isNumber = false,
    ): void => {
        if (_.isUndefined(value)) {
            console.error(`No value to change for ${String(group.key)}|${key}`);
            return;
        }
        let payload: string | boolean | number | null | Date = moment.isMoment(value)
            ? value.toDate()
            : _.isBoolean(value)
                ? value
                : _.isString(value)
                    ? value
                    : value
                        ? value.target.value
                        : null;
        if (isNumber) {
            payload = Number(payload);
            if (_.isNaN(payload)) {
                console.error(`Invalid value type for  ${String(group.key)}|${key}`);
            }
        }
        updateClaimState({
            ...updatedClaim,
            [group.key]: {
                ...updatedClaim[group.key],
                [key]: payload,
            },
        });
    };

    const renderInput = (
        field: IWhatsappClaimGroupField,
        value: any,
        group: IWhatsappClaimGroup,
    ): ReactElement | false => {
        let isEditable = false;
        if (!isDisabled) {
            isEditable = _.isArray(field.editable) && _.includes(field.editable, queue);
        }
        switch (field.control) {
            case 'email':
                return (
                    <EmailInput
                        disabled={!isEditable}
                        showLabel={false}
                        type="email"
                        value={value || ''}
                        field={field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, field.key, group)}
                    />
                );
            case 'string':
                // Text field
                return (
                    <Textbox
                        disabled={!isEditable}
                        showLabel={false}
                        type="text"
                        value={value || ''}
                        field={field}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e, field.key, group)}
                    />
                );
            case 'phone':
                return (
                    <PhoneNumber
                        whatsappclaim={whatsappclaim}
                        disabled={!isEditable}
                        type="text"
                        value={value}
                        field={field}
                        onChange={(phone) => onChange(phone, field.key, group)}
                        showLabel={false}
                    />
                );
            case 'select':
                return (
                    <DropDownControl
                        disabled={!isEditable}
                        showLabel={false}
                        group={group}
                        onChange={onChange}
                        field={field}
                        value={value}
                    />
                );
            default:
                // no idea, so let's play it safe;
                console.error(
                    `Invalid field control type ${field.control}, please check the type and try again`,
                );
                return false;
        }
    };

    const renderRow = (
        field: IWhatsappClaimGroupField,
        group: IWhatsappClaimGroup,
        index: any,
    ): ReactElement | false => {
        // Fetch value from local state
        if (!field.visible || (_.isArray(field.visible) && !_.includes(field.visible, queue))) {
            return false;
        }

        const updatedGroup = updatedClaim[group.key] as any;
        const value = updatedGroup[field.key];
        return (
            <tr key={`${index}`}>
                <td>{String(field.label)}</td>
                <td>{renderInput(field, value, group)}</td>
            </tr>
        );
    };

    const renderClaimGroup = (group: IWhatsappClaimGroup, whatsappclaim: IWhatsappsClaim): ReactElement | false => {
        const claimGroup = _.compact(
            _.map(group.fields, (field: IWhatsappClaimGroupField, index: string) => {
                // Cast as any so we can access prop without implicit any error
                return renderRow(field, group, index);
            }),
        );
        if (_.isEmpty(claimGroup)) {
            return false;
        }
        return (
            <div className="claim-group" key={group.key}>
                <div className="claim-group-label">
                    <div>{group.label}</div>
                </div>
         
                <Table style={{width: '80%', marginLeft: '10%'}}>
                    {/* <colgroup>
                        <col width="30%" />
                        <col width="70%" />
                    </colgroup> */}
                    <tbody>
                        <tr>    
                                {_.map(claimGroup)}
                        </tr>
                        <tr>
                            <hr/>
                        <div style={{marginLeft: '35%', marginTop: '1%'}}>
                            <Button color="secondary" size="sm" outline onClick={saveChanges} disabled={undefined}>
                                Re-send
                            </Button>
                            </div>
                        </tr>
                    </tbody>
                </Table>
               
                {/* <Table style={{width: '80%', height:'150%', marginLeft: '50%', marginTop: '10%'}}>
                    <tbody>
                        <tr>
                        <div >
                            <Button color="secondary" size="sm" outline onClick={saveChanges} disabled={undefined}>
                                Re-send
                            </Button>
                            </div>
                        </tr>
                    </tbody>
                </Table> */}
            </div>
        );
    };
    return (
        <>
            <PerfectScrollbar className={`sidebar-info-scrollbar`}>
                {_.map(groups, (group: IWhatsappClaimGroup) => renderClaimGroup(group, whatsappclaim))}
            </PerfectScrollbar>
            {/* <div className="scrollbar-container sidebar-info-scrollbar ps">
                <div className="claim-group">
                    <Table className="sidepanel-info-table table table-borderless">
                        <colgroup>
                            <col width="40%" />
                            <col width="70%" />
                        </colgroup>
                        <tbody>
                            <tr>
                                <div className="form-group"></div>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <div className="form-group">
                                        <div>
                                            <Button color="secondary" size="sm" className="cancel-claim" outline onClick={saveChanges} disabled={undefined}>
                                                Re-send
                                            </Button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div> */}
        </>
    );
};

export default connect(null, { updateWhatsappClaim })(WhatsappClaimInfoEdit);
