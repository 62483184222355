/* eslint-disable prettier/prettier */
import React from 'react';
import _ from 'lodash';

const WhatsappHeaderComponent: React.FC<any> = ({
  deleteClaimIcon, reInstateClaimIcon, queueName
}) => {
  return (
    <thead>
      <tr>
        <th>Insured Name</th>
        <th className="text-center">Insured Mobile No</th>
        <th className="text-center">Registration No</th>
        <th className="text-center">Broker</th>
        <th className="text-center">Underwriter</th>
        <th className="text-center">Days Open</th>
        <th className="text-center">Created</th>
        <th className="text-center">Updated</th>    
        {queueName === 'CANCELED'? <th className="text-center">Reinstate Claim</th> : <th className="text-center">Cancel Claim</th>}
        {deleteClaimIcon && <th></th>}  
        {reInstateClaimIcon && <th></th>} 
        <th></th>
      </tr>
    </thead>
  );
};

export default WhatsappHeaderComponent;
