/* eslint-disable prettier/prettier */
import { Provider } from 'react-redux';
import {
  ReactReduxFirebaseConfig,
  ReactReduxFirebaseProvider,
  getFirebase,
} from 'react-redux-firebase';
import { applyMiddleware, compose, createStore } from 'redux';
import { createFirestoreInstance, getFirestore } from 'redux-firestore';
import React from 'react';
import thunk from 'redux-thunk';

import { Router, Switch } from 'react-router-dom';
import ReduxPromise from 'redux-promise';
import reducers from './reducers';

import { firebase } from './config/firebase';
// Helpers
import RouteWithLayout from './helpers/RouteWithLayout';
import history from './helpers/history';

// Wrappers
import AppDefault from './wrappers/AppDefault';
import AppWithSidebar from './wrappers/AppWithSidebar';

import 'react-perfect-scrollbar/dist/css/styles.css';
import AllClaimsPage from './components/claims/whatsapp-all/AllClaimsPage';
import CompletedClaimsPage from './components/claims/completed/CompletedClaimPage';
import DashboardPage from './components/dashboard/DashboardPage';
import IncomingClaimsPage from './components/claims/incoming/IncomingClaimPage';
import LoginPage from './components/auth/LoginPage';
import NewClaimsPage from './components/claims/new/NewClaimPage';
import OutgoingClaimsPage from './components/claims/outgoing/OutgoingClaimPage';
import UsersPage from './components/claims/users/UsersPage';
import WhatsappAuthorizedPage from './components/claims/whatsapp-authorized/WhatsappAuthorizedPage';
import WhatsappCompletedPage from './components/claims/whatsapp-completed/WhatsappCompletedPage';
import WhatsappOutgoingClaimsPage from './components/claims/whatsapp-outgoing/WhatsappOutgoingRequestPage';
import WhatsappQuotesPendingPage from './components/claims/whatsapp-quotes-pending/WhatsappQuotesPendingPage';
import WhatsappQuotesReceivedPage from './components/claims/whatsapp-quotes-received/WhatsappQuotesReceivedPage';
import manualClaim from './components/claims/manualClaims/ManualClaimsPage';
import whatsappCanceledClaimsPage from './components/claims/whatsapp-cancel/WhatsappCanceledClaimsPage';
import whatsappJourney from './components/claims/whatsapp/WhatsappClaimPage';

// Middlewares
const middlewares = [
  ReduxPromise,
  thunk.withExtraArgument({ getFirebase, getFirestore }),
];

const rrfConfig: Partial<ReactReduxFirebaseConfig> = {
  userProfile: 'users', // where profiles are stored in database
  useFirestoreForProfile: true,
};

// Create store with reducers and initial state
const initialState = {};
const store = createStore(
  reducers,
  initialState,
  compose(applyMiddleware(...middlewares))
);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const App = () => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <Router history={history}>
          <Switch>
            <RouteWithLayout
              key="login"
              layout={AppDefault}
              path="/login"
              component={LoginPage}
            />
            <RouteWithLayout
              key="all"
              layout={AppWithSidebar}
              path="/all"
              component={AllClaimsPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="users"
              layout={AppWithSidebar}
              path="/users"
              component={UsersPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="whatsapp-journey"
              layout={AppWithSidebar}
              path="/whatsapp-journey"
              component={whatsappJourney}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="manualClaim"
              layout={AppWithSidebar}
              path="/manualClaim"
              component={manualClaim}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="whatsapp-outgoing"
              layout={AppWithSidebar}
              path="/whatsapp-outgoing"
              component={WhatsappOutgoingClaimsPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="whatsapp-authorized"
              layout={AppWithSidebar}
              path="/whatsapp-authorized"
              component={WhatsappAuthorizedPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="whatsapp-completed"
              layout={AppWithSidebar}
              path="/whatsapp-completed"
              component={WhatsappCompletedPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="whatsapp-cancel"
              layout={AppWithSidebar}
              path="/whatsapp-cancel"
              component={whatsappCanceledClaimsPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="whatsapp-quotes-pending"
              layout={AppWithSidebar}
              path="/whatsapp-quotes-pending"
              component={WhatsappQuotesPendingPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="whatsapp-quotes-received"
              layout={AppWithSidebar}
              path="/whatsapp-quotes-received"
              component={WhatsappQuotesReceivedPage}
              requiresAuth={true}
            />
            <RouteWithLayout
              key="/"
              layout={AppWithSidebar}
              path="/"
              component={DashboardPage}
              requiresAuth={true}
            />
          </Switch>
        </Router>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export default App;
