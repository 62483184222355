/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import { IWhatsappClaimGroup } from '../../components/claims/whatsapp/typings';
import { IWhatsappClaimPayload } from '../../../../typings';
import {
  WC_INIT_VALUE,
  WC_RESET_CLAIM,
  WC_UPDATE_VALUE,
} from './types';
import { convertFirebaseDates } from './helpers/DateHelper';
import { functions } from '../../config/firebase';

export const updateWhatsappClaimValue = (
  group: string,
  key: string,
  value: any,
  validation: IWhatsappClaimGroup,
) => {
  return (dispatch: any) => {
    dispatch({
      type: WC_UPDATE_VALUE,
      payload: { group, key, value, validation },
    });
  };
};

export const initializeWhatsappClaimValue = (
  group: string,
  key: string,
  value: any,
  validation: IWhatsappClaimGroup,
) => {
  return (dispatch: any) => {
    dispatch({
      type: WC_INIT_VALUE,
      payload: { group, key, value, validation },
    });
  };
};

export const resetClaim = (empty: string[]) => {
  return (dispatch: any) => {
    dispatch({ type: WC_RESET_CLAIM });
  };
};

export const submitWhatsappClaim = (
  claim: IWhatsappClaimPayload,
  callback: Function,
) => {
  return (dispatch: any) => {
    const payload = convertFirebaseDates(claim);
    const createNewWhatsappClaim = functions.httpsCallable('createNewWhatsappClaim');
    createNewWhatsappClaim({ ...payload })
      .then(() => {
        callback();
      })
      .catch((err) => {
        console.error(err);
        console.error('FAILED');
        callback(err);
      });
  };
};

export const submitManualClaim = (
  claim: IWhatsappClaimPayload,
  callback: Function,
) => {
  return (dispatch: any) => {
    const payload = convertFirebaseDates(claim);
    const createManualClaim = functions.httpsCallable('createManualClaim');
    createManualClaim({ ...payload })
      .then(() => {
        callback();
      })
      .catch((err) => {
        console.error(err);
        console.error('FAILED');
        callback(err);
      });
  };
};
