/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/named */
import {
  ADD_CLAIM_NOTE,
  ADD_CLAIM_NOTE_FAIL,
  ADD_CLAIM_NOTE_IN_PROGRESS,
  UPDATE_CLAIM,
  UPDATE_CLAIM_FAIL,
  UPDATE_CLAIM_IN_PROGRESS,
  UPDATE_WHATSAPP_AUTHORISE_CLAIM,
  UPDATE_WHATSAPP_AUTHORISE_CLAIM_FAIL,
  UPDATE_WHATSAPP_AUTHORISE_CLAIM_IN_PROGRESS,
  UPDATE_WHATSAPP_CANCEL_CLAIM,
  UPDATE_WHATSAPP_CANCEL_CLAIM_FAIL,
  UPDATE_WHATSAPP_CANCEL_CLAIM_IN_PROGRESS,
  UPDATE_WHATSAPP_CLAIM,
  UPDATE_WHATSAPP_CLAIM_FAIL,
  UPDATE_WHATSAPP_CLAIM_IN_PROGRESS,
  UPDATE_WHATSAPP_COMPLETED_CLAIM,
  UPDATE_WHATSAPP_COMPLETED_CLAIM_FAIL,
  UPDATE_WHATSAPP_COMPLETED_CLAIM_IN_PROGRESS,
  UPDATE_WHATSAPP_PENDING_CLAIM,
  UPDATE_WHATSAPP_PENDING_CLAIM_FAIL,
  UPDATE_WHATSAPP_PENDING_CLAIM_IN_PROGRESS,
  UPLOAD_INVOICE,
  UPLOAD_INVOICE_FAIL,
  UPLOAD_INVOICE_IN_PROGRESS
} from './types';
import { ActionCreator } from 'redux';
import { INewClaimPayload, IWhatsappClaimPayload } from '../../../../typings';
import { functions } from '../../config/firebase';
import _ from 'lodash';
import moment from 'moment';

export const completeStep = (
  id: string,
  step: string,
  payload: any,
  callback: Function
) => {
  return (dispatch: any) => {
    const values: any = {};
    if (payload && !_.isEmpty(payload)) {
      _.each(payload, (value) => {
        values[value.key] = moment.isMoment(value.value)
          ? value.value.toDate().toISOString()
          : value;
      });
    }
    dispatch({ type: UPDATE_CLAIM_IN_PROGRESS });
    const callable = functions.httpsCallable('completeStep');
    callable({ id, step, values })
      .then(() => {
        dispatch({ type: UPDATE_CLAIM, payload: { id, step } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_CLAIM_FAIL, payload: { id, step } });
        callback(err);
      });
  };
};

export const invoiceStep = (
  id: string,
  step: string,
  payload: any,
  callback: Function
) => {
  return (dispatch: any) => {
    const values: any = {};
    if (payload && !_.isEmpty(payload)) {
      _.each(payload, (value) => {
        values[value.key] = moment.isMoment(value.value)
          ? value.value.toDate().toISOString()
          : value;
      });
    }
    dispatch({ type: UPDATE_CLAIM_IN_PROGRESS });
    const callable = functions.httpsCallable('invoiceStep');
    callable({ id, step, values })
      .then(() => {
        dispatch({ type: UPDATE_CLAIM, payload: { id, step } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_CLAIM_FAIL, payload: { id, step } });
        callback(err);
      });
  };
};

export const updateStep = (
  id: string,
  step: string,
  reason: any,
  callback: Function
) => {
  const callable = functions.httpsCallable('updateTodoStep');
  callable({ id, step, reason })
    .then(() => {
      callback(null);
    })
    .catch((err) => {
      callback(err);
    });
};

export const uploadFile = (
  id: string,
  payload: { files: string[]; key: string },
  callback: Function
) => {
  return (dispatch: any) => {
    dispatch({ type: UPLOAD_INVOICE_IN_PROGRESS });
    const callable = functions.httpsCallable('uploadFiles');
    callable({ id, files: payload.files, key: payload.key })
      .then(() => {
        dispatch({ type: UPLOAD_INVOICE, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPLOAD_INVOICE_FAIL, payload: { id } });
        callback(err);
      });
  };
};

export const whatsappUploadFile = (
  id: string,
  payload: { files: string[]; key: string },
  callback: Function
) => {
  return (dispatch: any) => {
    dispatch({ type: UPLOAD_INVOICE_IN_PROGRESS });
    const callable = functions.httpsCallable('whatsappUploadFiles');
    callable({ id, files: payload.files, key: payload.key })
      .then(() => {
        dispatch({ type: UPLOAD_INVOICE, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPLOAD_INVOICE_FAIL, payload: { id } });
        callback(err);
      });
  };
};

export const whatsappDownloadFiles = (
  file: Array<{ fileName: string; mimeType: string }>,
  callback: Function,
) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('whatsappRetrieveFile');
    callable({ file })
      .then((response: any) => {
        callback(null, response.data);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const whatsappRemoveFiles = (key: string, id: string, callback: Function) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('whatsappRemoveFiles');
    callable({ key, id })
      .then((response: any) => {
        callback(null, response.data);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const addNote = (id: string, note: string, callback: Function) => {
  return (dispatch: any) => {
    dispatch({ type: ADD_CLAIM_NOTE_IN_PROGRESS });
    const callable = functions.httpsCallable('addNote');
    callable({ id, note })
      .then(() => {
        dispatch({ type: ADD_CLAIM_NOTE, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: ADD_CLAIM_NOTE_FAIL, payload: { id } });
        callback(err);
      });
  };
};

export const addWhatsappNote = (id: string, note: string, callback: Function) => {
  return (dispatch: any) => {
    dispatch({ type: ADD_CLAIM_NOTE_IN_PROGRESS });
    const callable = functions.httpsCallable('addWhatsappNote');
    callable({ id, note })
      .then(() => {
        dispatch({ type: ADD_CLAIM_NOTE, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: ADD_CLAIM_NOTE_FAIL, payload: { id } });
        callback(err);
      });
  };
};

export const updateClaim = (
  id: string,
  claim: INewClaimPayload,
  currencyChangeObj: any,
  callback: Function
) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_CLAIM_IN_PROGRESS });
    const callable = functions.httpsCallable('updateClaim');
    callable({ id, claim, currencyChangeObj })
      .then(() => {
        dispatch({ type: UPDATE_CLAIM, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_CLAIM_FAIL, payload: { id } });
        callback(err);
      });
  };
};

export const updateWhatsappClaim = (
  id: string,
  claim: IWhatsappClaimPayload,
  callback: Function
) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_WHATSAPP_CLAIM_IN_PROGRESS });
    const callable = functions.httpsCallable('updateWhatsappClaim');
    callable({ id, claim })
      .then(() => {
        dispatch({ type: UPDATE_WHATSAPP_CLAIM, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_WHATSAPP_CLAIM_FAIL, payload: { id } });
        callback(err);
      });
  };
};

export const updateWhatsappPendingClaim = (
  id: string,
  claim: IWhatsappClaimPayload,
  callback: Function
) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_WHATSAPP_PENDING_CLAIM_IN_PROGRESS });
    const callable = functions.httpsCallable('updateWhatsappPendingClaim');
    callable({ id, claim })
      .then(() => {
        dispatch({ type: UPDATE_WHATSAPP_PENDING_CLAIM, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_WHATSAPP_PENDING_CLAIM_FAIL, payload: { id } });
        callback(err);
      });
  };
};

export const updateWhatsappAuthoriseClaim = (
  id: string,
  claim: IWhatsappClaimPayload,
  callback: Function
) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_WHATSAPP_AUTHORISE_CLAIM_IN_PROGRESS });
    const callable = functions.httpsCallable('updateWhatsappAuthoriseClaim');
    callable({ id, claim })
      .then(() => {
        dispatch({ type: UPDATE_WHATSAPP_AUTHORISE_CLAIM, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_WHATSAPP_AUTHORISE_CLAIM_FAIL, payload: { id } });
        callback(err);
      });
  };
};

export const updateWhatsappCompletedClaim = (
  id: string,
  data: any,
  callback: Function
) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_WHATSAPP_COMPLETED_CLAIM_IN_PROGRESS });
    const callable = functions.httpsCallable('updateWhatsappCompletedClaim');
    callable({ id, data })
      .then(() => {
        dispatch({ type: UPDATE_WHATSAPP_COMPLETED_CLAIM, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_WHATSAPP_COMPLETED_CLAIM_FAIL, payload: { id } });
        callback(err);
      });
  };
};

export const acceptIncomingClaim = (id: string, callback: Function) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('acceptIncomingClaim');
    callable({ id })
      .then(() => {
        callback(null);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const slaDoneMessageCheck = (id: string, callback: Function) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('slaDoneMessageCheck');
    callable({ id })
      .then(() => {
        callback(null);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const rejectIncomingClaim = (id: string, callback: Function) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('rejectIncomingClaim');
    callable({ id })
      .then(() => {
        callback(null);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const submitRatingForClaim = (
  id: string,
  rating: number,
  reason: string = '',
  callback: Function
) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('submitRatingForClaim');
    callable({ id, rating, reason })
      .then((data) => {
        callback(data.data);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const cancelClaim = (
  id: string,
  reason: string,
  cancelledBy: string,
  callback: Function
) => {
  return (dispatch: any) => {
    const callable = functions.httpsCallable('cancelClaim');
    callable({ id, reason, cancelledBy })
      .then(() => {
        callback(null);
      })
      .catch((err) => {
        callback(err);
      });
  };
};

export const downloadClaimInfo: ActionCreator<any> = async (
  id: string,
  isCompleteInfo: boolean
) => {
  try {
    const downloadClaimInfo = functions.httpsCallable('downloadClaimInfo');
    const pdf = await downloadClaimInfo({ id, isCompleteInfo });
    return pdf.data;
  } catch (e) {
    console.error(e);
  }
};

export const downloadClaimDetail = async () => {
  try {
    const downloadClaimDetail = functions.httpsCallable("downloadClaimDetail");
    const csv = await downloadClaimDetail();
    return Object.values(csv);
  } catch (e) {
    console.error(e);
  }
};

export const generateQuotePDF: ActionCreator<any> = async (
  id: string,
  isCompleteInfo: boolean
) => {
  try {
    const generateQuotePDF = functions.httpsCallable('generateQuotePDF');
    const pdf = await generateQuotePDF({ id, isCompleteInfo });
    return pdf.data;
  } catch (e) {
    console.error(e);
  }
};

export const generateQuotePDFForHollardBIC: ActionCreator<any> = async (
  id: string,
  isCompleteInfo: boolean
) => {
  try {
    const generateQuotePDFForHollardBIC = functions.httpsCallable('generateQuotePDFForHollardBIC');
    const pdf = await generateQuotePDFForHollardBIC({ id, isCompleteInfo });
    return pdf.data;
  } catch (e) {
    console.error(e);
  }
};

export const deleteClaim = (
  id: string,
  reason: string,
  callback: Function
) => {
  return (dispatch: any) => {
    dispatch({ type: UPDATE_WHATSAPP_CANCEL_CLAIM_IN_PROGRESS });
    const callable = functions.httpsCallable('deleteClaim');
    callable({ id, reason })
      .then(() => {
        dispatch({ type: UPDATE_WHATSAPP_CANCEL_CLAIM, payload: { id } });
        callback(null);
      })
      .catch((err) => {
        dispatch({ type: UPDATE_WHATSAPP_CANCEL_CLAIM_FAIL, payload: { id } });
        callback(err);
      });
  }
};

export const deleteClaim1: ActionCreator<any> = async  (
  id: string,
  reason: string,
) => {
  try {
    const deleteClaim = functions.httpsCallable('deleteClaim');
    const response = await deleteClaim({ id, reason });
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const reinstateClaim: ActionCreator<any> = async  (
  id: string
) => {
  try {
    const reinstateClaim = functions.httpsCallable('reinstateClaim');
    const response = await reinstateClaim({ id });
    return response.data;
  } catch (e) {
    console.error(e);
  }
};