/* eslint-disable prettier/prettier */
import '../../../styles/Checkboxes.scss';
import '../../../styles/image-gallery.scss';
import "react-datepicker/dist/react-datepicker.css";
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { Badge, Button, Col, Collapse, Input, Row, Table } from 'reactstrap';
import { FileDropZone } from '../side-panel/inputs/FileDropzone';
import { IWhatsappClaimPayload } from '../../../../../typings';
import { IWhatsappsClaim, WhatsappAllClaimSidePanelProps } from '../whatsapp-typing';
import { chevronDown, chevronUp, eye, loader, x } from 'react-icons-kit/feather';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { updateWhatsappClaimValue } from '../../../actions/whatsapp/index';
import { updateWhatsappCompletedClaim, whatsappDownloadFiles, whatsappRemoveFiles, whatsappUploadFile } from '../../../actions/update-claim';
import DatePicker from "react-datepicker"
import Icon from 'react-icons-kit';
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import Modal from 'react-modal';
import NotesComponent from '../shared/WhatsappNotes';
import React, { ReactElement, useEffect, useState } from 'react';
import _ from 'lodash';
import qcIcon from "../../../images/QC.svg";

const initialState: { loading: boolean; saving: boolean; hasChanges: boolean; isDisabled: boolean; loadingDelete: boolean } = {
    saving: false,
    hasChanges: false,
    loading: false,
    loadingDelete: false,
    isDisabled: false
};

const initialStateForRemove: { loading: boolean; loadingDelete: boolean } = {
    loading: false,
    loadingDelete: false
};

const initialImages: ReactImageGalleryItem[] = [];

const WhatsappAllClaimSidePanel: React.FC<WhatsappAllClaimSidePanelProps> = ({
    claim,
    onClose,
    whatsappDownloadFiles,
}) => {
    const claimSansAudit: IWhatsappClaimPayload = _.pick(claim, [
        'whatsappinsurer',
    ]);
    const [updatedClaim, updateClaimState] = useState({ ...claimSansAudit });
    const [state, setState] = useState(initialState);
    const [stateRemove, setRemoveState] = useState(initialStateForRemove);
    const [insuredEditOpen, setInsuredEditOpen] = useState(false);
    const [incidentEditOpen, setIncidentEditOpen] = useState(false);
    const [imagesEditOpen, setImagesEditOpen] = useState(false);
    const [damageEditOpen, setDamageEditOpen] = useState(false);
    const [noteEditOpen, setNoteEditOpen] = useState(false);
    const [insuredChatOpen, setInsuredChatOpen] = useState(true);
    const [incidentChatOpen, setIncidentChatOpen] = useState(true);
    const [imagesChatOpen, setImagesChatOpen] = useState(true);
    const [damageChatOpen, setDamageChatOpen] = useState(true);
    const [noteChatOpen, setNoteChatOpen] = useState(true);
    const [startDate, setStartDate] = useState(new Date());

    const [loading, setLoading] = useState(new Map());
    const [isOpenModal, toggleModal] = useState(false);

    const collapseConfig = {
        show: 100,
        hide: 100,
    };

    useEffect(() => {
        if (claim?.whatsappinsurer?.name !== updatedClaim.whatsappinsurer?.name) {
            reset();
        }
        // we don't need exhaustive hook dep check, shut the linter up.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claim]);

    useEffect(() => {
        checkForChanges();
        // we don't need exhaustive hook dep check, shut the linter up.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedClaim]);


    const reset = () => {
        state.isDisabled = false;
        state.hasChanges = false;
    };

    const checkForChanges = (): boolean => {
        const claimSansAudit: IWhatsappClaimPayload = _.pick(claim, [
            'whatsappinsurer',
        ]);
        const hasChanges = !_.isEqual(updatedClaim, claimSansAudit);
        setState({ ...state, hasChanges });
        return hasChanges;
    };

    const openFile = (pdf: string, mimeType: string) => {
        const binaryString = atob(pdf);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        // create the blob object with content-type "application/pdf"
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url);
    };

    const openPdf = async (files: {
        [index: number]: { data: any; mimeType: string };
    }): Promise<void> => {
        _.each(files, (file) => {
            openFile(file.data, file.mimeType);
        });
    };

    const openImageModal = (files: { data: string; mimeType: string }[]) => {
        const imgs: ReactImageGalleryItem[] = [];
        _.each(files, ({ data: file, mimeType }) => {
            imgs.push({
                original: `${mimeType},${file}`,
                thumbnail: `${mimeType},${file}`,
            });
        });
        toggleModal(true);
    };

    const download = (file: Array<{ fileName: string; mimeType: string }>, key: string) => {
        setLoading(_.cloneDeep(loading.set(file[0].fileName, true)));
        whatsappDownloadFiles(file, (err: Error, files: any) => {
            setLoading(_.cloneDeep(loading.set(file[0].fileName, false)));
            if (err) {
                console.error(err);
                toast.error(`Could not retrieve ${key}, please try again later.`);
                return;
            }
            const imageFiles = files.filter((file: any) => {
                return !file?.mimeType.includes('pdf');
            });
            const pdfFiles = files.filter((file: any) => {
                return file?.mimeType.includes('pdf');
            });
            if (imageFiles && imageFiles.length > 0) {
                openImageModal(imageFiles);
            }
            if (pdfFiles && pdfFiles.length > 0) {
                openPdf(pdfFiles);
            }
        });
    };

    const renderClaim = (claim: IWhatsappsClaim): ReactElement | false => {
        let damageGlassDetails: Array<string> = [];
        let calTotalCost = 0;

        if (claim.whatsappinsurer.damageGlassCostDetails !== undefined) {
            damageGlassDetails = claim.whatsappinsurer.damageGlassCostDetails;
            for (const index in damageGlassDetails) {
                calTotalCost = calTotalCost + Number(damageGlassDetails[index].cost);
            }
        } else {
            const rowsInput = {
                partNumber: '',
                partDescription: '',
                cost: 0
            }
            damageGlassDetails = [rowsInput];
            calTotalCost = 0;
        }

        let damageImages: Array<string> = [];
        damageImages = claim.whatsappinsurer.damagePhotos;
        const images = [
            claim.whatsappinsurer.licenceDisc !== '' && claim.whatsappinsurer.licenceDisc !== undefined ?
                {
                    original: `${claim.whatsappinsurer.licenceDisc}`,
                    thumbnail: `${claim.whatsappinsurer.licenceDisc}`,
                }
                :
                {
                    original: `${qcIcon}`,
                    thumbnail: `${qcIcon}`,
                },
            claim.whatsappinsurer.vehicleFront !== '' && claim.whatsappinsurer.vehicleFront !== undefined ?
                {
                    original: `${claim.whatsappinsurer.vehicleFront}`,
                    thumbnail: `${claim.whatsappinsurer.vehicleFront}`,
                }
                :
                {
                    original: `${qcIcon}`,
                    thumbnail: `${qcIcon}`,
                },
            claim.whatsappinsurer.driversLicense !== '' && claim.whatsappinsurer.driversLicense !== undefined ?
                {
                    original: `${claim.whatsappinsurer.driversLicense}`,
                    thumbnail: `${claim.whatsappinsurer.driversLicense}`,
                }
                :
                {
                    original: `${qcIcon}`,
                    thumbnail: `${qcIcon}`,
                },
        ];

        if (damageImages !== null && damageImages !== undefined) {
            if (damageImages.length === 0) {
                images.push({
                    original: qcIcon,
                    thumbnail: qcIcon
                })
            } else {
                for (const index in damageImages) {
                    images.push({
                        original: damageImages[index],
                        thumbnail: damageImages[index]
                    })
                }
            }
        } 
        // else {
        //     images.push({
        //         original: qcIcon,
        //         thumbnail: qcIcon
        //     })
        // }

        if (!claim) {
            return false;
        }
        return (
            <div>
                <div className="header-panel">
                    <div>
                        <Button className="close-btn" onClick={(e) => onClose(e)}>
                            <Icon icon={x} size={28} />
                        </Button>
                        <h2>
                            {claim?.whatsappinsurer?.name}
                            <Badge className="status-badge">{claim?.status}</Badge>
                        </h2>
                    </div>
                </div>
                <div>
                    {state.isDisabled && state.hasChanges ? (
                        <div className={`position-fixed-btns edit-buttons ${state.hasChanges ? '' : 'disabled'}`}>
                            <Button
                                color="secondary"
                                size="sm"
                                disabled={
                                    true
                                }>
                                {state.saving ? 'Completing...' : 'Complete'}
                            </Button>
                        </div>
                    ) : null}
                </div>
                <Row className="claim">
                    <Col sm={6} className="todo-list-container">
                        <div className="static">
                            <div className="todo-list">
                                <h3>To Do List</h3>
                                &nbsp;
                                <div className="">
                                    <div className="">
                                        <Table className="sidepanel-info-table table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td >
                                                        <div className="form-checkbox">
                                                            <input className="checkbox"
                                                                type="checkbox"
                                                                id="checkbox1"
                                                                checked={claim.whatsapptodos.confirmEstimate.completed === false ? false : true}
                                                            />
                                                            <label htmlFor="checkbox1">Confirm cost estimate</label>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <Input
                                                            value={calTotalCost !== null || calTotalCost !== 0 ? calTotalCost : 0.00}
                                                            type='number'
                                                            placeholder=""
                                                            className="form-checkbox"
                                                            required
                                                            disabled={true}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <div className="form-checkbox">
                                                            <input className="checkbox"
                                                                type="checkbox"
                                                                id="checkbox2"
                                                                checked={claim.whatsapptodos.claimNumber.completed === false ? false : true}
                                                            />
                                                            <label htmlFor="checkbox2">Claim Number</label>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <Input
                                                            value={claim.whatsappinsurer.claimNumber !== null || claim.whatsappinsurer.claimNumber !== '' ? claim.whatsappinsurer.claimNumber : ''}
                                                            type='text'
                                                            placeholder="Enter Claim Number"
                                                            className="form-checkbox"
                                                            required
                                                            disabled={true}
                                                            readOnly
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <div className="form-checkbox">
                                                            <input className="checkbox"
                                                                type="checkbox"
                                                                id="checkbox3"
                                                                checked={claim.whatsapptodos.collectedExcess.completed === false ? false : true}
                                                            />
                                                            <label htmlFor="checkbox3">Collect excess from client</label>
                                                            <span style={{color: 'red'}}> (Excluding VAT)</span>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <Input
                                                            value={claim.whatsappinsurer.excessValue !== null || claim.whatsappinsurer.excessValue !== 0 ? claim.whatsappinsurer.excessValue : 0.00}
                                                            type='number'
                                                            placeholder="Enter Excess"
                                                            className="form-checkbox"
                                                            required
                                                            disabled={true}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <div className="form-checkbox">
                                                            <input className="checkbox" 
                                                            type="checkbox" 
                                                            id="checkbox4" 
                                                            checked={claim.whatsapptodos.bookInDate.completed === false ? false : true}
                                                            />
                                                            <label htmlFor="checkbox4">Fitment date</label>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        <div className="picker">
                                                            <DatePicker
                                                                showTimeSelect
                                                                dateFormat="dd/MM/yyyy h:mmaa"
                                                                selected={claim.status === 'SENT' ? new Date('01/01/1900 12:00') : new Date()}
                                                                selectsStart
                                                                startDate={startDate}                                                               
                                                                showIcon={true}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td >
                                                        <div className="form-checkbox">
                                                            <input className="checkbox" 
                                                            type="checkbox" 
                                                            id="checkbox5" 
                                                            checked={claim.whatsapptodos.invoice.completed === false ? false : true}
                                                            />
                                                            <label htmlFor="checkbox5">Upload invoice</label>
                                                        </div>
                                                    </td>
                                                    <td >
                                                        {claim.files !== undefined ?
                                                    <div key={claim.files.invoice[0].fileName} className="files-attached-div">
                                                            <h6>{claim.files.invoice[0].fileName}</h6>
                                                            <Button
                                                                className="file-single"
                                                                color="link"
                                                                onClick={() => download(claim.files.invoice, 'invoice')}
                                                                size="sm">
                                                                {loading.get(claim.files.invoice[0].fileName) ? (
                                                                    <Icon icon={loader} size={16} className="spin" />
                                                                ) : (
                                                                    <Icon icon={eye} />
                                                                )}
                                                            </Button>
                                                        </div>
                                                        : 
                                                        <FileDropZone
                                                        loading={state.loading}
                                                        onError={toast.error}
                                                        hideText={false}
                                                        onDrop={null}
                                                        className="todo-drop-file"
                                                    />

                                                        }
                                                        <Modal isOpen={isOpenModal} className="image-modal" ariaHideApp={true}>
                                                            <ImageGallery items={images} />
                                                            <Button className="image-modal-close" onClick={() => toggleModal(!isOpenModal)}>
                                                                <Icon icon={x} size={64} />
                                                            </Button>
                                                        </Modal>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={insuredChatOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setInsuredChatOpen(!insuredChatOpen);
                                    setInsuredEditOpen(!insuredEditOpen);
                                }}>
                                <div>
                                    Insured Info<Icon icon={insuredChatOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={insuredChatOpen}>
                                <div className="scrollbar-container sidebar-info-scrollbar ps">
                                    <div className="claim-group">
                                        <Table className="sidepanel-info-table table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Registration</td>
                                                    <td>{claim?.whatsappinsurer.registrationNumber}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer Name</td>
                                                    <td>{claim?.whatsappinsurer.name}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer Email Address</td>
                                                    <td>{claim?.whatsappinsurer.customerEmail}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer preferred Contact No.</td>
                                                    <td>{claim?.whatsappinsurer.mobileNumber}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Is Insured/Customer have Landline No.</td>
                                                    <td>{claim?.whatsappinsurer.isLandlineNumberExist}</td>
                                                </tr>
                                                {claim.whatsappinsurer.isLandlineNumberExist === 'Yes' ?
                                                    <tr>
                                                        <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer Landline No.</td>
                                                        <td>{claim?.whatsappinsurer.landlineNumber}</td>
                                                    </tr>
                                                    : null}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={incidentEditOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setIncidentEditOpen(!incidentEditOpen);
                                    setIncidentChatOpen(!incidentChatOpen);
                                }}>
                                <div>
                                    Incident Details<Icon icon={incidentEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={incidentEditOpen}>
                                <div className="scrollbar-container sidebar-info-scrollbar ps">
                                    <div className="claim-group">
                                        <Table className="sidepanel-info-table table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Date of Breakage (DD/MM/YYYY)</td>
                                                    <td>{claim?.whatsappinsurer.incidentDate}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Cause of Breakage</td>
                                                    <td>{claim?.whatsappinsurer.causeOfBreakage}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Is the driver’s name same as the insured?</td>
                                                    <td>{claim?.whatsappinsurer.isDriversNameSameInsured}</td>
                                                </tr>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isDriversNameSameInsured !== undefined ? (
                                                    <>
                                                        {claim.whatsappinsurer.isDriversNameSameInsured !== 'Yes' ?
                                                            <tr>
                                                                <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Driver Name</td>
                                                                <td>{claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.driversName !== undefined ? claim?.whatsappinsurer.driversName : ''}</td>
                                                            </tr>
                                                            : null}
                                                    </>
                                                )
                                                    : (
                                                        ''
                                                    )}
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Was there a witness to the incident?</td>
                                                    <td>{claim?.whatsappinsurer.isWitnessToIncident}</td>
                                                </tr>
                                                {claim.whatsappinsurer.isWitnessToIncident === 'Yes' ?
                                                    <><tr>
                                                        <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Witness Name</td>
                                                        <td>{claim?.whatsappinsurer.witnessName}</td>
                                                    </tr><tr>
                                                            <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Witness Mobile No.</td>
                                                            <td>{claim?.whatsappinsurer.witnessMobileNumber}</td>
                                                        </tr></>
                                                    : null
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={imagesEditOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setImagesEditOpen(!imagesEditOpen);
                                    setImagesChatOpen(!imagesChatOpen);
                                }}>
                                <div>
                                    Images<Icon icon={imagesEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={imagesEditOpen}>
                                <div className="scrollbar-container sidebar-info-scrollbar ps">
                                    <div className="claim-group">
                                        <Table className="sidepanel-info-table table table-borderless">
                                            <tbody>
                                                <tr>
                                                    {images.length !== 0 ?
                                                        <div className="image-gallery-wrapper">
                                                            <ImageGallery items={images} />
                                                        </div>
                                                        : null
                                                    }
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={damageEditOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setDamageEditOpen(!damageEditOpen);
                                    setDamageChatOpen(!damageChatOpen);
                                }}>
                                <div>
                                Damage Windscreen/Glass Details <Icon icon={damageEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={damageEditOpen}>
                            <div className="scrollbar-container sidebar-info-scrollbar ps">
                                    <div className="claim-group">
                                        <Table className="sidepanel-info-table table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Make</td>
                                                    <td>{claim?.whatsappinsurer.vehicleMake}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Model</td>
                                                    <td>{claim?.whatsappinsurer.vehicleModel}</td>
                                                </tr>                                           
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                                <div className="scrollbar-container sidebar-info-scrollbar ps">
                                    <div className="claim-group">
                                        <Table className="sidepanel-info-table table table-borderless">
                                            <tbody>
                                                <thead>
                                                <tr>
                                                    <th style={{ width: '250px' }}>Part Number</th>
                                                    <th style={{ width: '250px' }}>Description</th>
                                                    <th style={{ width: '300px' }}>Cost <span style={{color: 'red'}}> (Excluding VAT)</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {damageGlassDetails !== undefined || damageGlassDetails !== null ? 
                                                damageGlassDetails.map((data, index) => {
                                                        const { partNumber, partDescription, cost } = data;
                                                       return (<tr key={index}>
                                                            <td style={{ width: '250px' }}>
                                                                {
                                                                    partNumber === '' ? '' : partNumber
                                                                }
                                                            </td>
                                                            <td style={{ width: '250px' }}>
                                                                {
                                                                    partDescription === '' ? '' : partDescription
                                                                }
                                                            </td>
                                                            <td style={{ width: '300px' }}>
                                                                {
                                                                    cost
                                                                }
                                                            </td>
                                                        </tr>)
                                                    })
                                                    : null}
                                                    <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Total Claim Cost</td>
                                                        <td></td>
                                                        <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>
                                                            {
                                                                calTotalCost
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={noteEditOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setNoteEditOpen(!noteEditOpen);
                                    setNoteChatOpen(!noteChatOpen);
                                }}>
                                <div>
                                    Claim Notes <Icon icon={noteEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} isOpen={noteEditOpen}>
                                <div className="static">
                                    <NotesComponent claim={claim} />
                                </div>
                            </Collapse>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    if (!claim) {
        return <div></div>;
    }

    return (
        <div>
            <div className={`side-panel-overlay ${claim ? 'open' : ''}`}></div>
            <div id="incoming-side-panel" className={`side-panel ${claim ? 'open' : ''}`}>
                {claim ? renderClaim(claim) : false}
            </div>
        </div>
    );
};

export default connect(null, { whatsappDownloadFiles })(WhatsappAllClaimSidePanel);