/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import '../../../styles/NewClaimPage.scss';
import {
  FirebaseReducer,
  FirestoreReducer,
  ReduxFirestoreQueries,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { IWhatsappClaimReducer } from '../../../reducers/reducers/typings';
import { RouteComponentProps } from 'react-router';
import { WhatsappClaimsComponentProps } from '../whatsapp/typings';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { resetClaim } from '../../../actions/whatsapp';
import Helmet from 'react-helmet';
import LoadingPage from '../../loading/LoadingPage';
import React, { Component } from 'react';
import WhatsappClaimForm from './WhatsappClaimForm';
import _ from 'lodash';

class WhatsappClaimPage extends Component<
  RouteComponentProps & FirebaseReducer.Reducer & WhatsappClaimsComponentProps
> {
  componentDidMount() {
    this.props.resetClaim();
  }

  render() {
    const { whatsappConfig, whatsappClaim, profile } = this.props;
    const whatsapp: IWhatsappClaimReducer = whatsappClaim; 
    return (
      <div className="padded-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Quick Claims | New Jobs</title>
        </Helmet>
        {whatsappConfig.loading ? ( 
          <LoadingPage />
        ) : (
          <WhatsappClaimForm whatsappConfig={whatsappConfig.claims} whatsappClaim={whatsapp} partner={profile} />
        )}
      </div>
    );
  }
}

const preMapStateToProps = (state: any) => {
  const {
    firebase: { profile },
  }: { firebase: FirebaseReducer.Reducer } = state;
  return {
    profile: profile,
  };
};

const mapQueryToProps = ({ profile }: { profile: any }): ReduxFirestoreQueries => {
  if (!profile.isLoaded || profile.isEmpty) {
    return [];
  }
  return [
    {
      collection: 'config',
      doc: 'whatsapp',
      storeAs: 'whatsappConfig',
    },
    {
      collection: 'partners',
      doc: profile.partnerId,
      storeAs: 'partner',
    },
  ];
};

const mapStateToProps = (state: any) => {
  const {
    firestore,
    firebase: { auth },
    whatsappClaim,
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
    whatsappClaim: IWhatsappClaimReducer;
  } = state;
 const whatsappConfig = firestore.data.whatsappConfig;
  const partner = firestore.data.partner;
  const loading = !isLoaded(whatsappConfig) || !isLoaded(partner);
  let whatsappClaims = null;
  if (!loading) {
    const masterClaim = whatsappConfig.whatsapp;
    whatsappClaims = masterClaim;
  }
  return {
    whatsappConfig: {
      claims: whatsappClaims,
      loading: loading,
    }, 
    whatsappClaim,
    auth: auth as FirebaseReducer.AuthState,
  };
};

export default compose(
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, { resetClaim }),
)(WhatsappClaimPage);
