/* eslint-disable prettier/prettier */
import * as icons from 'react-icons-kit/feather';
import { DropzoneComponentProps } from '../../whatsapp/typings';
import { useDropzone } from 'react-dropzone';
import Icon from 'react-icons-kit';
import React, { ReactText, useCallback } from 'react';
import async from 'async';

const WhatsappFileDropZone: React.FC<DropzoneComponentProps> = ({
  onDrop,
  onError,
  text,
  className,
  hideText = false,
  field,
  loading,
  isDisabled,
}) => {
  const onDropInternal = useCallback(
    (acceptedFiles: File[]) => {
      const files: Array<{ file: string; ext: string }> = [];
      async.forEachOfSeries(
        acceptedFiles,
        (file: File, index: ReactText, callback: async.ErrorCallback<Error>) => {
          const reader = new FileReader();
          reader.onabort = () => callback(new Error('file reading was aborted'));
          reader.onerror = (err) => callback(new Error('file reading has failed'));
          reader.onload = () => {
            // Do whatever you want with the file contents
            const name = file.name;
            const lastDot = name.lastIndexOf('.');
            const ext = name.substring(lastDot + 1);
            const base64Str: string = reader.result as string;
            files.push({ file: base64Str, ext });
            callback();
          };
          reader.readAsDataURL(file);
        },
        (err: Error | null | undefined) => {
          if (err) {
            onError(err);
            return false;
          }
          onDrop(files);
          return true;
        },
      );
    },
    [onDrop, onError],
  );

  const onDropRejected = (files: any, event: Event): void => {
    onError('Incorrect file type or size');
  };

  const acceptedFiles = ['application/pdf', 'image/*'];
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropInternal,
    accept: acceptedFiles,
    onDropRejected,
  });

  const renderText = () => {
    const defaultText = 'Drop files here, or click to select file.';
    let overrideText = field && field.label ? field.label : text;
    if (loading) {
      overrideText = 'Uploading file(s)';
    }
    return <p>{overrideText || defaultText}</p>;
  };
  let icon = field && field.icon ? icons[field.icon] : icons.uploadCloud;
  if (loading) {
    icon = icons.loader;
  }
  const loadingClass = loading ? 'spin' : '';
  return (
    <div {...getRootProps()} className={`invoice-dropzone ${className ? className  : ''}`}>
      <input {...getInputProps()} />
      <Icon icon={icon} size={24} className={loadingClass}/>
      {hideText ? false : renderText()}
    </div>
  );
};

export default WhatsappFileDropZone;