/* eslint-disable prettier/prettier */
import '../../../styles/Checkboxes.scss';
import '../../../styles/image-gallery.scss';
import "react-datepicker/dist/react-datepicker.css";
import 'react-image-gallery/styles/scss/image-gallery.scss';
import {
    Badge, Button, Card,
    CardBody,
    CardFooter,
    CardHeader, Col, Collapse, FormGroup, Input, Label, Row, Table
} from 'reactstrap';
import { IWhatsappClaimPayload } from '../../../../../typings';
import { IWhatsappsClaim, WhatsappQuoteReceivedSidePanelProps } from '../whatsapp-typing';
import { checkSquare, chevronDown, chevronUp, loader, x } from 'react-icons-kit/feather';
import { connect } from 'react-redux';
import { deleteClaim, generateQuotePDF, generateQuotePDFForHollardBIC, updateWhatsappAuthoriseClaim } from '../../../actions/update-claim';
import { download } from 'react-icons-kit/icomoon/download';
import { toast } from 'react-toastify';
import { trash } from 'react-icons-kit/iconic/trash'
import CancelModal from '../../modals/CancelModal';
import DatePicker from "react-datepicker"
import FileDropzone from '../side-panel/inputs/WhatsappFileDropzone';
import Icon from 'react-icons-kit';
import ImageGallery from "react-image-gallery";
import NotesComponent from '../shared/WhatsappNotes';
import React, { ReactElement, useEffect, useState } from 'react';
import _ from 'lodash';
import history from '../../../helpers/history';
import moment from 'moment';
import qcIcon from "../../../images/QC.svg";

const initialState: { loading: boolean; saving: boolean; hasChanges: boolean; isDisabled: boolean, cancelling: boolean } = {
    saving: false,
    hasChanges: false,
    loading: false,
    isDisabled: false,
    cancelling: false,
};

const WhatsappQuoteReceivedSidePanel: React.FC<WhatsappQuoteReceivedSidePanelProps> = ({
    claim,
    partner,
    userProfile,
    onClose,
    updateWhatsappAuthoriseClaim,
    deleteClaim,
}) => {
    const claimSansAudit: IWhatsappClaimPayload = _.pick(claim, [
        'whatsappinsurer',
    ]);
    const [updatedClaim, updateClaimState] = useState<any>({});
    const [state, updateState] = useState(initialState);
    const [insuredEditOpen, setInsuredEditOpen] = useState(false);
    const [incidentEditOpen, setIncidentEditOpen] = useState(false);
    const [imagesEditOpen, setImagesEditOpen] = useState(false);
    const [damageEditOpen, setDamageEditOpen] = useState(false);
    const [noteEditOpen, setNoteEditOpen] = useState(false);
    const [insuredChatOpen, setInsuredChatOpen] = useState(true);
    const [incidentChatOpen, setIncidentChatOpen] = useState(true);
    const [imagesChatOpen, setImagesChatOpen] = useState(true);
    const [damageChatOpen, setDamageChatOpen] = useState(true);
    const [noteChatOpen, setNoteChatOpen] = useState(true);
    const [startDate, setStartDate] = useState(new Date('01/01/1900 12:00'));
    const [endDate, setEndDate] = useState(new Date());
    const [fileLoading, setFileLoading] = useState<any>({});

    const [checkedOne, setCheckedOne] = React.useState(false);
    const [checkedTwo, setCheckedTwo] = React.useState(false);
    const [checkedThree, setCheckedThree] = React.useState(false);

    const [claimNumber, setClaimNumber] = useState('');
    const [excess, setExcess] = useState('');
    const [excessDisabled, setExcessDisabled] = useState(true);
    const [claimNumberDisabled, setClaimNumberDisabled] = useState(true);

    const [downloadPowLoading, setDownloadPowLoading] = useState(true);
    const [downloadInfoLoading, setDownloadInfoLoading] = useState(true);
    const [cancelClaimLoading, setCancelClaimLoading] = useState(true);

    // const [state, setState] = useState(initialState);
    const [modalOpen, setModalOpen] = useState(false);
    const [reason, setReason] = useState('');

    const handleChangeClaimNumber = (event: { target: { value: any; }; }) => {
        const claimNumberVariable = event.target.value
        setClaimNumber(claimNumberVariable);
        state.isDisabled = true;
        state.hasChanges = true;
    }

    const handleChangeExcess = (event: { target: { value: any; }; }) => {
        const excessVariable = event.target.value
        setExcess(excessVariable);
        state.isDisabled = true;
        state.hasChanges = true;
    }

    const handleChangeOne = () => {
        setCheckedOne(!checkedOne);
    };

    const handleChangeTwo = (event: { target: { checked: any; }; }) => {
        if (event.target.checked) {
            setCheckedTwo(!checkedTwo);
            setClaimNumberDisabled(false);
        } else {
            setCheckedTwo(!checkedTwo);
            setClaimNumberDisabled(true);
        }
    };

    const handleChangeThree = (event: { target: { checked: any; }; }) => {
        if (event.target.checked) {
            setCheckedThree(!checkedThree);
            setExcessDisabled(false);
        } else {
            setCheckedThree(!checkedThree);
            setExcessDisabled(true);
        }
    };

    const collapseConfig = {
        show: 100,
        hide: 100,
    };

    useEffect(() => {
        if (claim?.whatsappinsurer?.name !== updatedClaim.whatsappinsurer?.name) {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claim]);

    useEffect(() => {
        checkForChanges();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedClaim]);

    const onChange = () => {
        state.isDisabled = true;
        state.hasChanges = true;
        const updatedData = {
            claimNumber: claimNumber,
            excessValue: Number(excess)
        }
        return updatedData;
    }

    const saveChanges = (e: any) => {
        if (userProfile.partnerId !== 'EXCLUSIVE' && claim.whatsappinsurer.underwriter !== 'GEMVAS' && claim.whatsappinsurer.underwriter !== undefined && claim.whatsappinsurer !== undefined) {
            if (claimNumber !== null && claimNumber !== '' && excess !== null && excess !== '' && checkedTwo !== false && checkedThree !== false) {
                updateState({ ...state, saving: true });
                const updatedClaim = onChange();
                updateWhatsappAuthoriseClaim(String(claim.id), updatedClaim, (err: Error) => {
                    updateState({ ...initialState });                    
                    if (err) {
                        toast.error('Could not update claim. Please try again later');
                        console.error(err);
                        return;
                    }
                    onClose(e);
                    const successMessage = (
                        <div className="toast-message">
                            <Icon icon={checkSquare} size={36} /> Successfully Authorised the claim.
                        </div>
                    );
                    // history.push('/');
                    return toast.success(successMessage, {
                        autoClose: 3000,
                        closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
                    });
                });
            } else {
                const errorMessage = (
                    <div className="toast-message">
                        <Icon icon={checkSquare} size={36} /> Kindly fill the mandetory data.
                    </div>
                );
                return toast.error(errorMessage, {
                    autoClose: 3000,
                    closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
                });
            }
        } else {
            if (claimNumber !== null && claimNumber !== '' && checkedTwo !== false) {
                updateState({ ...state, saving: true });
                const updatedClaim = onChange();
                updatedClaim.excessValue = 0;
                updateWhatsappAuthoriseClaim(String(claim.id), updatedClaim, (err: Error) => {
                    updateState({ ...initialState });
                    if (err) {
                        toast.error('Could not update claim. Please try again later');
                        console.error(err);
                        return;
                    }
                    const successMessage = (
                        <div className="toast-message">
                            <Icon icon={checkSquare} size={36} /> Successfully Authorised the claim.
                        </div>
                    );
                    history.push('/');
                    return toast.success(successMessage, {
                        autoClose: 3000,
                        closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
                    });
                });
            } else {
                const errorMessage = (
                    <div className="toast-message">
                        <Icon icon={checkSquare} size={36} /> Kindly fill the mandetory data.
                    </div>
                );
                return toast.error(errorMessage, {
                    autoClose: 3000,
                    closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
                });
            }
        }
    };

    const reset = () => {
        state.isDisabled = false;
        state.hasChanges = false;
    };

    const checkForChanges = (): boolean => {
        const claimSansAudit: IWhatsappClaimPayload = _.pick(claim, [
            'whatsappinsurer',
        ]);
        const hasChanges = !_.isEqual(updatedClaim, claimSansAudit);
        updateState({ ...state, hasChanges });
        return hasChanges;
    };

    const openPdf = (base64str: string) => {
        // decode base64 string, remove space for IE compatibility
        const binary = atob(base64str.replace(/\s/g, ''));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        // create the blob object with content-type "application/pdf"
        const blob = new Blob([view], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url);
        setDownloadPowLoading(true);
        setDownloadInfoLoading(true);
    };

    const downloadQuotePDF = async () => {
        try {
            setDownloadPowLoading(false);
            const pdfFile = await generateQuotePDF(claim.messageClaimId || '', false);
            if (pdfFile) {
                openPdf(pdfFile);
            }
        } catch (e) {
            setDownloadPowLoading(true);
            console.error(e);
        }
    };

    const downloadClaimForm = async () => {
        try {
            setDownloadInfoLoading(false);
            const pdfFile = await generateQuotePDFForHollardBIC(claim.messageClaimId || '', false);
            if (pdfFile) {
                openPdf(pdfFile);
            }
        } catch (e) {
            setDownloadInfoLoading(true);
            console.error(e);
        }
    };

    const onClickCancelClaim = async () => {
        try {
            setCancelClaimLoading(false);
            // const response = await deleteClaim(claim.messageClaimId || '');
            const successMessage = (
                <div className="toast-message">
                    <Icon icon={checkSquare} size={36} /> Successfully Cancel the claim.
                </div>
            );
            history.push('/');
            return toast.success(successMessage, {
                autoClose: 3000,
                closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
            });
        } catch (e) {
            setCancelClaimLoading(true);
            console.error(e);
        }
    };

    const cancel = (e: any) => {
        if (!claim) {
            return false;
        }
        updateState({ ...state, cancelling: true });
        setCancelClaimLoading(false);
        deleteClaim(String(claim.id), reason, (err: Error) => {
            updateState({ ...state, cancelling: false });
            setCancelClaimLoading(true);
            onClose(e);
            if (err) {
                console.error(err);
                toast.error('Could not cancel claim, please try again later.');
                return;
            }           
            const successMessage = (
                <div className="toast-message">
                    <Icon icon={checkSquare} size={36} /> Successfully Cancel the claim.
                </div>
            );
            // history.push('/');
            return toast.success(successMessage, {
                autoClose: 3000,
                closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
            });
        });
    };

    const showModal = () => {
        setModalOpen(true);
      };

    const confirmCancel = () => {
        setModalOpen(false);
        cancel(true);
    };

    const rejectCancel = () => {
        setModalOpen(false);
    };

    const onChangeReason = (value: string) => {
        setReason(value);
    };

    const renderClaim = (claim: IWhatsappsClaim): ReactElement | false => {
        const profile = userProfile;
        const template = partner.claimTemplate.claims;
        let vinDisc = null;
        let vehicleFront = null;
        let driversLicense = null;
        let scensors = null;
        let damagePhotos = null;

        let breakageDate = null;
        if (claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.incidentDate !== undefined && claim.whatsappinsurer.incidentDate !== null) {
            breakageDate = moment(claim?.whatsappinsurer?.incidentDate).format("DD/MM/YYYY");
        }

        let damageGlassDetails: Array<string> = [];
        let calTotalCost = 0;
        if (claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.damageGlassCostDetails !== undefined) {
            damageGlassDetails = claim.whatsappinsurer.damageGlassCostDetails;
            for (const index in damageGlassDetails) {
                calTotalCost = calTotalCost + Number(damageGlassDetails[index].cost);
            }
            calTotalCost = Math.abs(calTotalCost).toFixed(2);
        }

        let damageImages: Array<string> = [];
        if (claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.damagePhotos !== undefined) {
            damageImages = claim.whatsappinsurer.damagePhotos;
        }

        if (claim !== undefined && claim.files !== undefined && claim.files.vin_disc !== undefined) {
            const vinDiscImage = claim.files.vin_disc[0];
            if (vinDiscImage !== undefined) {
                vinDisc = <img src={`${vinDiscImage.file}`} />
            } else {
                vinDisc = <img src={`${qcIcon}`} />
            }
        }
        if (claim !== undefined && claim.files !== undefined && claim.files.vehicle_front !== undefined) {
            const vehicleFrontImage = claim.files.vehicle_front[0];
            if (vehicleFrontImage !== undefined) {
                vehicleFront = <img src={`${vehicleFrontImage.file}`} />
            } else {
                vehicleFront = <img src={`${qcIcon}`} />
            }
        }
        if (claim !== undefined && claim.files !== undefined && claim.files.drivers_license !== undefined) {
            const driversLicenseImage = claim.files.drivers_license[0];
            if (driversLicenseImage !== undefined) {
                driversLicense = <img src={`${driversLicenseImage.file}`} />
            } else {
                driversLicense = <img src={`${qcIcon}`} />
            }
        }
        if (claim !== undefined && claim.files !== undefined && claim.files.scensors !== undefined) {
            const scensorsImage = claim.files.scensors[0];
            if (scensorsImage !== undefined) {
                scensors = <img src={`${scensorsImage.file}`} />
            } else {
                scensors = <img src={`${qcIcon}`} />
            }
        }
        if (claim !== undefined && claim.files !== undefined && claim.files.damage_photos !== undefined) {
            const damagePhotosImage = claim.files.damage_photos[0];
            if (damagePhotosImage !== undefined) {
                damagePhotos = <img src={`${damagePhotosImage.file}`} />
            } else {
                damagePhotos = <img src={`${qcIcon}`} />
            }
        }

        const images = [
            claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.licenceDisc !== undefined ?
                {
                    original: `${claim.whatsappinsurer.licenceDisc}`,
                    thumbnail: `${claim.whatsappinsurer.licenceDisc}`,
                }
                :
                claim !== undefined && claim.files !== undefined && claim.files.vin_disc !== undefined ?
                    {
                        original: vinDisc?.props.src,
                        thumbnail: vinDisc?.props.src,
                    }
                    :
                    {
                        original: `${qcIcon}`,
                        thumbnail: `${qcIcon}`,
                    },
            claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.vehicleFront !== undefined ?
                {
                    original: `${claim.whatsappinsurer.vehicleFront}`,
                    thumbnail: `${claim.whatsappinsurer.vehicleFront}`,
                }
                :
                claim !== undefined && claim.files !== undefined && claim.files.vehicle_front !== undefined ?
                    {
                        original: vehicleFront?.props.src,
                        thumbnail: vehicleFront?.props.src,
                    }
                    :
                    {
                        original: `${qcIcon}`,
                        thumbnail: `${qcIcon}`,
                    },
            claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.driversLicense !== undefined ?
                {
                    original: `${claim.whatsappinsurer.driversLicense}`,
                    thumbnail: `${claim.whatsappinsurer.driversLicense}`,
                }
                :
                claim !== undefined && claim.files !== undefined && claim.files.drivers_license !== undefined ?
                    {
                        original: driversLicense?.props.src,
                        thumbnail: driversLicense?.props.src,
                    }
                    :
                    {
                        original: `${qcIcon}`,
                        thumbnail: `${qcIcon}`,
                    },
            claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.scensor !== undefined ?
                {
                    original: `${claim.whatsappinsurer.scensor}`,
                    thumbnail: `${claim.whatsappinsurer.scensor}`,
                }
                :
                claim !== undefined && claim.files !== undefined && claim.files.scensors !== undefined ?
                    {
                        original: scensors?.props.src,
                        thumbnail: scensors?.props.src,
                    }
                    :
                    {
                        original: `${qcIcon}`,
                        thumbnail: `${qcIcon}`,
                    },
        ];

        if (claim !== undefined && claim.files !== undefined && claim.files.damage_photos !== undefined) {
            images.push({
                original: damagePhotos?.props.src,
                thumbnail: damagePhotos?.props.src,
            })
        } else if (damageImages !== null && damageImages !== undefined) {
            if (damageImages.length === 0) {
                images.push({
                    original: qcIcon,
                    thumbnail: qcIcon
                })
            } else {
                for (const index in damageImages) {
                    images.push({
                        original: damageImages[index],
                        thumbnail: damageImages[index]
                    })
                }
            }
        }

        if (!claim) {
            return false;
        }

        return (
            <div>
                <div className="header-panel">
                    <div>
                        <Button className="close-btn" onClick={(e) => onClose(e)}>
                            <Icon icon={x} size={28} />
                        </Button>
                        <h2>
                            {claim?.whatsappinsurer?.name}
                            <Badge className="status-badge">{claim?.status}</Badge>
                        </h2>
                    </div>
                </div>
                <div>
                    {state.isDisabled && state.hasChanges ? (
                        <div className={`position-fixed-btns edit-buttons ${state.hasChanges ? '' : 'disabled'}`}>
                            <Button
                                color="secondary"
                                size="sm"
                                onClick={saveChanges}
                                disabled={
                                    false
                                }>
                                {state.saving ? 'Authorising...' : 'Authorise'}
                            </Button>
                        </div>
                    ) : null}
                </div>
                <Row className="claim">
                    <Col sm={6} className="todo-list-container">
                        <div className="static">
                            <div className="todo-list">
                                <h3>To Do List</h3>
                                <CardBody>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <input className="checkbox" type="checkbox" id="checkbox1" checked={true} onChange={handleChangeOne} />
                                                <label htmlFor="checkbox1">Confirm cost estimate</label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Input
                                                    value={calTotalCost !== null || calTotalCost !== 0 ? calTotalCost : 0.00}
                                                    type='number'
                                                    placeholder=""
                                                    className="form-checkbox"
                                                    required
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <input className="checkbox" type="checkbox" id="checkbox2" checked={checkedTwo} onChange={handleChangeTwo} />
                                                <label htmlFor="checkbox2">Claim Number</label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Input
                                                    value={claimNumber}
                                                    type='text'
                                                    placeholder="Enter Claim Number"
                                                    className="form-checkbox"
                                                    required
                                                    disabled={claimNumberDisabled}
                                                    onChange={handleChangeClaimNumber}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                    {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.underwriter !== undefined && claim.whatsappinsurer.underwriter !== 'GEMVAS' && profile.partnerId !== 'EXCLUSIVE' ?
                                        <FormGroup row className="my-0">
                                            <Col xs="6" sm="6" md="6">
                                                <FormGroup>
                                                    <input className="checkbox" type="checkbox" id="checkbox3" checked={checkedThree} onChange={handleChangeThree} />
                                                    <label htmlFor="checkbox3">Collect excess from client</label>
                                                    <span style={{ color: 'red' }}> (Excluding VAT)</span>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6" sm="6" md="6">
                                                <FormGroup>
                                                    <Input
                                                        value={excess}
                                                        type='number'
                                                        placeholder="Enter Excess"
                                                        className="form-checkbox"
                                                        required
                                                        disabled={excessDisabled}
                                                        onChange={handleChangeExcess}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </FormGroup>
                                        :
                                        null
                                    }
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <input className="checkbox" disabled type="checkbox" id="checkbox4" />
                                                <label htmlFor="checkbox4">Fitment date</label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <div className="picker">
                                                    <DatePicker
                                                        showTimeSelect
                                                        dateFormat="dd/MM/yyyy h:mmaa"
                                                        selected={startDate}
                                                        selectsStart
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        onChange={(date) => setStartDate(date)}
                                                        showIcon={true}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <input className="checkbox" disabled type="checkbox" id="checkbox5" />
                                                <label htmlFor="checkbox5">Upload invoice</label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <FileDropzone
                                                    loading={false}
                                                    onError={toast.error}
                                                    hideText={false}
                                                    onDrop={null}
                                                    className="drop-file-disabled"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                </CardBody>
                                &nbsp;&nbsp;
                                <FormGroup row className="my-0">
                                    <Col xs="4" sm="4" md="4">
                                        <FormGroup>
                                            <button type="button" className="btn-primary" onClick={downloadQuotePDF}>
                                                {!downloadPowLoading ?
                                                    null :
                                                    <Icon icon={download} size={20} />}
                                                {!downloadPowLoading ?
                                                    ' Downloading...' :
                                                    ' Quote'
                                                }
                                                {!downloadPowLoading && <Icon icon={loader} size={16} className="spin" />}
                                            </button>
                                        </FormGroup>
                                    </Col>
                                    {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.underwriter !== undefined && claim.whatsappinsurer.underwriter !== 'Alpha Direct Insurance Co' && claim.whatsappinsurer.underwriter !== 'Self Insured' ?
                                        <Col xs="4" sm="4" md="4">
                                            <FormGroup>
                                                <button type="button" className="btn-info" onClick={downloadClaimForm}>
                                                    {!downloadInfoLoading ?
                                                        null :
                                                        <Icon icon={download} size={20} />}
                                                    {!downloadInfoLoading ? 'Downloading...' : ' Claim Form'}
                                                    {!downloadInfoLoading && <Icon icon={loader} size={16} className="spin" />}
                                                </button>
                                            </FormGroup>
                                        </Col>
                                        : <Col xs="4" sm="4" md="4"></Col>}
                                    <Col xs="4" sm="4" md="4">
                                        <FormGroup>
                                            <button type="button" className="btn-danger" onClick={showModal}>
                                                {!cancelClaimLoading ?
                                                    null :
                                                    <Icon icon={trash} size={20} />}
                                                {!cancelClaimLoading ? 'Canceling...' : ' Cancel Claim'}
                                                {!cancelClaimLoading && <Icon icon={loader} size={16} className="spin" />}
                                            </button>
                                        </FormGroup>
                                    </Col>
                                </FormGroup>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={insuredChatOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setInsuredChatOpen(!insuredChatOpen);
                                    setInsuredEditOpen(!insuredEditOpen);
                                }}>
                                <div>
                                    Insured Info<Icon icon={insuredChatOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={insuredChatOpen}>
                                <CardBody>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Registration</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.registrationNumber !== undefined ? claim?.whatsappinsurer.registrationNumber : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="name" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer Name</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.name !== undefined ? claim?.whatsappinsurer.name : ''}
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="customerEmail" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer Email Address</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.customerEmail !== undefined ? claim?.whatsappinsurer.customerEmail : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="mobileNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer preferred Contact No.</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.mobileNumber !== undefined ? claim?.whatsappinsurer.mobileNumber : ''}
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="isLandlineNumberExist" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Does the Insured/Customer have a Landline No?</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isLandlineNumberExist !== undefined ? claim?.whatsappinsurer.isLandlineNumberExist : ''}
                                            </FormGroup>
                                        </Col>
                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isLandlineNumberExist !== undefined ? (
                                            <>
                                                {claim.whatsappinsurer.isLandlineNumberExist === 'Yes' ?
                                                    (<Col xs="6" sm="6" md="6">
                                                        <FormGroup>
                                                            <Label htmlFor="landlineNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer Landline No.</Label>
                                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.landlineNumber !== undefined ? claim?.whatsappinsurer.landlineNumber : ''}
                                                        </FormGroup>
                                                    </Col>)
                                                    : null}
                                            </>
                                        )

                                            : ('')
                                        }
                                    </FormGroup>
                                </CardBody>
                            </Collapse>
                        </div>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={incidentEditOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setIncidentEditOpen(!incidentEditOpen);
                                    setIncidentChatOpen(!incidentChatOpen);
                                }}>
                                <div>
                                    Incident Details<Icon icon={incidentEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={incidentEditOpen}>
                                <CardBody>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Date of Breakage (DD/MM/YYYY)</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.incidentDate !== undefined ? breakageDate : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Cause of Breakage</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.causeOfBreakage !== undefined ? claim?.whatsappinsurer.causeOfBreakage : ''}
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row className="my-0">
                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isDriversNameSameInsured !== undefined ? (
                                            <>
                                                {claim.whatsappinsurer.isDriversNameSameInsured !== 'Yes' ?
                                                    <><Col xs="6" sm="6" md="6">
                                                        <FormGroup>
                                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Is the driver’s name same as the insured?</Label>
                                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isDriversNameSameInsured !== undefined ? claim?.whatsappinsurer.isDriversNameSameInsured : ''}
                                                        </FormGroup>
                                                    </Col><Col xs="6" sm="6" md="6">
                                                            <FormGroup>
                                                                <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Driver Name</Label>
                                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.driversName !== undefined ? claim?.whatsappinsurer.driversName : ''}
                                                            </FormGroup>
                                                        </Col></>
                                                    : <Col xs="12" sm="12" md="12">
                                                        <FormGroup>
                                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Is the driver’s name same as the insured?</Label>
                                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isDriversNameSameInsured !== undefined ? claim?.whatsappinsurer.isDriversNameSameInsured : ''}
                                                        </FormGroup>
                                                    </Col>}
                                            </>
                                        )
                                            : (
                                                ''
                                            )}
                                    </FormGroup>

                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Was there a witness to the incident?</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isWitnessToIncident !== undefined ? claim?.whatsappinsurer.isWitnessToIncident : ''}
                                        </Col>
                                    </FormGroup>

                                    {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isWitnessToIncident !== undefined ? (
                                        <>
                                            {claim.whatsappinsurer.isWitnessToIncident === 'Yes' ?

                                                <><FormGroup row className="my-0">
                                                    <Col xs="6" sm="6" md="6">
                                                        <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Witness Name</Label>
                                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.witnessName !== undefined ? claim?.whatsappinsurer.witnessName : ''}
                                                    </Col>
                                                    <Col xs="6" sm="6" md="6">
                                                        <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Witness Mobile No.</Label>
                                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.witnessMobileNumber !== undefined ? claim?.whatsappinsurer.witnessMobileNumber : ''}
                                                    </Col>
                                                </FormGroup></>
                                                : null}
                                        </>
                                    )
                                        : (
                                            ''
                                        )}
                                </CardBody>
                                <CardHeader style={{ textAlign: 'center', fontSize: '0.8em', color: 'black', fontWeight: 'bold' }}>
                                    Details of Broken Glass (<span style={{ color: 'blue' }}>Optional</span>)
                                </CardHeader>
                                <CardBody>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Full decription of broken Glass</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.glassDecription !== undefined ? claim?.whatsappinsurer.glassDecription : ''}
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Size and thickness in millimetres</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.glassSizeThickness !== undefined ? claim?.whatsappinsurer.glassSizeThickness : ''}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Is the Glass Cracked or shattered?</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.glassCrackeShattered !== undefined ? claim?.whatsappinsurer.glassCrackeShattered : ''}
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Any signwriting on broken glass?</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.glassSignwriting !== undefined ? claim?.whatsappinsurer.glassSignwriting : ''}
                                        </Col>
                                    </FormGroup>
                                </CardBody>
                            </Collapse>
                        </div>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={imagesEditOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setImagesEditOpen(!imagesEditOpen);
                                    setImagesChatOpen(!imagesChatOpen);
                                }}>
                                <div>
                                    Images<Icon icon={imagesEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={imagesEditOpen}>
                                <div className="scrollbar-container sidebar-info-scrollbar ps">
                                    <div className="claim-group">
                                        <Table className="sidepanel-info-table table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <div className="image-gallery-wrapper">
                                                        <ImageGallery items={images} />
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={damageEditOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setDamageEditOpen(!damageEditOpen);
                                    setDamageChatOpen(!damageChatOpen);
                                }}>
                                <div>
                                    Damage Windscreen/Glass Details <Icon icon={damageEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={damageEditOpen}>
                                <CardBody>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Make</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.vehicleMake !== undefined ? claim?.whatsappinsurer.vehicleMake : ''}
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Model</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.vehicleModel !== undefined ? claim?.whatsappinsurer.vehicleModel : ''}
                                        </Col>
                                    </FormGroup>
                                </CardBody>
                                <br />
                                {/* <div className="scrollbar-container sidebar-info-scrollbar ps">
                                    <div className="claim-group">
                                        <Table className="sidepanel-info-table table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Make</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Model</td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div> */}

                                <div className="scrollbar-container sidebar-info-scrollbar ps">
                                    <div className="claim-group">
                                        <Table className="sidepanel-info-table table table-borderless">
                                            <tbody>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '250px' }}>Part Number</th>
                                                        <th style={{ width: '250px' }}>Description</th>
                                                        <th style={{ width: '250px' }}>Quantity</th>
                                                        <th style={{ width: '300px' }}>Cost <span style={{ color: 'red' }}> (Excluding VAT)</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {damageGlassDetails.map((data, index) => {
                                                        const { partNumber, partDescription, partQuantity, cost } = data;
                                                        return (<tr key={index}>
                                                            <td style={{ width: '250px' }}>
                                                                {
                                                                    partNumber
                                                                }
                                                            </td>
                                                            <td style={{ width: '250px' }}>
                                                                {
                                                                    partDescription === '' ? 'Shattered' : partDescription
                                                                }
                                                            </td>
                                                            <td style={{ width: '250px' }}>
                                                                {
                                                                    partQuantity
                                                                }
                                                            </td>
                                                            <td style={{ width: '300px' }}>
                                                                {
                                                                    cost
                                                                }
                                                            </td>
                                                        </tr>)
                                                    })}
                                                    <tr>
                                                        <td></td>
                                                        <td ></td>
                                                        <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Total Claim Cost</td>
                                                        <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>
                                                            {
                                                                calTotalCost
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={noteEditOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setNoteEditOpen(!noteEditOpen);
                                    setNoteChatOpen(!noteChatOpen);
                                }}>
                                <div>
                                    Claim Notes <Icon icon={noteEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} isOpen={noteEditOpen}>
                                <div className="static">
                                    <NotesComponent claim={claim} />
                                </div>
                            </Collapse>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    if (!claim) {
        return <div></div>;
    }

    return (
        <div>
            <CancelModal
                claim={claim}
                onConfirm={confirmCancel}
                onCancel={rejectCancel}
                isOpen={modalOpen}
                reason={reason}
                onChangeReason={onChangeReason}
            />
            <div className={`side-panel-overlay ${claim ? 'open' : ''}`}></div>
            <div id="incoming-side-panel" className={`side-panel ${claim ? 'open' : ''}`}>
                {claim ? renderClaim(claim) : false}
            </div>
        </div>
    );
};

export default connect(null, { updateWhatsappAuthoriseClaim, deleteClaim })(WhatsappQuoteReceivedSidePanel);
