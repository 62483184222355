/* eslint-disable prettier/prettier */
import { Badge, Button } from 'reactstrap';
import { TileClaimsProps } from '../../claims/whatsapp/typings';
import React from 'react';
import _ from 'lodash';
import history from '../../../helpers/history';

const whatsappQuoteReceived: React.FC<TileClaimsProps> = ({ whatsappclaims, partner, userProfile }) => {
  // console.log(userProfile);
  const unclaimed = _.reduce(
    _.map(whatsappclaims, (c) => (c?.assign.owners && !_.isString(c?.assign.owners.partner) ? 1 : 0)),
    (sum, n) => sum + n,
    0,
  );
  const isDisabled = _.size(whatsappclaims) <= 0;
  return (
    <Button className="claim-tile" disabled={isDisabled} onClick={() => history.push('/whatsapp-quotes-received')}>
      {userProfile.userType === 'Broker' ?
        <Badge color="danger" className="claim-tile-badge">
          <span>{partner.name}</span>
          {/* <strong>{partner.name}</strong> */}
        </Badge>
        : null
      }             
      <div className="counter">{_.size(whatsappclaims)}</div>
      <div className="title">Auth Required</div>
      <div className="subtitle" style={{color:'green', fontWeight: 'bold'}}>Action Taken by Broker</div>
      <div className="subtitle" style={{color:'Red', fontWeight: 'bold'}}>Step 3</div>
    </Button>
  );
};

export default whatsappQuoteReceived;
