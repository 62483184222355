/* eslint-disable prettier/prettier */
import { Button, Table } from 'reactstrap';
import { WhatsappConfirmModalProps } from '../claims/whatsapp/typings';
import Modal from 'react-modal';
import React from 'react';

const WhatsappConfirmModal: React.FC<WhatsappConfirmModalProps> = ({ whatsappclaim, isOpen, onConfirm, onCancel }) => {
  return (
    <div>
      <Modal isOpen={isOpen} className="confirm-claim-modal" ariaHideApp={false}>
        <h2>Start Whatsapp Journey</h2>
        <p>
          I hereby confirm that all details captured by me in this whatsapp journey are accurate and to the
          best of my knowledge, true.
        </p>
        {/* <p>
          <b>
            All the costs are converted to EURO, please do check the conversion and confirm if the
            values are correct
          </b>
        </p> */}
        <Table borderless>
          <tbody>
          <tr>
              <td className="confirm-modal-label">Insurer Name</td>
              <td>{whatsappclaim?.whatsappinsurer.name}</td>
            </tr>
            <tr>
              <td className="confirm-modal-label">Insurer Policy Number</td>
              <td>{whatsappclaim?.whatsappinsurer.policyNumber}</td>
            </tr>
            <tr>
              <td className="confirm-modal-label">Insurer Mobile Number</td>
              <td>{whatsappclaim?.whatsappinsurer.mobileNumber}</td>
            </tr>
            <tr>
              <td className="confirm-modal-label">Underwiter</td>
              <td>{whatsappclaim?.whatsappinsurer.underwriter}</td>
            </tr>
            <tr>
              <td className="confirm-modal-label">Broker</td>
              <td>{whatsappclaim?.whatsappinsurer.broker}</td>
            </tr>
            <tr>
              <td className="confirm-modal-label">Email</td>
              <td>{whatsappclaim?.whatsappinsurer.email}</td>
            </tr>
          </tbody>
        </Table>
        <div className="confirm-modal-buttons">
          <Button color="secondary" outline onClick={() => onCancel()}>
            No, Go Back to Journey
          </Button>
          <Button color="secondary" onClick={() => onConfirm()}>
            Yes, Create Journey
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default WhatsappConfirmModal;
