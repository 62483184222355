/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import '../styles/Wrapper.scss';
import { ToastContainer } from 'react-toastify';
import React, { Component } from 'react';
import Sidebar from '../components/sidebar/Sidebar';
import _ from 'lodash';

import 'react-toastify/dist/ReactToastify.css';
// eslint-disable-next-line import/named
import { RouteComponentProps } from 'react-router-dom';
import { adminPaths } from '../helpers/utils';

export default class App extends Component<RouteComponentProps> {
  render() {
    const {
      match: { path },
    } = this.props;
    const routes = ['/incoming', '/outgoing', '/new-claim', '/completed', ...adminPaths, '/whatsapp-outgoing'];
    const activeClass = _.includes(routes, path) ? 'compact' : '';
    return (
      <div className="app-container">
        <ToastContainer />
        <div className={`app-sidebar ${activeClass}`}>
          <Sidebar isCompact={activeClass === 'compact'} />
        </div>
        <div className="app-main">{this.props.children}</div>
      </div>
    );
  }
}
