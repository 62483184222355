/* eslint-disable prettier/prettier */
import { Badge, Button } from 'reactstrap';
import { QueueRowProps } from '../../whatsapp-typing';
import { connect} from 'react-redux';
import { deleteClaim1, generateQuotePDF, generateQuotePDFForHollardBIC } from '../../../../actions/update-claim';
import {download} from 'react-icons-kit/icomoon/download';
import { loader } from 'react-icons-kit/feather';
import {trash} from 'react-icons-kit/iconic/trash'
import { useFirestoreConnect } from 'react-redux-firebase';
import Icon from 'react-icons-kit';
import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

const WhatsappOutgoingRow: React.FC<QueueRowProps> = ({
  claim,
  onClick,
  downloadQuotePDF,
  downloadClaimForm,
  showDownloadIcon,
  deleteClaimIcon,
  deactivateClaim,
}) => {
  const [downloadPowLoading, setDownloadPowLoading] = useState(true);
  const [downloadInfoLoading, setDownloadInfoLoading] = useState(true);
  const [cancelClaimLoading, setCancelClaimLoading] = useState(true);

  const updateDate = !_.isDate(claim._audit.updated.timestamp)
    ? moment(claim._audit.updated.timestamp.toDate()).format('DD MMM, YYYY')
    : '-';
  const createDateMoment = !_.isDate(claim._audit.created.timestamp)
    ? moment(claim._audit.created.timestamp.toDate())
    : moment();
  const createDate = createDateMoment.format('DD MMM, YYYY');
  const todayMoment = moment();
  const daysOpen = Math.max(todayMoment.diff(createDateMoment, 'days'), 1);

  let query: any = [];

  if (claim.whatsappinsurer.name) {
    query = [
      {
        collection: 'whatsapp',
        doc: claim.whatsappinsurer.name,
      },
    ];
  }

  const openPdf = (base64str: string) => {
    // decode base64 string, remove space for IE compatibility
    const binary = atob(base64str.replace(/\s/g, ''));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    // create the blob object with content-type "application/pdf"
    const blob = new Blob([view], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    window.open(url);
    setDownloadPowLoading(true);
    setDownloadInfoLoading(true);
  };

  downloadQuotePDF = async () => {
    try {
      setDownloadPowLoading(false);
      const pdfFile = await generateQuotePDF(claim.messageClaimId || '', false);
      if (pdfFile) {
        openPdf(pdfFile);
      }
    } catch (e) {
      setDownloadPowLoading(true);
      console.error(e);
    }
  };

  downloadClaimForm = async () => {
    try {
      setDownloadInfoLoading(false);
      const pdfFile = await generateQuotePDFForHollardBIC(claim.messageClaimId || '', false);
      if (pdfFile) {
        openPdf(pdfFile);
      }
    } catch (e) {
      setDownloadInfoLoading(true);
      console.error(e);
    }
  };

  deactivateClaim = async () => {
    try {
      setCancelClaimLoading(false);
      const response = await deleteClaim1(claim.messageClaimId || '', '', Error);
      console.log(response);
    } catch (e) {      
      setCancelClaimLoading(true);
      console.error(e);
    }
  };
  useFirestoreConnect(query);

  if (!claim) {
    return null;
  }

  return (
    <tr>
      <td>
        <Button className="table-inline-button" onClick={(e) => onClick(e, claim)}>
          {claim.whatsappinsurer.name}
        </Button>        
      </td>
      <td className="text-center">{claim.whatsappinsurer.mobileNumber}</td>
      <td className="text-center">{claim.whatsappinsurer.registrationNumber}</td>
      <td className="text-center">{claim.whatsappinsurer.broker}</td>
      <td className="text-center">{claim.whatsappinsurer.underwriter}</td>      
      <td className="text-center">
        <Badge color="secondary" size="lg">
          {daysOpen}
        </Badge>
      </td>
      <td className="text-center">{createDate}</td>
      <td className="text-center">{updateDate}</td>
      {showDownloadIcon && (
      <td className="text-center">
          {(claim?.status === 'AUTHREQUIRED' || claim?.status === 'COMPLETED' || claim?.status === 'AUTHORIZED') && claim.active  ? (
            <>
              {downloadPowLoading ? (
                <div>
                  <Icon
                    onClick={downloadQuotePDF?.bind(this, claim.messageClaimId || '')}
                    icon={download}
                    size={20}
                  />
                </div>
              ) : (
                <div>
                    {(!downloadPowLoading) && <Icon icon={loader} size={20} className="spin" />}
                </div>
              )}
            </>
          ) : (
            false
          )}
      </td>
       )}
      {showDownloadIcon && (
      <td className="text-center">
          {claim.active && claim.whatsappinsurer.underwriter !== 'Alpha Direct Insurance Co' && claim.whatsappinsurer.underwriter !== 'Self Insured' && (claim?.status === 'AUTHREQUIRED' || claim?.status === 'COMPLETED' || claim?.status === 'AUTHORIZED') ? (
            <>
              {downloadInfoLoading ? (
                <div>
                  <Icon
                    onClick={downloadClaimForm?.bind(this, claim.messageClaimId || '')}
                    icon={download}
                    size={20}
                  />
                </div>
              ) : (
                <div>
                    {(!downloadInfoLoading) && <Icon icon={loader} size={20} className="spin" />}
                </div>
              )}
            </>
          ) : (
            false
          )}
      </td>
       )}
       {deleteClaimIcon && (
        <td className="text-center">
          <div>
            {/* {<Icon 
            onClick={deactivateClaim?.bind(this, claim.messageClaimId || '')}
            icon={trash} 
            size={20}/>} */}
            <button type="button" className="btn-danger" onClick={deactivateClaim?.bind(this, claim.messageClaimId || '')}>
              {!cancelClaimLoading ?
                null :
                <Icon icon={trash} size={10} />}
              {!cancelClaimLoading ? 'Cancelling...' : ' Cancel'}
              {!cancelClaimLoading && <Icon icon={loader} size={12} className="spin" />}
            </button>
          </div>
        </td>
      )}
    </tr>
  );
};

export default connect(null)(WhatsappOutgoingRow);
