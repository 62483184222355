/* eslint-disable prettier/prettier */
import '../../../styles/Checkboxes.scss';
import '../../../styles/image-gallery.scss';
import "react-datepicker/dist/react-datepicker.css";
import 'react-image-gallery/styles/scss/image-gallery.scss';
import {
    Badge, Button, Card,
    CardBody,
    CardFooter,
    CardHeader, Col, Collapse, FormGroup, Input, Label, Row, Table
} from 'reactstrap';
import { FileDropZone } from '../side-panel/inputs/FileDropzone';
import { IWhatsappClaimPayload } from '../../../../../typings';
import { IWhatsappsClaim, WhatsappQuoteAuthorizedSidePanelProps } from '../whatsapp-typing';
import { checkSquare, chevronDown, chevronUp, eye, loader, trash2, x } from 'react-icons-kit/feather';
import { connect } from 'react-redux';
import { deleteClaim, generateQuotePDF, generateQuotePDFForHollardBIC, updateWhatsappCompletedClaim, whatsappDownloadFiles, whatsappRemoveFiles, whatsappUploadFile } from '../../../actions/update-claim';
import { download } from 'react-icons-kit/icomoon/download';
import { toast } from 'react-toastify';
import { trash } from 'react-icons-kit/iconic/trash';
import { updateWhatsappClaimValue } from '../../../actions/whatsapp/index';
import CancelModal from '../../modals/CancelModal';
import DatePicker from "react-datepicker"
import DeleteFilesModal from '../../modals/DeleteFilesModal';
import Icon from 'react-icons-kit';
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import Modal from 'react-modal';
import NotesComponent from '../shared/WhatsappNotes';
import React, { ReactElement, useEffect, useState } from 'react';
import _ from 'lodash';
import history from '../../../helpers/history';
import moment from 'moment';
import qcIcon from "../../../images/QC.svg";

const initialState: { loading: boolean; saving: boolean; hasChanges: boolean; isDisabled: boolean; loadingDelete: boolean, cancelling: boolean } = {
    saving: false,
    hasChanges: false,
    loading: false,
    loadingDelete: false,
    isDisabled: false,
    cancelling: false,
};

const initialStateForRemove: { loading: boolean; loadingDelete: boolean } = {
    loading: false,
    loadingDelete: false
};

const initialImages: ReactImageGalleryItem[] = [];

let dropClass = 'drop-file-disabled';

const WhatsappQuoteAuthorizedSidePanel: React.FC<WhatsappQuoteAuthorizedSidePanelProps> = ({
    claim,
    userProfile,
    onClose,
    whatsappUploadFile,
    whatsappDownloadFiles,
    whatsappRemoveFiles,
    updateWhatsappCompletedClaim,
    deleteClaim
}) => {
    const claimSansAudit: IWhatsappClaimPayload = _.pick(claim, [
        'whatsappinsurer',
    ]);
    const [updatedClaim, updateClaimState] = useState({ ...claimSansAudit });
    const [state, setState] = useState(initialState);
    const [stateRemove, setRemoveState] = useState(initialStateForRemove);
    const [insuredEditOpen, setInsuredEditOpen] = useState(false);
    const [incidentEditOpen, setIncidentEditOpen] = useState(false);
    const [imagesEditOpen, setImagesEditOpen] = useState(false);
    const [damageEditOpen, setDamageEditOpen] = useState(false);
    const [noteEditOpen, setNoteEditOpen] = useState(false);
    const [insuredChatOpen, setInsuredChatOpen] = useState(true);
    const [incidentChatOpen, setIncidentChatOpen] = useState(true);
    const [imagesChatOpen, setImagesChatOpen] = useState(true);
    const [damageChatOpen, setDamageChatOpen] = useState(true);
    const [noteChatOpen, setNoteChatOpen] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [fileLoading, setFileLoading] = useState<any>({});
    const [fileModalOpen, setFileModalOpen] = useState(false);

    const [checkedFour, setCheckedFour] = React.useState(false);
    const [checkedFive, setCheckedFive] = React.useState(false);

    const [attachedFiles, setAttachedFiles] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    const [keyToDelete, setKeyToDelete] = useState('');
    const [isOpen, toggleModal] = useState(false);
    const [images, setImages] = useState(initialImages);

    const [fitmentDateDisabled, setFitmentDateDisabled] = useState(true);
    const [uploadInvoiceDisabled, setUploadInvoiceDisabled] = useState(true);

    const [downloadPowLoading, setDownloadPowLoading] = useState(true);
    const [downloadInfoLoading, setDownloadInfoLoading] = useState(true);
    const [cancelClaimLoading, setCancelClaimLoading] = useState(true);

    const [eyeLoading, setLoading] = useState(new Map());

    const [modalOpen1, setModalOpen1] = useState(false);
    const [reason, setReason] = useState('');

    const collapseConfig = {
        show: 100,
        hide: 100,
    };

    const handleChangeFour = (event: { target: { checked: any; }; }) => {
        setCheckedFour(!checkedFour);
        if (event.target.checked) {
            // state.isDisabled = true;
            // state.hasChanges = true;
            setCheckedFour(!checkedFour);
            setFitmentDateDisabled(false);
        } else {
            state.isDisabled = false;
            state.hasChanges = false;
            setCheckedFour(!checkedFour);
            setFitmentDateDisabled(true);
        }
    };

    const handleChangeFive = (event: { target: { checked: any; }; }) => {
        if (event.target.checked) {
            // state.isDisabled = true;
            // state.hasChanges = true;
            setCheckedFive(!checkedFive);
            setUploadInvoiceDisabled(false);
            dropClass = 'todo-drop-file';
        } else {
            state.isDisabled = false;
            state.hasChanges = false;
            setCheckedFive(!checkedFive);
            setUploadInvoiceDisabled(true);
            dropClass = 'drop-file-disabled';
        }
    };

    useEffect(() => {
        if (keyToDelete !== '') {
            setModalOpen(true);
        }
    }, [keyToDelete]);

    useEffect(() => {
        if (claim?.whatsappinsurer?.name !== updatedClaim.whatsappinsurer?.name) {
            reset();
        }
        // we don't need exhaustive hook dep check, shut the linter up.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [claim]);

    useEffect(() => {
        checkForChanges();
        // we don't need exhaustive hook dep check, shut the linter up.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedClaim]);

    const reset = () => {
        state.isDisabled = false;
        state.hasChanges = false;
    };

    const checkForChanges = (): boolean => {
        const claimSansAudit: IWhatsappClaimPayload = _.pick(claim, [
            'whatsappinsurer',
        ]);
        const hasChanges = !_.isEqual(updatedClaim, claimSansAudit);
        setState({ ...state, hasChanges });
        return hasChanges;
    };

    const onChangeBookInDate = () => {
        const updatedBookInData = {
            bookInDate: startDate.toLocaleDateString(),
            status: 'AUTHORIZED',
            whatsapptodos: {
                bookInDate: true,
                invoice: false,
            }
        }
        return updatedBookInData;
    }

    const onChangeUploadInvoice = () => {
        const updatedFinalClaimStatus = {
            status: 'COMPLETED',
            bookInDate: claim.whatsappinsurer.bookInDate,
            whatsapptodos: {
                bookInDate: true,
                invoice: true,
            }
        }
        return updatedFinalClaimStatus;
    }

    const saveChanges = (e: any) => {
        if (checkedFour !== false) {
            setState({ ...state, saving: true });
            const updatedBookInDate = onChangeBookInDate();
            updateWhatsappCompletedClaim(String(claim.id), updatedBookInDate, (err: Error) => {
                setState({ ...initialState });
                if (err) {
                    toast.error('Could not update fitment date to claim. Please try again later');
                    console.error(err);
                    return;
                }
                onClose(e);
                const successMessage = (
                    <div className="toast-message">
                        <Icon icon={checkSquare} size={36} /> Fitment Date Updated Successfully to the claim.
                    </div>
                );
                // history.push('/');
                return toast.success(successMessage, {
                    autoClose: 3000,
                    closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
                });
            });
        } else if (checkedFive !== false) {
            setState({ ...state, saving: true });
            const updatedInvoiceStatus = onChangeUploadInvoice();
            updateWhatsappCompletedClaim(String(claim.id), updatedInvoiceStatus, (err: Error) => {
                setState({ ...initialState });
                if (err) {
                    toast.error('Could not update claim. Please try again later');
                    console.error(err);
                    return;
                }
                onClose(e);
                const successMessage = (
                    <div className="toast-message">
                        <Icon icon={checkSquare} size={36} /> Successfully Authorised the claim.
                    </div>
                );
                // history.push('/');
                return toast.success(successMessage, {
                    autoClose: 3000,
                    closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
                });
            });
        } else {
            const errorMessage = (
                <div className="toast-message">
                    <Icon icon={checkSquare} size={36} /> Kindly fill the mandetory data.
                </div>
            );
            return toast.error(errorMessage, {
                autoClose: 3000,
                closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
            });
        }
    };

    const showLoader = (): ReactElement | false => {
        if (!state.loading) {
            return <Icon icon={eye} size={16} />;
        }
        return <Icon icon={loader} size={16} className="spin" />;
    };

    const showLoaderTrash = (): ReactElement | false => {
        if (!state.loadingDelete) {
            return <Icon icon={trash2} size={16} />;
        }
        return <Icon icon={loader} size={16} className="spin" />;
    };

    const openDeleteFileModal = (key: string) => {
        setKeyToDelete(key);
    };

    const openFile = (pdf: string, mimeType: string) => {
        const binaryString = atob(pdf);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);

        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        // create the blob object with content-type "application/pdf"
        const blob = new Blob([bytes], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url);
    };

    const openPdf = async (files: {
        [index: number]: { data: any; mimeType: string };
    }): Promise<void> => {
        _.each(files, (file) => {
            openFile(file.data, file.mimeType);
        });
    };

    const openImageModal = (files: { data: string; mimeType: string }[]) => {
        const imgs: ReactImageGalleryItem[] = [];
        _.each(files, ({ data: file, mimeType }) => {
            imgs.push({
                original: `${mimeType},${file}`,
                thumbnail: `${mimeType},${file}`,
            });
        });
        setImages(imgs);
        toggleModal(true);
    };

    const downloadInvoice = (file: Array<{ fileName: string; mimeType: string }>, key: string) => {
        setLoading(_.cloneDeep(eyeLoading.set(file[0].fileName, true)));
        whatsappDownloadFiles(file, (err: Error, files: any) => {
            setLoading(_.cloneDeep(eyeLoading.set(file[0].fileName, false)));
            if (err) {
                console.error(err);
                toast.error(`Could not retrieve ${key}, please try again later.`);
                return;
            }
            const imageFiles = files.filter((file: any) => {
                return !file?.mimeType.includes('pdf');
            });
            const pdfFiles = files.filter((file: any) => {
                return file?.mimeType.includes('pdf');
            });
            if (imageFiles && imageFiles.length > 0) {
                openImageModal(imageFiles);
            }
            if (pdfFiles && pdfFiles.length > 0) {
                openPdf(pdfFiles);
            }
        });
    };

    const deleteFiles = () => {
        setModalOpen(false);
        setAttachedFiles([]);
        if (keyToDelete !== '') {
            setRemoveState({ loading: false, loadingDelete: true });
            setModalOpen(false);
            whatsappRemoveFiles(keyToDelete, String(claim.id), (err: Error, files: any) => {
                setRemoveState({ loading: false, loadingDelete: false });
                if (err) {
                    console.error(err);
                    toast.error(`Could not retrieve ${keyToDelete}, please try again later.`);
                    return;
                }
                setCheckedFive(false);
                toast.success('Files deleted successfully');
            });
        }
    };

    const onDropFile = (files: any): void => {
        if (!claim) {
            return;
        }
        setFileLoading(true);
        setAttachedFiles(files);
        const id = claim.id as string;
        whatsappUploadFile(id, { files, key: 'invoice' }, (err: Error) => {
            setFileLoading(false);
            if (err) {
                console.error(err);
                return toast.error('Error uploading invoice, please try again later');
            }

            const successMessage = (
                <div className="toast-message">
                    <Icon icon={checkSquare} size={36} /> Successfully uploaded file.
                </div>
            );
            return toast.success(successMessage, {
                autoClose: 3000,
                closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
            });
        });
    };

    const confirmCancel = () => {
        deleteFiles();
    };

    const rejectCancel = () => {
        setModalOpen(false);
        setKeyToDelete('');
    };

    const openPdfForQuoteClaimForm = (base64str: string) => {
        const binary = atob(base64str.replace(/\s/g, ''));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }
        const blob = new Blob([view], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url);
        setDownloadPowLoading(true);
        setDownloadInfoLoading(true);
    };

    const downloadQuotePDF = async () => {
        try {
            setDownloadPowLoading(false);
            const pdfFile = await generateQuotePDF(claim.messageClaimId || '', false);
            if (pdfFile) {
                openPdfForQuoteClaimForm(pdfFile);
            }
        } catch (e) {
            setDownloadPowLoading(true);
            console.error(e);
        }
    };

    const downloadClaimForm = async () => {
        try {
            setDownloadInfoLoading(false);
            const pdfFile = await generateQuotePDFForHollardBIC(claim.messageClaimId || '', false);
            if (pdfFile) {
                openPdfForQuoteClaimForm(pdfFile);
            }
        } catch (e) {
            setDownloadInfoLoading(true);
            console.error(e);
        }
    };

    // const cancelClaim = async () => {
    //     try {
    //         setCancelClaimLoading(false);
    //         const response = await deleteClaim(claim.messageClaimId || '');
    //         const successMessage = (
    //             <div className="toast-message">
    //                 <Icon icon={checkSquare} size={36} /> Successfully Cancel the claim.
    //             </div>
    //         );
    //         history.push('/');
    //         return toast.success(successMessage, {
    //             autoClose: 3000,
    //             closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
    //         });
    //     } catch (e) {
    //         setCancelClaimLoading(true);
    //         console.error(e);
    //     }
    // };

    const cancel = (e: any) => {
        if (!claim) {
            return false;
        }
        setState({ ...state, cancelling: true });
        setCancelClaimLoading(false);
        deleteClaim(String(claim.id), reason, (err: Error) => {
            setState({ ...state, cancelling: false });
            setCancelClaimLoading(true);
            onClose(e);
            if (err) {
                console.error(err);
                toast.error('Could not cancel claim, please try again later.');
                return;
            }           
            const successMessage = (
                <div className="toast-message">
                    <Icon icon={checkSquare} size={36} /> Successfully Cancel the claim.
                </div>
            );
            // history.push('/');
            return toast.success(successMessage, {
                autoClose: 3000,
                closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
            });
        });
    };

    const showModal = () => {
        setModalOpen1(true);
    };
    
    const confirmCancel1 = () => {
        setModalOpen1(false);
        cancel(true);
    };

    const rejectCancel1 = () => {
        setModalOpen1(false);
    };

    const onChangeReason = (value: string) => {
        setReason(value);
    };
    
    const renderClaim = (claim: IWhatsappsClaim): ReactElement | false => {
        const profile = userProfile;
        let vinDisc = null;
        let vehicleFront = null;
        let driversLicense = null;
        let scensors = null;
        let damagePhotos = null;

        const todaysDate =moment(new Date()).format("DD/MM/YYYY");
        let fitmentDate = null;
        if (claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.bookInDate !== undefined && claim.whatsappinsurer.bookInDate !== null) {
            fitmentDate = claim?.whatsappinsurer?.bookInDate;
        }

        let breakageDate = null;
        if (claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.incidentDate !== undefined && claim.whatsappinsurer.incidentDate !== null) {
            breakageDate = moment(claim?.whatsappinsurer?.incidentDate).format("DD/MM/YYYY");
        }        

        if (claim !== undefined && claim.files !== undefined && claim.files.invoice !== undefined) {
            if (claim.files.invoice.length > 0 && checkedFive) {
                state.hasChanges = true;
                state.isDisabled = true;
            } else {
                state.hasChanges = false;
                state.isDisabled = false;
            }
        }

        let damageGlassDetails: Array<string> = [];
        let calTotalCost = 0;
        if (claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.damageGlassCostDetails !== undefined) {
            damageGlassDetails = claim.whatsappinsurer.damageGlassCostDetails;
            for (const index in damageGlassDetails) {
                calTotalCost = calTotalCost + Number(damageGlassDetails[index].cost);
            }
            calTotalCost = Math.abs(calTotalCost).toFixed(2);
        }

        const uploding = state.loading;

        let damageImages: Array<string> = [];
        if (claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.damagePhotos !== undefined) {
            damageImages = claim.whatsappinsurer.damagePhotos;
        }

        if (claim !== undefined && claim.files !== undefined && claim.files.vin_disc !== undefined) {
            const vinDiscImage = claim.files.vin_disc[0];
            if (vinDiscImage !== undefined) {
                vinDisc = <img src={`${vinDiscImage.file}`} />
            } else {
                vinDisc = <img src={`${qcIcon}`} />
            }
        }
        if (claim !== undefined && claim.files !== undefined && claim.files.vehicle_front !== undefined) {
            const vehicleFrontImage = claim.files.vehicle_front[0];
            if (vehicleFrontImage !== undefined) {
                vehicleFront = <img src={`${vehicleFrontImage.file}`} />
            } else {
                vehicleFront = <img src={`${qcIcon}`} />
            }
        }
        if (claim !== undefined && claim.files !== undefined && claim.files.drivers_license !== undefined) {
            const driversLicenseImage = claim.files.drivers_license[0];
            if (driversLicenseImage !== undefined) {
                driversLicense = <img src={`${driversLicenseImage.file}`} />
            } else {
                driversLicense = <img src={`${qcIcon}`} />
            }
        }
        if (claim !== undefined && claim.files !== undefined && claim.files.scensors !== undefined) {
            const scensorsImage = claim.files.scensors[0];
            if (scensorsImage !== undefined) {
                scensors = <img src={`${scensorsImage.file}`} />
            } else {
                scensors = <img src={`${qcIcon}`} />
            }
        }
        if (claim !== undefined && claim.files !== undefined && claim.files.damage_photos !== undefined) {
            const damagePhotosImage = claim.files.damage_photos[0];
            if (damagePhotosImage !== undefined) {
                damagePhotos = <img src={`${damagePhotosImage.file}`} />
            } else {
                damagePhotos = <img src={`${qcIcon}`} />
            }
        }

        const images = [
            claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.licenceDisc !== undefined ?
                {
                    original: `${claim.whatsappinsurer.licenceDisc}`,
                    thumbnail: `${claim.whatsappinsurer.licenceDisc}`,
                }
                :
                claim !== undefined && claim.files !== undefined && claim.files.vin_disc !== undefined ?
                    {
                        original: vinDisc?.props.src,
                        thumbnail: vinDisc?.props.src,
                    }
                    :
                    {
                        original: `${qcIcon}`,
                        thumbnail: `${qcIcon}`,
                    },
            claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.vehicleFront !== undefined ?
                {
                    original: `${claim.whatsappinsurer.vehicleFront}`,
                    thumbnail: `${claim.whatsappinsurer.vehicleFront}`,
                }
                :
                claim !== undefined && claim.files !== undefined && claim.files.vehicle_front !== undefined ?
                    {
                        original: vehicleFront?.props.src,
                        thumbnail: vehicleFront?.props.src,
                    }
                    :
                    {
                        original: `${qcIcon}`,
                        thumbnail: `${qcIcon}`,
                    },
            claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.driversLicense !== undefined ?
                {
                    original: `${claim.whatsappinsurer.driversLicense}`,
                    thumbnail: `${claim.whatsappinsurer.driversLicense}`,
                }
                :
                claim !== undefined && claim.files !== undefined && claim.files.drivers_license !== undefined ?
                    {
                        original: driversLicense?.props.src,
                        thumbnail: driversLicense?.props.src,
                    }
                    :
                    {
                        original: `${qcIcon}`,
                        thumbnail: `${qcIcon}`,
                    },
            claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.scensor !== undefined ?
                {
                    original: `${claim.whatsappinsurer.scensor}`,
                    thumbnail: `${claim.whatsappinsurer.scensor}`,
                }
                :
                claim !== undefined && claim.files !== undefined && claim.files.scensors !== undefined ?
                    {
                        original: scensors?.props.src,
                        thumbnail: scensors?.props.src,
                    }
                    :
                    {
                        original: `${qcIcon}`,
                        thumbnail: `${qcIcon}`,
                    },
        ];

        if (claim !== undefined && claim.files !== undefined && claim.files.damage_photos !== undefined) {
            images.push({
                original: damagePhotos?.props.src,
                thumbnail: damagePhotos?.props.src,
            })
        } else if (damageImages !== null && damageImages !== undefined) {
            if (damageImages.length === 0) {
                images.push({
                    original: qcIcon,
                    thumbnail: qcIcon
                })
            } else {
                for (const index in damageImages) {
                    images.push({
                        original: damageImages[index],
                        thumbnail: damageImages[index]
                    })
                }
            }
        }

        if (!claim) {
            return false;
        }
        return (
            <div>
                <div className="header-panel">
                    <div>
                        <Button className="close-btn" onClick={(e) => onClose(e)}>
                            <Icon icon={x} size={28} />
                        </Button>
                        <h2>
                            {claim?.whatsappinsurer?.name}
                            <Badge className="status-badge">{claim?.status}</Badge>
                        </h2>
                    </div>
                </div>
                <div>
                    {checkedFour === true && claim !== undefined && claim.whatsapptodos !== undefined && claim.whatsapptodos.bookInDate.completed !== true ? (
                        <div className={`position-fixed-btns edit-buttons ${state.hasChanges ? '' : 'disabled'}`}>
                            <Button
                                color="secondary"
                                size="sm"
                                onClick={saveChanges}
                                disabled={
                                    false
                                }>
                                {state.saving ? 'Saving Date...' : 'Submit Fitment Date'}
                            </Button>
                        </div>
                    ) : null}
                </div>
                <div>
                    {state.isDisabled && state.hasChanges ? (
                        <div className={`position-fixed-btns edit-buttons ${state.hasChanges ? '' : 'disabled'}`}>
                            <Button
                                color="secondary"
                                size="sm"
                                onClick={saveChanges}
                                disabled={
                                    false
                                }>
                                {state.saving ? 'Completing...' : 'Complete'}
                            </Button>
                        </div>
                    ) : null}
                </div>
                <Row className="claim">
                    <Col sm={6} className="todo-list-container">
                        <div className="static">
                            <div className="todo-list">
                                <h3>To Do List</h3>
                                <CardBody>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <input className="checkbox"
                                                    type="checkbox"
                                                    id="checkbox1"
                                                    checked={claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.confirmEstimate !== undefined && claim.whatsapptodos.confirmEstimate.completed === false ? false : true}
                                                />
                                                <label htmlFor="checkbox1">Confirm cost estimate</label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Input
                                                    value={calTotalCost !== null || calTotalCost !== 0 ? calTotalCost : 0.00}
                                                    type='number'
                                                    placeholder=""
                                                    className="form-checkbox"
                                                    required
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <input className="checkbox"
                                                    type="checkbox"
                                                    id="checkbox2"
                                                    checked={claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.claimNumber !== undefined && claim.whatsapptodos.claimNumber.completed === false ? false : true}
                                                />
                                                <label htmlFor="checkbox2">Claim Number</label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Input
                                                    value={claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.claimNumber !== undefined && claim.whatsappinsurer.claimNumber !== null && claim.whatsappinsurer.claimNumber !== '' ? claim.whatsappinsurer.claimNumber : ''}
                                                    type='text'
                                                    placeholder="Enter Claim Number"
                                                    className="form-checkbox"
                                                    required
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                    {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.underwriter !== undefined && claim.whatsappinsurer.underwriter !== 'GEMVAS' && profile.partnerId !== 'EXCLUSIVE' ?
                                        <FormGroup row className="my-0">
                                            <Col xs="6" sm="6" md="6">
                                                <FormGroup>
                                                    <input className="checkbox"
                                                        type="checkbox"
                                                        id="checkbox3"
                                                        checked={claim !== undefined && claim.whatsapptodos !== undefined && claim.whatsapptodos.collectedExcess !== undefined && claim.whatsapptodos.collectedExcess.completed === false ? false : true}
                                                    />
                                                    <label htmlFor="checkbox3">Collect excess from client</label>
                                                    <span style={{ color: 'red' }}> (Excluding VAT)</span>
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6" sm="6" md="6">
                                                <FormGroup>
                                                    <Input
                                                        value={claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.excessValue !== undefined && claim.whatsappinsurer.excessValue !== null && claim.whatsappinsurer.excessValue !== 0 ? claim.whatsappinsurer.excessValue : 0.00}
                                                        type='number'
                                                        placeholder="Enter Excess"
                                                        className="form-checkbox"
                                                        required
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </FormGroup>
                                        :
                                        null
                                    }
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <input className="checkbox" type="checkbox" id="checkbox4"
                                                    onChange={handleChangeFour}
                                                    checked={claim !== undefined && claim.whatsapptodos !== undefined && claim.whatsapptodos.bookInDate !== undefined && checkedFour === false && claim.whatsapptodos.bookInDate.completed === false ? false : true}
                                                />
                                                <label htmlFor="checkbox4">Fitment date</label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <div className="picker">
                                                    <DatePicker
                                                        showTimeSelect={true}
                                                        dateFormat="MM/dd/yyyy h:mmaa"
                                                        selected={startDate}
                                                        selectsStart
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        onChange={(date) => setStartDate(date)}
                                                        showIcon={true}
                                                        value={claim !== undefined && claim !== undefined && claim.whatsappinsurer !== undefined ? fitmentDate : startDate}
                                                        disabled={fitmentDateDisabled}
                                                    />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <input className="checkbox" type="checkbox" id="checkbox5" checked={checkedFive} value={checkedFive} onChange={handleChangeFive} />
                                                <label htmlFor="checkbox5">Upload invoice</label>
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                {!attachedFiles.length ? (
                                                    <FileDropZone
                                                        loading={state.loading}
                                                        onError={toast.error}
                                                        hideText={false}
                                                        onDrop={onDropFile}
                                                        className={dropClass}
                                                    />
                                                )
                                                    :
                                                    (
                                                        <div className="files-attached-div">


                                                            <span className="text">{attachedFiles.length} File Attached</span>

                                                            {claim !== undefined && claim.files !== undefined && claim.files.invoice !== undefined && claim.files.invoice[0] !== undefined ?

                                                                <> {modalOpen && (
                                                                    <DeleteFilesModal
                                                                        onConfirm={confirmCancel}
                                                                        onCancel={rejectCancel}
                                                                        isOpen={modalOpen}
                                                                        keyToDelete={keyToDelete}
                                                                    />
                                                                )}<Modal isOpen={isOpen} className="image-modal" ariaHideApp={true}>
                                                                        <ImageGallery items={images} />
                                                                        <Button className="image-modal-close" onClick={() => toggleModal(!isOpen)}>
                                                                            <Icon icon={x} size={64} />
                                                                        </Button>
                                                                    </Modal><div className="icons-div">
                                                                        {/* <div key={claim !== undefined && claim.files !== undefined && claim.files.invoice[0] !== undefined ? claim.files.invoice[0].fileName : ''} className="files-attached-div"> */}
                                                                        {/* <h6>{claim !== undefined && claim.files !== undefined && claim.files.invoice[0] !== undefined ? claim.files.invoice[0].fileName : ''}</h6> */}
                                                                        <Button
                                                                            className="file-single"
                                                                            color="link"
                                                                            onClick={() => downloadInvoice(claim.files.invoice, 'invoice')}
                                                                            size="sm">
                                                                            {eyeLoading.get(claim !== undefined && claim.files !== undefined && claim.files.invoice[0] !== undefined ? claim.files.invoice[0].fileName : '') ? (
                                                                                <Icon icon={loader} size={16} className="spin" />
                                                                            ) : (
                                                                                <Icon icon={eye} />
                                                                            )}
                                                                        </Button>
                                                                        {/* </div> */}
                                                                        <Button
                                                                            className="file-single trash"
                                                                            color="link"
                                                                            onClick={() => openDeleteFileModal(claim.files.invoice[0].fileName)}
                                                                            size="sm">
                                                                            {showLoaderTrash()}
                                                                        </Button>
                                                                    </div></>
                                                                :
                                                                null}
                                                        </div>
                                                    )}
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                </CardBody>
                                &nbsp;&nbsp;
                                <FormGroup row className="my-0">
                                    <Col xs="4" sm="4" md="4">
                                        <FormGroup>
                                            <button type="button" className="btn-primary" onClick={downloadQuotePDF}>
                                                {!downloadPowLoading ?
                                                    null :
                                                    <Icon icon={download} size={20} />}
                                                {!downloadPowLoading ?
                                                    ' Downloading...' :
                                                    ' Quote'
                                                }
                                                {!downloadPowLoading && <Icon icon={loader} size={16} className="spin" />}
                                            </button>
                                        </FormGroup>
                                    </Col>
                                    {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.underwriter !== undefined && claim.whatsappinsurer.underwriter !== 'Alpha Direct Insurance Co' && claim.whatsappinsurer.underwriter !== 'Self Insured' ?
                                        <Col xs="4" sm="4" md="4">
                                            <FormGroup>
                                                <button type="button" className="btn-info" onClick={downloadClaimForm}>
                                                    {!downloadInfoLoading ?
                                                        null :
                                                        <Icon icon={download} size={20} />}
                                                    {!downloadInfoLoading ? 'Downloading...' : ' Claim Form'}
                                                    {!downloadInfoLoading && <Icon icon={loader} size={16} className="spin" />}
                                                </button>
                                            </FormGroup>
                                        </Col>
                                        : <Col xs="4" sm="4" md="4"></Col>}
                                    {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.bookInDate !== undefined && fitmentDate !== null ? 
                                    null
                                     : <Col xs="4" sm="4" md="4">
                                     <FormGroup>
                                         <button type="button" className="btn-danger" onClick={showModal}>
                                             {!cancelClaimLoading ?
                                                 null :
                                                 <Icon icon={trash} size={20} />}
                                             {!cancelClaimLoading ? 'Canceling...' : ' Cancel Claim'}
                                             {!cancelClaimLoading && <Icon icon={loader} size={16} className="spin" />}
                                         </button>
                                     </FormGroup>
                                 </Col>}
                                </FormGroup>
                            </div>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={insuredChatOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setInsuredChatOpen(!insuredChatOpen);
                                    setInsuredEditOpen(!insuredEditOpen);
                                }}>
                                <div>
                                    Insured Info<Icon icon={insuredChatOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={insuredChatOpen}>
                                <CardBody>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Registration</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.registrationNumber !== undefined ? claim?.whatsappinsurer.registrationNumber : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="name" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer Name</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.name !== undefined ? claim?.whatsappinsurer.name : ''}
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="customerEmail" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer Email Address</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.customerEmail !== undefined ? claim?.whatsappinsurer.customerEmail : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="mobileNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer preferred Contact No.</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.mobileNumber !== undefined ? claim?.whatsappinsurer.mobileNumber : ''}
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="isLandlineNumberExist" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Does the Insured/Customer have a Landline No?</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isLandlineNumberExist !== undefined ? claim?.whatsappinsurer.isLandlineNumberExist : ''}
                                            </FormGroup>
                                        </Col>
                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isLandlineNumberExist !== undefined ? (
                                            <>
                                                {claim.whatsappinsurer.isLandlineNumberExist === 'Yes' ?
                                                    (<Col xs="6" sm="6" md="6">
                                                        <FormGroup>
                                                            <Label htmlFor="landlineNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Insured/Customer Landline No.</Label>
                                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.landlineNumber !== undefined ? claim?.whatsappinsurer.landlineNumber : ''}
                                                        </FormGroup>
                                                    </Col>)
                                                    : null}
                                            </>
                                        )

                                            : ('')
                                        }
                                    </FormGroup>
                                </CardBody>
                            </Collapse>
                        </div>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={incidentEditOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setIncidentEditOpen(!incidentEditOpen);
                                    setIncidentChatOpen(!incidentChatOpen);
                                }}>
                                <div>
                                    Incident Details<Icon icon={incidentEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={incidentEditOpen}>
                                <CardBody>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Date of Breakage (DD/MM/YYYY)</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.incidentDate !== undefined ? breakageDate : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <FormGroup>
                                                <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Cause of Breakage</Label>
                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.causeOfBreakage !== undefined ? claim?.whatsappinsurer.causeOfBreakage : ''}
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>

                                    <FormGroup row className="my-0">
                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isDriversNameSameInsured !== undefined ? (
                                            <>
                                                {claim.whatsappinsurer.isDriversNameSameInsured !== 'Yes' ?
                                                    <><Col xs="6" sm="6" md="6">
                                                        <FormGroup>
                                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Is the driver’s name same as the insured?</Label>
                                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isDriversNameSameInsured !== undefined ? claim?.whatsappinsurer.isDriversNameSameInsured : ''}
                                                        </FormGroup>
                                                    </Col><Col xs="6" sm="6" md="6">
                                                            <FormGroup>
                                                                <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Driver Name</Label>
                                                                {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.driversName !== undefined ? claim?.whatsappinsurer.driversName : ''}
                                                            </FormGroup>
                                                        </Col></>
                                                    : <Col xs="12" sm="12" md="12">
                                                        <FormGroup>
                                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Is the driver’s name same as the insured?</Label>
                                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isDriversNameSameInsured !== undefined ? claim?.whatsappinsurer.isDriversNameSameInsured : ''}
                                                        </FormGroup>
                                                    </Col>}
                                            </>
                                        )
                                            : (
                                                ''
                                            )}
                                    </FormGroup>

                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Was there a witness to the incident?</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isWitnessToIncident !== undefined ? claim?.whatsappinsurer.isWitnessToIncident : ''}
                                        </Col>
                                    </FormGroup>

                                    {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.isWitnessToIncident !== undefined ? (
                                        <>
                                            {claim.whatsappinsurer.isWitnessToIncident === 'Yes' ?

                                                <><FormGroup row className="my-0">
                                                    <Col xs="6" sm="6" md="6">
                                                        <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Witness Name</Label>
                                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.witnessName !== undefined ? claim?.whatsappinsurer.witnessName : ''}
                                                    </Col>
                                                    <Col xs="6" sm="6" md="6">
                                                        <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Witness Mobile No.</Label>
                                                        {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.witnessMobileNumber !== undefined ? claim?.whatsappinsurer.witnessMobileNumber : ''}
                                                    </Col>
                                                </FormGroup></>
                                                : null}
                                        </>
                                    )
                                        : (
                                            ''
                                        )}
                                </CardBody>
                                <CardHeader style={{ textAlign: 'center', fontSize: '0.8em', color: 'black', fontWeight: 'bold' }}>
                                    Details of Broken Glass (<span style={{ color: 'blue' }}>Optional</span>)
                                </CardHeader>
                                <CardBody>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Full decription of broken Glass</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.glassDecription !== undefined ? claim?.whatsappinsurer.glassDecription : ''}
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Size and thickness in millimetres</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.glassSizeThickness !== undefined ? claim?.whatsappinsurer.glassSizeThickness : ''}
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Is the Glass Cracked or shattered?</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.glassCrackeShattered !== undefined ? claim?.whatsappinsurer.glassCrackeShattered : ''}
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Any signwriting on broken glass?</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.glassSignwriting !== undefined ? claim?.whatsappinsurer.glassSignwriting : ''}
                                        </Col>
                                    </FormGroup>
                                </CardBody>
                            </Collapse>
                        </div>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={imagesEditOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setImagesEditOpen(!imagesEditOpen);
                                    setImagesChatOpen(!imagesChatOpen);
                                }}>
                                <div>
                                    Images<Icon icon={imagesEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={imagesEditOpen}>
                                <div className="scrollbar-container sidebar-info-scrollbar ps">
                                    <div className="claim-group">
                                        <Table className="sidepanel-info-table table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <div className="image-gallery-wrapper">
                                                        <ImageGallery items={images} />
                                                    </div>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={damageEditOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setDamageEditOpen(!damageEditOpen);
                                    setDamageChatOpen(!damageChatOpen);
                                }}>
                                <div>
                                    Damage Windscreen/Glass Details <Icon icon={damageEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={damageEditOpen}>
                                {/* <div className="scrollbar-container sidebar-info-scrollbar ps">
                                    <div className="claim-group">
                                        <Table className="sidepanel-info-table table table-borderless">
                                            <tbody>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Make</td>
                                                    <td>{claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.vehicleMake !== undefined ? claim?.whatsappinsurer.vehicleMake : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Model</td>
                                                    <td>{claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.vehicleModel !== undefined ? claim?.whatsappinsurer.vehicleModel : ''}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div> */}

                                <CardBody>
                                    <FormGroup row className="my-0">
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Make</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.vehicleMake !== undefined ? claim?.whatsappinsurer.vehicleMake : ''}
                                        </Col>
                                        <Col xs="6" sm="6" md="6">
                                            <Label htmlFor="registrationNumber" style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Vehicle Model</Label>
                                            {claim !== undefined && claim.whatsappinsurer !== undefined && claim.whatsappinsurer.vehicleModel !== undefined ? claim?.whatsappinsurer.vehicleModel : ''}
                                        </Col>
                                    </FormGroup>
                                </CardBody>
                                <br />

                                <div className="scrollbar-container sidebar-info-scrollbar ps">
                                    <div className="claim-group">
                                        <Table className="sidepanel-info-table table table-borderless">
                                            <tbody>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '250px' }}>Part Number</th>
                                                        <th style={{ width: '250px' }}>Description</th>
                                                        <th style={{ width: '250px' }}>Quantity</th>
                                                        <th style={{ width: '300px' }}>Cost <span style={{ color: 'red' }}> (Excluding VAT)</span></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {damageGlassDetails.map((data, index) => {
                                                        const { partNumber, partDescription, partQuantity, cost } = data;
                                                        return (<tr key={index}>
                                                            <td style={{ width: '250px' }}>
                                                                {
                                                                    partNumber
                                                                }
                                                            </td>
                                                            <td style={{ width: '250px' }}>
                                                                {
                                                                    partDescription === '' ? 'Shattered' : partDescription
                                                                }
                                                            </td>
                                                            <td style={{ width: '250px' }}>
                                                                {
                                                                    partQuantity
                                                                }
                                                            </td>
                                                            <td style={{ width: '300px' }}>
                                                                {
                                                                    cost
                                                                }
                                                            </td>
                                                        </tr>)
                                                    })}
                                                    <tr>
                                                        <td></td>
                                                        <td ></td>
                                                        <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>Total Claim Cost</td>
                                                        <td style={{ fontSize: '0.80em', fontWeight: 'bold' }}>
                                                            {
                                                                calTotalCost
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                        <div>
                            <Button
                                className="accordion-btn"
                                outline={noteEditOpen ? false : true}
                                size="sm"
                                onClick={() => {
                                    setNoteEditOpen(!noteEditOpen);
                                    setNoteChatOpen(!noteChatOpen);
                                }}>
                                <div>
                                    Claim Notes <Icon icon={noteEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                                </div>
                            </Button>
                            <Collapse delay={collapseConfig} isOpen={noteEditOpen}>
                                <div className="static">
                                    <NotesComponent claim={claim} />
                                </div>
                            </Collapse>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    if (!claim) {
        return <div></div>;
    }

    return (
        <div>
            <CancelModal
                claim={claim}
                onConfirm={confirmCancel1}
                onCancel={rejectCancel1}
                isOpen={modalOpen1}
                reason={reason}
                onChangeReason={onChangeReason}
            />
            <div className={`side-panel-overlay ${claim ? 'open' : ''}`}></div>
            <div id="incoming-side-panel" className={`side-panel ${claim ? 'open' : ''}`}>
                {claim ? renderClaim(claim) : false}
            </div>
        </div>
    );
};

export default connect(null, { updateWhatsappClaimValue, whatsappUploadFile, whatsappDownloadFiles, whatsappRemoveFiles, updateWhatsappCompletedClaim, deleteClaim })(WhatsappQuoteAuthorizedSidePanel);