/* eslint-disable prettier/prettier */
import { Badge, Button } from 'reactstrap';
import { QueueRowProps } from '../../whatsapp-typing';
import { connect } from 'react-redux';
import { deleteClaim1, reinstateClaim } from '../../../../actions/update-claim';
import { loader } from 'react-icons-kit/feather';
import {trash} from 'react-icons-kit/iconic/trash'
import {undo} from 'react-icons-kit/icomoon/undo'
import { useFirestoreConnect } from 'react-redux-firebase';
import Icon from 'react-icons-kit';
import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';

const WhatsappSentRow: React.FC<QueueRowProps> = ({
  claim,
  onClick,
  deleteClaimIcon,
  deactivateClaim,
  reInstateClaimIcon,
  reinstateCancelClaim,
}) => {
  const [reinstateCancelClaimLoading, setReinstateCancelClaimLoading] = useState(true);
  const [cancelClaimLoading, setCancelClaimLoading] = useState(true);
  const updateDate = !_.isDate(claim._audit.updated.timestamp)
    ? moment(claim._audit.updated.timestamp.toDate()).format('DD MMM, YYYY')
    : '-';
  const createDateMoment = !_.isDate(claim._audit.created.timestamp)
    ? moment(claim._audit.created.timestamp.toDate())
    : moment();
  const createDate = createDateMoment.format('DD MMM, YYYY');
  const todayMoment = moment();
  const daysOpen = Math.max(todayMoment.diff(createDateMoment, 'days'), 1);

  let query: any = [];

  if (claim.whatsappinsurer.name) {
    query = [
      {
        collection: 'whatsapp',
        doc: claim.whatsappinsurer.name,
      },
    ];
  }
  deactivateClaim = async () => {
    try {
      setCancelClaimLoading(false);
      const response = await deleteClaim1(claim.messageClaimId || '', '', Error);
      console.log(response);
    } catch (e) {      
      setCancelClaimLoading(true);   
      console.error(e);
    }
  };

  reinstateCancelClaim= async () => {
    try {
      setReinstateCancelClaimLoading(false);
      const response = await reinstateClaim(claim.messageClaimId || '');
      console.log(response);
    } catch (e) {   
      setReinstateCancelClaimLoading(true);   
      console.error(e);
    }
  };

  useFirestoreConnect(query);

  if (!claim) {
    return null;
  }

  return (
    <tr>
      <td>
        <Button className="table-inline-button" onClick={(e) => onClick(e, claim)}>
          {claim.whatsappinsurer.name}
        </Button>
      </td>
      <td className="text-center">{claim.whatsappinsurer.mobileNumber}</td>
      <td className="text-center">{claim.whatsappinsurer.registrationNumber}</td>
      <td className="text-center">{claim.whatsappinsurer.broker}</td>
      <td className="text-center">{claim.whatsappinsurer.underwriter}</td> 
      <td className="text-center">
        <Badge color="secondary" size="lg">
          {daysOpen}
        </Badge>
      </td>
      <td className="text-center">{createDate}</td>
      <td className="text-center">{updateDate}</td>
      {deleteClaimIcon && (
        <td className="text-center">
          <div>
            {/* <Icon 
            onClick={deactivateClaim?.bind(this, claim.messageClaimId || '')}
            icon={trash} 
            size={20}>
            {!cancelClaimLoading ?
                null :
                <Icon icon={trash} size={10} />}
              {!cancelClaimLoading ? 'Cancelling...' : ' Cancel'}
              {!cancelClaimLoading && <Icon icon={loader} size={12} className="spin" />}
            
              </Icon> */}
            <button type="button" className="btn-danger" onClick={deactivateClaim?.bind(this, claim.messageClaimId || '')}>
              {!cancelClaimLoading ?
                null :
                <Icon icon={trash} size={10} />}
              {!cancelClaimLoading ? 'Cancelling...' : ' Cancel'}
              {!cancelClaimLoading && <Icon icon={loader} size={12} className="spin" />}
            </button>
          </div>
        </td>
      )}
      {reInstateClaimIcon && (
        <td className="text-center">
          <div>
            {/* {<Icon 
            onClick={reinstateCancelClaim?.bind(this, claim.messageClaimId || '')}
            icon={undo} 
            size={20}/>} */}
            <button type="button" className="btn-danger" onClick={reinstateCancelClaim?.bind(this, claim.messageClaimId || '')}>
              {!reinstateCancelClaimLoading ?
                null :
                <Icon icon={undo} size={10} />}
              {!reinstateCancelClaimLoading ? 'Reinstating...' : ' Reinstate'}
              {!reinstateCancelClaimLoading && <Icon icon={loader} size={12} className="spin" />}
            </button>
          </div>
        </td>
      )}
    </tr>
  );
};

export default connect(null)(WhatsappSentRow);
