/* eslint-disable prettier/prettier */
import React from 'react';
import _ from 'lodash';

const WhatsappHeaderComponent: React.FC<any> = ({
  showDownloadIcon, deleteClaimIcon, queueName
}) => {
  return (
    <thead>
      <tr>
        <th>Insured Name</th>
        <th className="text-center">Insured Mobile No</th>
        <th className="text-center">Registration No</th>
        <th className="text-center">Broker</th>
        <th className="text-center">Underwriter</th>
        <th className="text-center">Days Open</th>
        <th className="text-center">Created</th>
        <th className="text-center">Updated</th>
        <th className="text-center">Download Quote</th>
        <th className="text-center">Download Claim Form</th>
        {queueName === 'COMPLETED'? null : <th className="text-center">Cancel Claim</th>}
        {showDownloadIcon && <th></th>}
        {deleteClaimIcon && <th></th>}        
        <th></th>
      </tr>
    </thead>
  );
};

export default WhatsappHeaderComponent;
