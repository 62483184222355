/* eslint-disable prettier/prettier */
import {
  FirebaseReducer,
  FirestoreReducer,
  ReduxFirestoreQueries,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import React, { useState } from 'react';
import _ from 'lodash';

import { Button, Card, CardBody, Collapse, Table } from 'reactstrap';
import { compose } from 'redux';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import Helmet from 'react-helmet';
import LoadingPage from '../../loading/LoadingPage';

const PartnersDetails = ({ partnersData, loading }: any) => {
  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Quick Claims | Info </title>
      </Helmet>
      <Table striped bordered hover size="20">
        <thead>
          <tr>
            <th className="text-left" style={{ width: '25%' }}>
              Partner Name
            </th>
            <th className="text-left">Country</th>

            <th className="text-left">Key Contact</th>
            <th className="text-left" style={{ width: '25%' }}>
              Phone Number
            </th>
            <th className="text-left" style={{ width: '25%' }}>
              Alt Phone Number
            </th>
            <th className="text-left">Website</th>
            <th className="text-left">Vat.</th>
            <th className="text-left">Contact Email Address</th>
            <th className="text-left">Language</th>
            <th className="text-left">Currency</th>
            <th className="text-left">Address</th>
          </tr>
        </thead>
        <tbody>
          {_.map(partnersData, (partner) => (
            <tr>
              <td className="text-left">{partner.name}</td>
              <td className="text-left">{partner.country}</td>
              <td className="text-left" style={{ width: '25%' }}>
                {partner.keycontact}
              </td>
              <td className="text-left" style={{ width: '20%' }}>
                {partner.telephone}
              </td>
              <td className="text-left" style={{ width: '20%' }}>
                {partner.telephone_alt}
              </td>
              <td className="text-left">{partner.website}</td>
              <td className="text-left">{partner.vat}</td>
              <td className="text-left">{partner.keyContactEmail}</td>
              <td className="text-left">{partner.language}</td>
              <td className="text-left">{partner.currency}</td>
              <td className="text-left">{partner.address}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
const mapQueryToProps = (): ReduxFirestoreQueries => {
  return [
    {
      collection: 'partners',
      where: ['partnerInformationVisible', '==', true],
      storeAs: 'partners',
    },
  ];
};

const mapStateToProps = (state: any) => {
  const {
    firestore,
    firebase: { auth },
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
  } = state;
  const users = firestore.data.users;
  const partnersData = firestore.data.partners;

  const loading = !isLoaded(partnersData);
  return {
    loading,
    users,
    auth: auth as FirebaseReducer.AuthState,
    partnersData,
  };
};

export default compose(
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps),
)(PartnersDetails);
