/* eslint-disable prettier/prettier */
import * as _ from 'lodash';
import { Button, Input } from 'reactstrap';
import { IDatabase, NotesComponentProps } from '../whatsapp-typing';
import { IWhatsappClaimNote } from '../../../../../typings';
import { addWhatsappNote } from '../../../actions/update-claim';
import { arrowRightCircle, inbox, loader } from 'react-icons-kit/feather';
import { connect, useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { toast } from 'react-toastify';
import Icon from 'react-icons-kit';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { ReactElement, useState } from 'react';
import moment from 'moment';

let scrollRef: HTMLElement;

const initialState: { note: string; loading: boolean } = {
  note: '',
  loading: false,
};

const NotesComponent: React.FC<NotesComponentProps> = ({ claim, addWhatsappNote }) => {
  const [state, setState] = useState(initialState);
  let query: any = [];
  if (claim) {
    query = [
      {
        collection: 'whatsappMessages',
        doc: claim.id,
        subcollections: [
          {
            collection: 'notes',
            storeAs: 'notes',
            orderBy: ['_audit.created.timestamp', 'asc'],
          },
        ],
        storeAs: 'notes',
      },
    ];
  }

  useFirestoreConnect(query);

  const notes = useSelector(({ firestore: { data } }: any) => {
    return data && data.notes ? data.notes : null;
  });

  const notesLoaded = useSelector(({ firestore: { data } }: any) => {
    return isLoaded(data.notes);
  });

  let userList: Array<string> = [];

  if (notes) {
    query = [
      {
        collection: 'users',
        storeAs: 'users',
      },
    ];
  }

  useFirestoreConnect(query);

  const users = useSelector(({ firestore: { data } }: any) => {
    return data && data.users ? data.users : null;
  });
  const usersLoaded = useSelector(({ firestore: { data } }: any) => {
    return isLoaded(data.users);
  });

  if (notes && usersLoaded) {
    const noteObj = Object.values(notes) as Array<IWhatsappClaimNote>;
    const userIds = noteObj.map((note: IWhatsappClaimNote) => {
      if (note && note.watchlist) return note?.watchlist[0];
      else return '';
    });
    userList = userIds.map((userId) => {
      return users[userId]?.email.split('@')[0];
    });
    userList = userList.filter((user) => {
      return user !== undefined;
    });
  }

  const submitNote = () => {
    if (state.note.length > 0) {
      setState({ ...state, loading: true });
      addWhatsappNote(claim?.id, state.note, (err: any) => {
        setState({ ...state, loading: false });
        if (err) {
          console.error(err);
          toast.error('Could not create note on claim, please try again later');
          return;
        }
        setTimeout(() => {
          scrollRef.scrollTop = scrollRef.scrollHeight * 2;
        }, 100);
        onChangeInput('');
      });
    }
  };

  const renderNote = (note: IWhatsappClaimNote & IDatabase, index: string): ReactElement => {
    const rawDate =
      note._audit.created.timestamp && !_.isDate(note._audit.created.timestamp)
        ? note._audit.created.timestamp.toDate()
        : null;
    return (
      <div className="note" key={index}>
        <p className="note-body">
          {' '}
          <p className="note-title">{userList[Number(index)]}</p> {note.note}
        </p>
        <p className="note-data">
          {rawDate ? moment(rawDate).format('DD MMM, YYYY') : 'null'}
          <br />
          {rawDate ? moment(rawDate).format('HH:MM A') : 'null'}
        </p>
      </div>
    );
  };

  const renderLoadingNotes = (): ReactElement => {
    return (
      <div className="loading-notes">
        <Icon icon={loader} className="spin" size={32} />
        Loading notes...
      </div>
    );
  };

  const renderEmptyNotes = (): ReactElement => {
    return (
      <div className="empty-notes">
        <Icon icon={inbox} size={32} />
        This claim has no active notes
      </div>
    );
  };

  const renderNotes = (): any => {
    if (userList.length > 0) {
      const notesObj = Object.values(notes) as Array<IWhatsappClaimNote & IDatabase>;
      return notesObj.map((note: IWhatsappClaimNote & IDatabase, index: number) => {
        return renderNote(note, String(index));
      });
    } else {
      return renderLoadingNotes();
    }
  };

  const onChangeInput = (value: any): void => {
    setState({
      ...state,
      note: value,
    });
  };

  const onNoteKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.nativeEvent.keyCode === 13 && !state.loading && state.note.trim() !== '') {
      e.preventDefault();
      submitNote();
    }
  };

  return (
    <div className="notes">
      <PerfectScrollbar
        className="past-notes"
        containerRef={(ref: HTMLElement) => (scrollRef = ref)}>
        {notesLoaded ? (notes ? renderNotes() : renderEmptyNotes()) : renderLoadingNotes()}
      </PerfectScrollbar>
      <div className="note-input">
        <Input
          type="textarea"
          value={state.note}
          onKeyDown={(e) => onNoteKeyDown(e)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e.target.value)}
        />
        <Button
          className="submit-note"
          onClick={submitNote}
          disabled={state.loading || state.note === ''}>
          {state.loading ? (
            <Icon icon={loader} size={20} className="spin" />
          ) : (
            <Icon icon={arrowRightCircle} size={20} />
          )}
        </Button>
      </div>
    </div>
  );
};

export default connect(null, { addWhatsappNote })(NotesComponent);
