/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import '../../../styles/IncomingClaimPage.scss';
import { Button, Input } from 'reactstrap/lib';
import { DropdownOption } from '../new/typings';
import {
  FirebaseReducer,
  FirestoreReducer,
  ReduxFirestoreQueries,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { RouteComponentProps } from 'react-router';
import { Table } from 'reactstrap';
import { WhatsappIncomingPageState, WhatsappOutgoingPageProps, WhatsappOutgoingPageState } from '../whatsapp-typing';
import { authorizedOptions, statusOptions } from '../../../helpers/utils';
import { compose } from 'redux';
import { connect } from 'react-redux';
import EmptyState from '../../claims/empty-state/EmptyState';
import Helmet from 'react-helmet';
import LoadingPage from '../../loading/LoadingPage';
import React, { Component } from 'react';
import WhatsappOutgoingSidePanel from '../side-panel/WhatsappOutgoingSidePanel';
import WhatsappSentHeaderComponent from '../shared/table/WhatsappSentHeaderComponent';
import WhatsappSentRow from '../shared/table/WhatsappSentRow';
import _ from 'lodash';
class WhatsappOutgoingClaimsPage extends Component<
  RouteComponentProps & FirebaseReducer.Reducer & WhatsappOutgoingPageProps,
  WhatsappOutgoingPageState
> {
  private static INITIAL_STATE: WhatsappIncomingPageState = {
    activeClaim: null,
  };

  private static propKey(propertyName: string, value: any): object {
    return { [propertyName]: value };
  }

  private setStateWithEvent(event: any, columnType: string): void {
    this.setState(WhatsappOutgoingClaimsPage.propKey(columnType, (event.target as any).value));
  }

  constructor(props: any) {
    super(props);
    this.state = {
      ...WhatsappOutgoingClaimsPage.INITIAL_STATE,
      statusFilter: { value: 'STATUS', label: 'ALL' },
      authorizedFilter: { value: 'Both', label: 'Both' },
      searchStringVal: '',
    };
  }

  public selectClaim(id: string | null) {
    this.setState(WhatsappOutgoingClaimsPage.propKey('activeClaim', id));
  }

  options = statusOptions.map((status: string) => {
    if (status === 'STATUS') {
      return {
        value: status,
        label: 'ALL',
      };
    }
    return {
      value: status,
      label: status,
    };
  });

  authorizedOpts = authorizedOptions.map((status: string) => {
    return {
      value: status,
      label: status,
    };
  });

  setStatusFilter = (value: DropdownOption) => {
    this.setState({ statusFilter: value });
  };

  setAuthorizedFilter = (value: DropdownOption) => {
    this.setState({ authorizedFilter: value });
  };

  onSearchBtnClick = () => {
    typeof this.props.searchChange === 'function' &&
      this.props.searchChange(this.state.searchStringVal || '');
  };

  onResetSearchBtnClick = () => {
    this.setState({ searchStringVal: '' });
    typeof this.props.searchChange === 'function' && this.props.searchChange('');
  };

  render() {
    const { claims, loading, partner, whatsapptodos, userProfile } = this.props;
    const { activeClaim } = this.state;
    const claim = claims && activeClaim ? { ...claims[activeClaim], id: activeClaim } : null;
    if (loading) {
      return <LoadingPage />;
    }
    if (_.isEmpty(claims)) {
      return <EmptyState message="There are currently no open sent requests" />;
    }

    // if (this.props.profile.partnerId === 'Autoboys') {
    //   return <EmptyState message="You are not authorized to view this page" />;
    // }

    let claimsList = _.pickBy(claims, (c) => {
      if (this.state.authorizedFilter.value === 'Authorized') {
        return c.whatsappinsurer.authorized === false;
      } else if (this.state.authorizedFilter.value === 'Unauthorized') {
        return c.whatsappinsurer.authorized === false;
      }
      return c;
    });

    claimsList = _.pickBy(claimsList, (c) => {
      if (this.state.statusFilter.value !== 'STATUS') {
        return c.status === this.state.statusFilter.value;
      }
      return c;
    });

    return (
      <div className={`incoming-claim-page-container ${activeClaim ? 'open' : 'closed'}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Quick Claims | Sent Jobs</title>
        </Helmet>
        <div className="search-div">
          <Input
            name="search"
            value={this.state.searchStringVal}
            placeholder="Search Claim"
            className="search-input"
            onChange={(event) => this.setState({ searchStringVal: event.target.value })}
          />
          <Button onClick={this.onSearchBtnClick} className="search-btn">
            Search
          </Button>
          <Button
            outline
            color="secondary"
            onClick={this.onResetSearchBtnClick}
            className="search-btn">
            Reset
          </Button>
        </div>
        <Table>
          <WhatsappSentHeaderComponent
            statusFilter={this.state.statusFilter}
            setStatusFilter={this.setStatusFilter}
            options={this.options}
            queue="Destination"
            authorizedOptions={this.authorizedOpts}
            authorizedFilter={this.state.authorizedFilter}
            setAuthorizedFilter={this.setAuthorizedFilter}
            queueName="SENT"
            deleteClaimIcon={true}
          />
          <tbody>
            {_.map(claimsList, (c, id: string) => (
              <WhatsappSentRow
                key={`outgoing-row-${id}`}
                claim={c}
                onClick={() => this.selectClaim(id)}
                deleteClaimIcon={true}
              />
            ))}
          </tbody>
        </Table>
        <WhatsappOutgoingSidePanel
          claim={claim}
          whatsapptodos={whatsapptodos}
          partner={partner}
          userProfile={userProfile}
          onClose={() => this.selectClaim(null)} />
      </div>
    );
  }
}

const preMapStateToProps = (state: any) => {
  const {
    firebase: { profile },
    searchString,
  }: {
    firebase: FirebaseReducer.Reducer;
    searchString: { searchString: string };
  } = state;
  return {
    profile: profile,
    searchString,
  };
};

const mapQueryToProps = ({
  profile,
  searchString,
}: {
  searchString: { searchString: string },
  profile: any
}): ReduxFirestoreQueries => {
  if (!profile.isLoaded || profile.isEmpty) {
    return [];
  }
  if (searchString.searchString !== '') {
    return [
      {
        collection: 'whatsappMessages',
        where: ['whatsappinsurer.name', '==', searchString.searchString],
        orderBy: ['_audit.created.timestamp', 'desc'],
      },
      {
        collection: 'whatsappMessages',
        where: ['whatsappinsurer.mobileNumber', '==', searchString.searchString],
        orderBy: ['_audit.created.timestamp', 'desc'],
        storeAs: 'claimsMobileNumber',
      },
      {
        collection: 'whatsappMessages',
        where: ['whatsappinsurer.registrationNumber', '==', searchString.searchString],
        orderBy: ['_audit.created.timestamp', 'desc'],
        storeAs: 'claimsReg',
      },
      {
        collection: 'config',
        doc: 'whatsapptodos',
        storeAs: 'whatsappTodosConfig',
      },
      {
        collection: 'partners',
        doc: profile.partnerId,
        storeAs: 'partner',
      },
    ];
  } else if (profile.userType === 'Admin' || profile.userType === 'Autoboys') {
    return [
      {
        collection: 'whatsappMessages',
        where: [
          // ['assign.origin', '==', profile.partnerId],
          ['status', '==', 'SENT'],
          ['active', '==', true],
        ],
        orderBy: ['_audit.created.timestamp', 'desc'],
      },
      {
        collection: 'config',
        doc: 'whatsapp',
        storeAs: 'whatsappConfig',
      },
      {
        collection: 'config',
        doc: 'whatsapptodos',
        storeAs: 'whatsappTodosConfig',
      },
      {
        collection: 'partners',
        doc: profile.partnerId,
        storeAs: 'partner',
      },
    ];
  } else {
    return [
      {
        collection: 'whatsappMessages',
        where: [
          ['assign.origin', '==', profile.partnerId],
          ['status', '==', 'SENT'],
          ['active', '==', true],
        ],
        orderBy: ['_audit.created.timestamp', 'desc'],
      },
      {
        collection: 'config',
        doc: 'whatsapp',
        storeAs: 'whatsappConfig',
      },
      {
        collection: 'config',
        doc: 'whatsapptodos',
        storeAs: 'whatsappTodosConfig',
      },
      {
        collection: 'partners',
        doc: profile.partnerId,
        storeAs: 'partner',
      },
    ];
  }
};

const mapDispatchToProps = (dispatch: any) => ({
  searchChange: (searchVal: string) => {
    dispatch({
      type: 'SEARCH_CHANGE',
      value: searchVal,
    });
  },
});

const mapStateToProps = (state: any) => {
  const {
    firestore,
    firebase: { auth },
    searchString,
    firebase: { profile },
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
    pagination: { skip: number; take: number };
    searchString: { searchString: string };
  } = state;
  const userProfile = profile;
  const partner = firestore.data.partner;
  const partners = firestore.data.partners;
  let claims = firestore.data.whatsappMessages;
  if (searchString.searchString !== '') {
    if (firestore.data.whatsappMessages) {
      claims = firestore.data.whatsappMessages;
    } else if (firestore.data.claimsMobileNumber) {
      claims = firestore.data.claimsMobileNumber;
    } else if (firestore.data.claimsReg) {
      claims = firestore.data.claimsReg;
    }
  }
  const whatsappTodosConfig = firestore.data.whatsappTodosConfig;
  const loading = !isLoaded(claims) || !isLoaded(whatsappTodosConfig) || !isLoaded(partner);
  const whatsapptodos = null;
  // if (!loading) {
  //   const masterTodo = todosConfig.whatsapptodos;
  //   const partnerConfig = partner.claimTemplate.whatsapptodos;
  //   whatsapptodos = _.assign({}, masterTodo, partnerConfig);
  // }
  return {
    loading,
    claims,
    whatsapptodos,
    partner,
    partners,
    userProfile,
    auth: auth as FirebaseReducer.AuthState,
  };
};

export default compose(
  connect(preMapStateToProps),
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, mapDispatchToProps),
)(WhatsappOutgoingClaimsPage);
