/* eslint-disable prettier/prettier */
import '../../../styles/ManualClaim.scss';
// import 'react-phone-number-input/style.css'
import 'react-phone-input-2/lib/style.css';
import { Badge, Button, Card, CardBody, Col, Collapse, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { FileDropZone } from '../side-panel/inputs/FileDropzone';
import { INewClaimPayload, IWhatsappClaimPayload } from '../../../../../typings';
import { IWhatsappsClaim, ManualClaimPopupProps } from '../whatsapp-typing';
import { checkSquare, chevronDown, chevronUp, trash2, x } from 'react-icons-kit/feather';
import { connect } from 'react-redux';
import {
    initializeNewClaimValue,
    resetClaim,
    submitNewClaim,
    updateNewClaimValue,
} from '../../../actions/new-claim';
import { removeFiles } from '../../../actions/files';
import { toast } from 'react-toastify';
// import { uploadFile } from '../../../actions/update-claim';
import { whatsappUploadFile } from '../../../actions/update-claim';
import DatePicker from "react-datepicker"
import Helmet from 'react-helmet';
import Icon from 'react-icons-kit';
import NotesComponent from '../shared/WhatsappNotes';
// import PhoneInput from 'react-phone-number-input'
import ConfirmModal from '../../modals/ConfirmManualClaimModal';
import PhoneInput from 'react-phone-input-2';
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form"
import PhoneNumber from '../whatsapp/inputs/PhoneNumber';
import React, { ReactElement, useState } from 'react';
import TableRows from '../whatsapp/inputs/TableRows';
import WhatsappClaimInfoEdit from '../shared/WhatsappClaimInfoEdit';
import _ from 'lodash';
import history from '../../../helpers/history';
import validator from "validator";

const initialState: { loading: boolean; saving: boolean; hasChanges: boolean; isDisabled: boolean } = {
    loading: false,
    saving: false,
    hasChanges: false,
    isDisabled: true
};
// const vin_disc = [];
// const vehicle_front = [];
// const drivers_license: React.SetStateAction<string[]> = [];
// const scensors: React.SetStateAction<string[]> = [];
// const damage_photos: React.SetStateAction<string[]> = [];

// let climId = null;

const ManualClaim: React.FC<ManualClaimPopupProps> = ({
    id,
    newClaim,
    userProfile,
    updateNewClaimValue,
    initializeNewClaimValue,
    submitNewClaim,
    resetClaim,
    partner,
    onClose,
    whatsappUploadFile,
    downloadClaimInfo,
    whatsappDownloadFiles,
    whatsappRemoveFiles
}) => {
    const [state, setState] = useState(initialState);

    const [insuredEditOpen, setInsuredEditOpen] = useState(false);
    const [incidentEditOpen, setIncidentEditOpen] = useState(false);
    const [imagesEditOpen, setImagesEditOpen] = useState(false);
    const [noteEditOpen, setNoteEditOpen] = useState(false);
    const [insuredChatOpen, setInsuredChatOpen] = useState(true);
    const [incidentChatOpen, setIncidentChatOpen] = useState(true);
    const [imagesChatOpen, setImagesChatOpen] = useState(true);
    const [noteChatOpen, setNoteChatOpen] = useState(true);
    const [damageEditOpen, setDamageEditOpen] = useState(false);
    const [damageChatOpen, setDamageChatOpen] = useState(true);


    const [breakageCause, setBreakageCause] = useState('Stone Damage');
    const [isDriverNameSame, SetisDriverNameSame] = useState('Yes');
    const [isWitness, SetisWitness] = useState('No');
    const [isLandlineNo, SetisLandlineNo] = useState('No');

    const [broker, Setbroker] = useState('');
    const [underwriter, setUnderwriter] = useState('Self Insured');
    const [insuredName, setInsuredName] = useState('');
    const [insuredPolicyNo, setInsuredPolicyNo] = useState('');
    const [insuredContactNo, setInsuredContactNo] = useState('');
    const [insuredEmailAddress, setInsuredEmailAddress] = useState('');
    const [insuredLandlineNo, setInsuredLandlineNo] = useState('');
    const [vehicleRegistration, setVehicleRegistration] = useState('');
    const [breakageDate, setbreakageDate] = useState(new Date().toISOString().split('T')[0]);
    const [driverName, setDriverName] = useState('');
    const [witnessName, setWitnessName] = useState('');
    const [newClaimNote, setNewClaimNote] = useState('');

    const [vin_disc, setVINDisc] = useState([]);
    const [vehicle_front, setVehicleFront] = useState([]);
    const [drivers_license, setDriversLicense] = useState([]);
    const [scensors, setScensors] = useState([]);
    const [damage_photos, setDamagePhotos] = useState([]);

    const [fileLoading, setFileLoading] = useState<any>({});
    const [modalOpen, setModalOpen] = useState(false);

    const [valid, setValid] = useState(true);
    const [emailMessage, setEmailMessage] = useState(" ");
    const [mobileMessage, setMobileMessage] = useState(" ");

    const [images, setImages] = useState([] as any);

    const collapseConfig = {
        show: 100,
        hide: 100,
    };

    const handleEmail = (event: { target: { value: any; }; }) => {
        const inputValue = event.target.value;
        setInsuredEmailAddress(inputValue);

        if (!validator.isEmail(inputValue)) {
            setEmailMessage("Please, enter a valid email!");
        } else {
            setEmailMessage("");
        }
    }

    const saveChanges = () => {
        const errorField = validateClaim();
        let updatedData = {};
        if (errorField === undefined) {
            updatedData = {
                files: {
                    vin_disc,
                    vehicle_front,
                    drivers_license,
                    scensors,
                    damage_photos
                },
                newClaimNote: newClaimNote,
                whatsappinsurer: {
                    broker: userProfile.partnerId === 'Autoboys' ? broker : userProfile.partnerId,
                    causeOfBreakage: breakageCause,
                    customerEmail: insuredEmailAddress,
                    incidentDate: breakageDate,
                    isDriversNameSameInsured: isDriverNameSame,
                    isLandlineNumberExist: isLandlineNo,
                    isMultipleDamagedGlasses: damage_photos.length > 0 ? 'No' : 'Yes',
                    isWitnessToIncident: isWitness,

                    landlineNumber: insuredLandlineNo,
                    driversName: driverName,
                    registrationNumber: vehicleRegistration,
                    mobileNumber: '+' + insuredContactNo,
                    name: insuredName,
                    witnessName: witnessName,
                    underwriter: underwriter,
                    policyNumber: insuredPolicyNo,
                    excessValue: 0,
                }
            }
            return updatedData;
        } else {
            return toast.error(throwMessage(`${errorField}`), {
                autoClose: 3000,
                closeButton: <Button className="Toastify__close-button">Dismiss</Button>,
            });
        }
    };

    const newClaimModal = {
        whatsappinsurer: {
            broker: userProfile.partnerId,
            underwriter: underwriter,
            name: insuredName,
            customerEmail: insuredEmailAddress,
            mobileNumber: insuredContactNo,
            registrationNumber: vehicleRegistration,
            causeOfBreakage: breakageCause,
            incidentDate: breakageDate,
        }
    }
    const onDropFile = (files: any, id: any) => {
        let response = false;
        whatsappUploadFile(id, { files, key: 'vehicleImages' }, (err: Error) => {
            setFileLoading(false);
            if (err) {
                console.error(err);
                response = false;
            }
            response = true;
        });
        return response;
    };

    const submitClaim = () => {
        const claim = saveChanges();
        state.loading = true;
        setState({ ...state, loading: true });
        submitNewClaim(claim, (data: any, err: Error) => {
            // console.log(data._path.segments[1]);
            // climId = data._path.segments[1];

            if (err) {
                console.error(err);
                toast.error('Could not create claim. Please try again later');
                return false;
            }
            state.loading = false;
            setState({ ...state, loading: false });
            state.saving = false;
            setState({ ...state, saving: false });
            state.hasChanges = false;
            setState({ ...state, hasChanges: false });
            state.isDisabled = true;
            setState({ ...state, isDisabled: true });
            resetClaim();
            history.push('/');
            return true;
        });
    };

    const throwMessage = (field: {} | null | undefined) => {
        const errorMessage = (
            <div className="toast-message">
                <Icon icon={checkSquare} size={36} /> Kindly fill the mandetory data - {field}.
            </div>
        );
        return errorMessage;
    }

    const validateClaim = () => {
        let fieldName = '';
        if (_.isEmpty(insuredName)) {
            fieldName = 'Insured Name';
            return fieldName;
        }
        if (_.isEmpty(insuredContactNo)) {
            fieldName = 'Insured Contact No';
            return fieldName;
        }
        if (isLandlineNo === 'Yes') {
            if (_.isEmpty(insuredLandlineNo)) {
                fieldName = 'Insured Landline No';
                return fieldName;
            }
        }
        if (_.isEmpty(vehicleRegistration)) {
            fieldName = 'Vehicle Registration';
            return fieldName;
        }
        if (_.isEmpty(breakageDate)) {
            fieldName = 'Date Of Breakage';
            return fieldName;
        }
        if (_.isEmpty(breakageCause)) {
            fieldName = 'Cause Of Breakage';
            return fieldName;
        }
        if (isDriverNameSame === 'No') {
            if (_.isEmpty(driverName)) {
                fieldName = 'Driver Name';
                return fieldName;
            }
        }
        if (isWitness === 'Yes') {
            if (_.isEmpty(witnessName)) {
                fieldName = 'Witness Name';
                return fieldName;
            }
        }
    };

    const onCancel = () => {
        history.push('/');
    };

    const confirmSubmit = () => {
        state.saving = true;
        setState({ ...state, saving: true });
        state.hasChanges = true;
        setState({ ...state, hasChanges: true });
        state.isDisabled = true;
        setState({ ...state, isDisabled: true });
        submitClaim();
    };

    const cancelSubmit = () => {
        setModalOpen(false);
    };

    const showModal = async () => {
        setModalOpen(true);
    }

    const reset = () => {
        // setCost(0);
        // setTotalCost(0);
        setUnderwriter('Self Insured');
        setInsuredName('');
        setInsuredPolicyNo('');
        setInsuredContactNo('');
        setInsuredEmailAddress('');
        SetisLandlineNo('No');
        setInsuredLandlineNo('');
        setVehicleRegistration('');
        setbreakageDate(new Date().toISOString().split('T')[0]);
        setBreakageCause('Stone Damage');
        SetisDriverNameSame('Yes');
        setDriverName('');
        SetisWitness('No');
        setWitnessName('');
        setNewClaimNote('');
        state.isDisabled = false;
        state.hasChanges = false;
    };

    const handlePhoneChange = (value: any) => {
        state.isDisabled = false;
        setInsuredContactNo(value);
        setValid(validatePhoneNumber(value));
        if (!validator.isMobilePhone(value)) {
            setMobileMessage("Please, enter a valid mobile number!");
        } else {
            setMobileMessage("");
        }
    };

    const validatePhoneNumber = (insuredContactNo: string) => {
        const phoneNumberPattern = /^\?[1-9]\d{1,8}$/;
        return phoneNumberPattern.test(insuredContactNo);
    };

    const onDropFileVINDisc = (files: any): void => {
        // images.push(files);

        setVINDisc(files);
        // vin_disc.push(files);
        updateNewClaimValue('files', 'vehicleImages', files);
    };

    const onDropFileVehicleFront = (files: any): void => {
        setVehicleFront(files);
        updateNewClaimValue('files', 'vehicleImages', files);
    };

    const onDropFileDriversLicense = (files: any): void => {
        setDriversLicense(files);
        updateNewClaimValue('files', 'vehicleImages', files);
    };

    const onDropFileScensors = (files: any): void => {
        setScensors(files);
        updateNewClaimValue('files', 'vehicleImages', files);
    };

    const onDropFileDamagePhotos = (files: any): void => {
        setDamagePhotos(files);
        updateNewClaimValue('files', 'vehicleImages', files);
    };

    const showLoaderTrash = (): ReactElement | false => {
        return <Icon icon={trash2} size={16} />;
    };

    const deleteFilesVinDisc = () => {
        setModalOpen(false);
        setVINDisc([]);
        updateNewClaimValue('files', 'vehicleImages', []);
    };

    const deleteFilesVehicleFront = () => {
        setModalOpen(false);
        setVehicleFront([]);
        updateNewClaimValue('files', 'vehicleImages', []);
    };

    const deleteFilesDriversLicense = () => {
        setModalOpen(false);
        setDriversLicense([]);
        updateNewClaimValue('files', 'vehicleImages', []);
    };

    const deleteFilesScensors = () => {
        setModalOpen(false);
        setScensors([]);
        updateNewClaimValue('files', 'vehicleImages', []);
    };

    const deleteFilesDamagePhotos = () => {
        setModalOpen(false);
        setDamagePhotos([]);
        updateNewClaimValue('files', 'vehicleImages', []);
    };

    const handleChangeVehicleMake = (event: { target: { value: any; }; }) => {
        setVehicleMake(event.target.value);
    };

    const handleChangeVehicleModel = (event: { target: { value: any; }; }) => {
        setVehicleModel(event.target.value);
    };

    const addTableRows = () => {
        const rowsInput = {
            partNumber: '',
            partDescription: '',
            cost: 0
        }
        setRowsData([...rowsData, rowsInput])
        const randumNumber = Math.floor(Math.random() * (maxVal - minVal + 1) + minVal);
        setQuoteNumber(randumNumber);
    }

    const deleteTableRows = (index: any) => {
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
        let grandTotal = 0;
        if (rows.length !== 0) {
            for (let i = 0; i < rows.length; i++) {
                if (rows[i].cost !== 0 && rows[i].cost !== '') {

                    grandTotal = grandTotal + Number(rows[i].cost);
                    setTotalCost(grandTotal);
                }
            }
        } else {
            setTotalCost(0);
            state.isDisabled = false;
            state.hasChanges = false;
        }
    }

    const handleChange = (index: any, evnt: any) => {
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);

        for (let i = 0; i < rowsInput.length; i++) {
            if (rowsInput[i].cost !== 0 && rowsInput[i].cost !== '') {
                setTotalCost(grandTotal(rowsInput[i].cost));
            }
        }
        state.isDisabled = true;
        state.hasChanges = true;
    }

    const grandTotal = () => {
        let grandTotal = 0;
        const rowTotals = rowsData.map(
            (row: { cost: any; }) => (row.cost) || 0
        );
        if (rowTotals.length > 0) {
            for (let j = 0; j < rowTotals.length; j++) {
                grandTotal = Number(grandTotal) + Number(rowTotals[j]);
            }
            //   grandTotal = rowTotals.reduce((acc, val) => acc + val);
        }
        return Number(grandTotal) + Number(cost);
    };

    const handleCustomerNameChange = (event: { target: { value: any; }; }) => {
        const inputValue = event.target.value.replace(/[^a-zA-Z ]/g, "");
        setInsuredName(inputValue);
    }

    const renderClaim = () => {
        const profile = userProfile;
        return (
            <><div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Quick Claims | Manual Claims</title>
                </Helmet>
            </div>
                <div style={{ marginLeft: '35px', marginTop: '10px', width: '100%' }}>
                    <h5 className="form-title">Manual Claim </h5> Fields marked with <span className="text-danger">*</span> are required.
                </div>
                <hr></hr>
                <div style={{ paddingTop: '0%', paddingLeft: '18%', paddingRight: '15%', width: '100%', height: '50%' }}>
                    <Button
                        className="accordion-btn"
                        outline={insuredChatOpen ? false : true}
                        size="sm"
                        onClick={() => {
                            setInsuredChatOpen(!insuredChatOpen);
                            setInsuredEditOpen(!insuredEditOpen);
                        }}>
                        <div>
                            Insured Info<Icon icon={insuredChatOpen ? chevronUp : chevronDown} size={20} />{' '}
                        </div>
                    </Button>
                    <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={insuredChatOpen}>
                        <div className="claim-group">
                            <Table className="sidepanel-info-table table table-borderless">
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="broker">
                                                <div>
                                                    Broker <span className="text-danger">*</span>
                                                </div>
                                            </Label>
                                            {profile.partnerId !== undefined && profile !== undefined ?
                                                profile.partnerId === 'Autoboys' || profile.partnerId === 'Admin' ?
                                                    <select
                                                        required
                                                        id="broker"
                                                        className="form-control"
                                                        style={{
                                                            border: '1px groove #00A36C',
                                                            fontWeight: 'bold',
                                                        }}
                                                        value={broker}
                                                        onChange={(event) => {
                                                            Setbroker(
                                                                event.target.value,
                                                            );
                                                        }}
                                                    >
                                                        {/* <option value={0}>Select...</option> */}                                                        
                                                        <option value="Autoboys" key="Autoboys">Autoboys</option>
                                                        <option value="Alpha Direct Insurance Co" key="Alpha Direct Insurance Co">Alpha Direct Insurance Co</option>
                                                        <option value="BBS Insurance Agency Limited" key="BBS Insurance Agency Limited">BBS Insurance Agency Limited</option>
                                                        <option value="BOC" key="BOC">BOC</option>
                                                        <option value="BOTSURE" key="BOTSURE">BOTSURE</option>
                                                        <option value="Botswana Insurance Company" key="Botswana Insurance Company">Botswana Insurance Company</option>
                                                        <option value="CHARTERED" key="CHARTERED">CHARTERED</option>
                                                        <option value="COMPLETE" key="COMPLETE">COMPLETE</option>
                                                        <option value="DYNAMIC" key="DYNAMIC">DYNAMIC</option>
                                                        <option value="EXCLUSIVE" key="EXCLUSIVE">EXCLUSIVE</option>
                                                        <option value="FINSEF" key="FINSEF">FINSEF</option>
                                                        <option value="FIRST SUN" key="FIRST SUN">FIRST SUN</option>
                                                        <option value="FNB" key="FNB">FNB</option>
                                                        <option value="GARY JOHNS" key="GARY JOHNS">GARY JOHNS</option>
                                                        <option value="GEMVAS" key="GEMVAS">GEMVAS</option>
                                                        <option value="Hollard Insurance" key="Hollard Insurance">Hollard Insurance</option>
                                                        <option value="INERCA" key="INERCA">INERCA</option>
                                                        <option value="JOHANN BURGER" key="JOHANN BURGER">JOHANN BURGER</option>
                                                        <option value="KEHUMILE" key="KEHUMILE">KEHUMILE</option>
                                                        <option value="KGARE" key="KGARE">KGARE</option>
                                                        <option value="KGARE-FTOWN" key="KGARE-FTOWN">KGARE-FTOWN</option>
                                                        <option value="KIB" key="KIB">KIB</option>
                                                        <option value="LETSEMA" key="LETSEMA">LETSEMA</option>
                                                        <option value="MARSH" key="MARSH">MARSH</option>
                                                        <option value="MERCANTILE" key="MERCANTILE">MERCANTILE</option>
                                                        <option value="MINET" key="MINET">MINET</option>
                                                        <option value="MOGAKOLODI" key="MOGAKOLODI">MOGAKOLODI</option>
                                                        <option value="NAALANO" key="NAALANO">NAALANO</option>
                                                        <option value="Old Mutual" key="Old Mutual">Old Mutual</option>
                                                        <option value="PENRICH" key="PENRICH">PENRICH</option>
                                                        <option value="SAKARI" key="SAKARI">SAKARI</option>
                                                        <option value="SALEYS" key="SALEYS">SALEYS</option>
                                                        <option value="SATIB" key="SATIB">SATIB</option>
                                                        <option value="SPECTRUM" key="SPECTRUM">SPECTRUM</option>
                                                    </select>
                                                    :
                                                    <Input
                                                        id="broker"
                                                        name="broker"
                                                        placeholder="Enter broker"
                                                        type="text"
                                                        required
                                                        className="form-control"
                                                        style={{ border: '1px groove #00A36C', fontWeight: 'bold' }}
                                                        disabled={true}
                                                        readOnly={true}
                                                        value={profile.partnerId}
                                                        onChange={(event) => {
                                                            Setbroker(
                                                                profile.partnerId,
                                                            );
                                                        }}
                                                    />
                                                :
                                                null}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="underwriter">
                                                <div>
                                                    Underwriter<span className="text-danger">*</span>
                                                </div>
                                            </Label>
                                            <select
                                                required
                                                id="underwriter"
                                                className="form-control"
                                                style={{
                                                    backgroundColor: 'Azure',
                                                    border: '1px groove #00A36C',
                                                    fontWeight: 'bold',
                                                }}
                                                value={underwriter}
                                                onChange={(event) => {
                                                    setUnderwriter(
                                                        event.target.value,
                                                    );
                                                }}
                                            >
                                                <option value="Botswana Insurance Company" key="Botswana Insurance Company">Botswana Insurance Company</option>
                                                <option value="Hollard Insurance" key="Hollard Insurance">Hollard Insurance</option>
                                                <option value="Alpha Direct Insurance Co" key="Alpha Direct Insurance Co">Alpha Direct Insurance Co</option>
                                                <option value="Old Mutual" key="Old Mutual">Old Mutual</option>
                                                <option value="Self Insured" key="Self Insured">Self Insured</option>

                                                {profile.partnerId !== undefined && profile !== undefined ?
                                                    profile.partnerId === 'EXCLUSIVE' || profile.partnerId === 'Autoboys' || profile.partnerId === 'Admin' ?
                                                        <option value="GEMVAS" key="GEMVAS">GEMVAS</option>
                                                        :
                                                        null
                                                    :
                                                    null}
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="insuredName">
                                                <div>
                                                    Insured/Customer Name<span className="text-danger">*</span>
                                                </div>
                                            </Label>
                                            <Input
                                                id="insuredName"
                                                name="insuredName"
                                                placeholder="Enter insured name"
                                                type="text"
                                                className="form-control"
                                                style={{
                                                    backgroundColor: 'Azure',
                                                    border: '1px groove #00A36C',
                                                    fontWeight: 'bold',
                                                }}
                                                value={insuredName}
                                                onChange={handleCustomerNameChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="insuredPolicyNo">
                                                <div>
                                                    Insured/Customer Policy No. {/*<span className="text-danger">*</span> */}
                                                </div>
                                            </Label>
                                            <Input
                                                id="insuredPolicyNo"
                                                name="insuredPolicyNo"
                                                placeholder="Enter insured policy no"
                                                type="text"
                                                className="form-control"
                                                style={{
                                                    backgroundColor: 'Azure',
                                                    border: '1px groove #00A36C',
                                                    fontWeight: 'bold',
                                                }}
                                                value={insuredPolicyNo}
                                                onChange={(event) => {
                                                    setInsuredPolicyNo(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="insuredContactNo">
                                                <div>
                                                    Insured/Customer preferred Contact No.<span className="text-danger">*</span>
                                                </div>
                                            </Label>
                                            <PhoneInput
                                                placeholder="Enter phone number"
                                                country={'bw'}
                                                value={insuredContactNo}
                                                onChange={handlePhoneChange} />
                                            <div style={{ color: "red" }}> {mobileMessage} </div>
                                            {/* <Input
                                                id="insuredContactNo"
                                                name="insuredContactNo"
                                                placeholder="Enter insured contact no"
                                                type="number"
                                                className="form-control"
                                                style={{
                                                    backgroundColor: 'Azure',
                                                    border: '1px groove #00A36C',
                                                    fontWeight: 'bold',
                                                }}
                                                value={insuredContactNo}
                                                onChange={(event) => {
                                                    setInsuredContactNo(
                                                        event.target.value,
                                                    );
                                                }}
                                            /> */}
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="insuredEmailAddress">
                                                <div>
                                                    Insured/Customer Email Address{/*<span className="text-danger">*</span> */}
                                                </div>
                                            </Label>
                                            <Input
                                                id="insuredEmailAddress"
                                                name="insuredEmailAddress"
                                                placeholder="Enter insured email address"
                                                type="email"
                                                className="form-control"
                                                style={{
                                                    backgroundColor: 'Azure',
                                                    border: '1px groove #00A36C',
                                                    fontWeight: 'bold',
                                                }}
                                                value={insuredEmailAddress}
                                                onChange={handleEmail}
                                            />
                                            <div style={{ color: "red" }}> {emailMessage} </div>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="isLandlineNo">
                                                <div>
                                                    Is Insured/Customer have Landline No.<span className="text-danger">*</span>
                                                </div>
                                            </Label>
                                            <select
                                                required
                                                id="isLandlineNo"
                                                className="form-control"
                                                style={{
                                                    backgroundColor: 'Azure',
                                                    border: '1px groove #00A36C',
                                                    fontWeight: 'bold',
                                                }}
                                                value={isLandlineNo}
                                                onChange={(event) => {
                                                    SetisLandlineNo(
                                                        event.target.value,
                                                    );
                                                }}
                                            >
                                                {/* <option value={0}>Select...</option> */}
                                                <option value="No" key="No">No</option>
                                                <option value="Yes" key="Yes">Yes</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    {isLandlineNo === 'Yes' ?
                                        (
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="insuredLandlineNo">
                                                        <div>
                                                            Insured/Customer Landline No.<span className="text-danger">*</span>
                                                        </div>
                                                    </Label>
                                                    <Input
                                                        id="insuredLandlineNo"
                                                        name="insuredLandlineNo"
                                                        placeholder="Enter insured landline no"
                                                        type="text"
                                                        className="form-control"
                                                        style={{
                                                            backgroundColor: 'Azure',
                                                            border: '1px groove #00A36C',
                                                            fontWeight: 'bold',
                                                        }}
                                                        value={insuredLandlineNo}
                                                        onChange={(event) => {
                                                            setInsuredLandlineNo(
                                                                event.target.value,
                                                            );
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )
                                        : null
                                    }
                                </Row>
                            </Table>
                        </div>
                    </Collapse>

                    <Button
                        className="accordion-btn"
                        outline={incidentEditOpen ? false : true}
                        size="sm"
                        onClick={() => {
                            setIncidentEditOpen(!incidentEditOpen);
                            setIncidentChatOpen(!incidentChatOpen);
                        }}>
                        <div>
                            Incident Details<Icon icon={incidentEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                        </div>
                    </Button>
                    <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={incidentEditOpen}>
                        <div className="claim-group">
                            <Table className="sidepanel-info-table table table-borderless">
                                <FormGroup>
                                    <Label for="vehicleRegistration">
                                        <div>
                                            Vehicle Registration<span className="text-danger">*</span>
                                        </div>
                                    </Label>
                                    <Input
                                        id="vehicleRegistration"
                                        name="vehicleRegistration"
                                        placeholder="Enter vehicle registration no"
                                        type="text"
                                        className="form-control"
                                        style={{
                                            backgroundColor: 'Azure',
                                            border: '1px groove #00A36C',
                                            fontWeight: 'bold',
                                        }}
                                        value={vehicleRegistration}
                                        onChange={(event) => {
                                            setVehicleRegistration(
                                                event.target.value,
                                            );
                                        }}
                                    />
                                </FormGroup>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="breakageDate">
                                                <div>
                                                    Date of Breakage<span className="text-danger">*</span>
                                                </div>
                                            </Label>
                                            <Input
                                                id="breakageDate"
                                                type='date'
                                                name="breakageDate"
                                                className="form-control"
                                                // min={new Date().toISOString().split('T')[0]}
                                                style={{
                                                    backgroundColor: 'Azure',
                                                    border: '1px groove #00A36C',
                                                    fontWeight: 'bold',
                                                }}
                                                value={breakageDate}
                                                onChange={(event) => {
                                                    setbreakageDate(
                                                        event.target.value,
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="breakageCause">
                                                <div>
                                                    Cause of Breakage<span className="text-danger">*</span>
                                                </div>
                                            </Label>
                                            <select
                                                required
                                                id="breakageCause"
                                                className="form-control"
                                                style={{
                                                    backgroundColor: 'Azure',
                                                    border: '1px groove #00A36C',
                                                    fontWeight: 'bold',
                                                }}
                                                value={breakageCause}
                                                onChange={(event) => {
                                                    setBreakageCause(
                                                        event.target.value,
                                                    );
                                                }}
                                            >
                                                {/* <option value={0}>Select...</option> */}
                                                <option value="Stone Damage" key="Stone Damage">Stone Damage</option>
                                                <option value="Smash & Grab" key="Smash & Grab">Smash & Grab</option>
                                                <option value="Collision" key="Collision">Collision</option>
                                                <option value="Vandalism" key="Vandalism">Vandalism</option>
                                                <option value="Hail Damage" key="Hail Damage">Hail Damage</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="isDriverNameSame">
                                                <div>
                                                    Is the drivers name same as the insured?<span className="text-danger">*</span>
                                                </div>
                                            </Label>
                                            <select
                                                required
                                                id="isDriverNameSame"
                                                className="form-control"
                                                style={{
                                                    backgroundColor: 'Azure',
                                                    border: '1px groove #00A36C',
                                                    fontWeight: 'bold',
                                                }}
                                                value={isDriverNameSame}
                                                onChange={(event) => {
                                                    SetisDriverNameSame(
                                                        event.target.value,
                                                    );
                                                }}
                                            >
                                                {/* <option value={0}>Select...</option> */}
                                                <option value="No" key="No">No</option>
                                                <option value="Yes" key="Yes">Yes</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="isWitness">
                                                <div>
                                                    Was there a witness to the incident?<span className="text-danger">*</span>
                                                </div>
                                            </Label>
                                            <select
                                                required
                                                id="isWitness"
                                                className="form-control"
                                                style={{
                                                    backgroundColor: 'Azure',
                                                    border: '1px groove #00A36C',
                                                    fontWeight: 'bold',
                                                }}
                                                value={isWitness}
                                                onChange={(event) => {
                                                    SetisWitness(
                                                        event.target.value,
                                                    );
                                                }}
                                            >
                                                {/* <option value={0}>Select...</option> */}
                                                <option value="No" key="No">No</option>
                                                <option value="Yes" key="Yes">Yes</option>
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    {isDriverNameSame === 'No' ?
                                        (
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="driverName">
                                                        <div>
                                                            Please enter the driver name<span className="text-danger">*</span>
                                                        </div>
                                                    </Label>
                                                    <Input
                                                        id="driverName"
                                                        name="driverName"
                                                        placeholder="Enter driver name"
                                                        type="text"
                                                        className="form-control"
                                                        style={{
                                                            backgroundColor: 'Azure',
                                                            border: '1px groove #00A36C',
                                                            fontWeight: 'bold',
                                                        }}
                                                        value={driverName}
                                                        onChange={(event) => {
                                                            setDriverName(
                                                                event.target.value,
                                                            );
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>)
                                        : <Col md={6}></Col>}
                                    {isWitness === 'Yes' ?
                                        (
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="witnessName">
                                                        <div>
                                                            Please enter witness name<span className="text-danger">*</span>
                                                        </div>
                                                    </Label>
                                                    <Input
                                                        id="witnessName"
                                                        name="witnessName"
                                                        placeholder="Enter witness name"
                                                        type="text"
                                                        className="form-control"
                                                        style={{
                                                            backgroundColor: 'Azure',
                                                            border: '1px groove #00A36C',
                                                            fontWeight: 'bold',
                                                        }}
                                                        value={witnessName}
                                                        onChange={(event) => {
                                                            setWitnessName(
                                                                event.target.value,
                                                            );
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>)
                                        : <Col md={6}></Col>}
                                </Row>
                            </Table>
                        </div>
                    </Collapse>

                    <Button
                        className="accordion-btn"
                        outline={imagesEditOpen ? false : true}
                        size="sm"
                        onClick={() => {
                            setImagesEditOpen(!imagesEditOpen);
                            setImagesChatOpen(!imagesChatOpen);
                        }}>
                        <div>
                            Images<Icon icon={imagesEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                        </div>
                    </Button>
                    <Collapse delay={collapseConfig} className="edit-info-collapse" isOpen={imagesEditOpen}>
                        <div className="claim-group">
                            <Table className="sidepanel-info-table table table-borderless">
                                <Row>
                                    <Col md={6}>
                                        <Label><div>
                                            VIN Disc {/* <span className="text-danger">*</span> */}
                                        </div>  </Label>
                                        {!vin_disc.length ? (
                                            <FileDropZone
                                                loading={false}
                                                onError={toast.error}
                                                hideText={false}
                                                onDrop={onDropFileVINDisc}
                                                className="todo-drop-file" isDisabled={false} />
                                        ) : (
                                            <div className="files-attached-div">
                                                <span className="text">{vin_disc.length} Files Attached</span>
                                                <div className="icons-div">
                                                    <Button
                                                        className="file-single trash"
                                                        color="link"
                                                        onClick={deleteFilesVinDisc}
                                                        size="sm">
                                                        {showLoaderTrash()}
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        <Label><div>
                                            Vehicle Front {/* <span className="text-danger">*</span> */}
                                        </div></Label>
                                        {/* <FileDropZone
                                            loading={false}
                                            onError={toast.error}
                                            hideText={false}
                                            onDrop={onDropFileVehicleFront}
                                            className="todo-drop-file" isDisabled={false} />

                                        <div className="files-attached-div">
                                            <span className="text">{vehicle_front.length} Files Attached</span>
                                            <div className="icons-div">
                                                <Button
                                                    className="file-single trash"
                                                    color="link"
                                                    onClick={deleteFilesVehicleFront}
                                                    size="sm">
                                                    {showLoaderTrash()}
                                                </Button>
                                            </div>
                                        </div> */}
                                        {!vehicle_front.length ? (
                                            <FileDropZone
                                                loading={false}
                                                onError={toast.error}
                                                hideText={false}
                                                onDrop={onDropFileVehicleFront}
                                                className="todo-drop-file" isDisabled={false} />
                                        ) : (
                                            <div className="files-attached-div">
                                                <span className="text">{vehicle_front.length} Files Attached</span>
                                                <div className="icons-div">
                                                    <Button
                                                        className="file-single trash"
                                                        color="link"
                                                        onClick={deleteFilesVehicleFront}
                                                        size="sm">
                                                        {showLoaderTrash()}
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Row>
                                    <Col md={6}>
                                        <Label><div>
                                            Drivers License {/* <span className="text-danger">*</span> */}
                                        </div></Label>
                                        {/* <FileDropZone
                                            loading={false}
                                            onError={toast.error}
                                            hideText={false}
                                            onDrop={onDropFileDriversLicense}
                                            className="todo-drop-file" isDisabled={false} />

                                        <div className="files-attached-div">
                                            <span className="text">{drivers_license.length} Files Attached</span>
                                            <div className="icons-div">
                                                <Button
                                                    className="file-single trash"
                                                    color="link"
                                                    onClick={deleteFilesDriversLicense}
                                                    size="sm">
                                                    {showLoaderTrash()}
                                                </Button>
                                            </div>
                                        </div> */}
                                        {!drivers_license.length ? (
                                            <FileDropZone
                                                loading={false}
                                                onError={toast.error}
                                                hideText={false}
                                                onDrop={onDropFileDriversLicense}
                                                className="todo-drop-file" isDisabled={false} />
                                        ) : (
                                            <div className="files-attached-div">
                                                <span className="text">{drivers_license.length} Files Attached</span>
                                                <div className="icons-div">
                                                    <Button
                                                        className="file-single trash"
                                                        color="link"
                                                        onClick={deleteFilesDriversLicense}
                                                        size="sm">
                                                        {showLoaderTrash()}
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                    <Col md={6}>
                                        <Label><div>
                                            Sensor {/* <span className="text-danger">*</span> */}
                                        </div></Label>
                                        {/* <FileDropZone
                                            loading={false}
                                            onError={toast.error}
                                            hideText={false}
                                            onDrop={onDropFileScensors}
                                            className="todo-drop-file" isDisabled={false} />

                                        <div className="files-attached-div">
                                            <span className="text">{scensors.length} Files Attached</span>
                                            <div className="icons-div">
                                                <Button
                                                    className="file-single trash"
                                                    color="link"
                                                    onClick={deleteFilesScensors}
                                                    size="sm">
                                                    {showLoaderTrash()}
                                                </Button>
                                            </div>
                                        </div> */}
                                        {!scensors.length ? (
                                            <FileDropZone
                                                loading={false}
                                                onError={toast.error}
                                                hideText={false}
                                                onDrop={onDropFileScensors}
                                                className="todo-drop-file" isDisabled={false} />
                                        ) : (
                                            <div className="files-attached-div">
                                                <span className="text">{scensors.length} Files Attached</span>
                                                <div className="icons-div">
                                                    <Button
                                                        className="file-single trash"
                                                        color="link"
                                                        onClick={deleteFilesScensors}
                                                        size="sm">
                                                        {showLoaderTrash()}
                                                    </Button>
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Row>
                                    <Col md={6}>
                                        <Label><div>
                                            Damage Windscreen/Glass Photos {/* <span className="text-danger">*</span> */}
                                        </div></Label>
                                        <FileDropZone
                                            loading={false}
                                            onError={toast.error}
                                            hideText={false}
                                            onDrop={onDropFileDamagePhotos}
                                            className="todo-drop-file" isDisabled={false} />

                                        <div className="files-attached-div">
                                            <span className="text">{damage_photos.length} Files Attached</span>
                                            <div className="icons-div">
                                                <Button
                                                    className="file-single trash"
                                                    color="link"
                                                    onClick={deleteFilesDamagePhotos}
                                                    size="sm">
                                                    {showLoaderTrash()}
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </Table>
                        </div>
                    </Collapse>

                    <Button
                        className="accordion-btn"
                        outline={noteEditOpen ? false : true}
                        size="sm"
                        onClick={() => {
                            setNoteEditOpen(!noteEditOpen);
                            setNoteChatOpen(!noteChatOpen);
                        }}>
                        <div>
                            Claim Notes <Icon icon={noteEditOpen ? chevronUp : chevronDown} size={20} />{' '}
                        </div>
                    </Button>
                    <Collapse delay={collapseConfig} isOpen={noteEditOpen}>
                        <div className="static">
                            <div className="field-limit-indicator" style={{ width: '100%' }}>
                                {_.size(newClaimNote)}/{250}

                                <Input
                                    id="newClaimNote"
                                    name="newClaimNote"
                                    placeholder="Enter note here..."
                                    maxLength={250}
                                    type="textarea"
                                    className="form-control"
                                    style={{
                                        backgroundColor: 'Azure',
                                        border: '1px groove #00A36C',
                                        fontWeight: 'bold',
                                    }}
                                    value={newClaimNote}
                                    onChange={(event) => {
                                        setNewClaimNote(
                                            event.target.value,
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </Collapse>
                    <div style={{ paddingTop: '9%', paddingLeft: '25%', paddingRight: '15%', width: '100%', justifyContent: 'space-between' }}>

                        <div className="section-warnings">
                            <p className="text-danger">
                                Please complete the following sections before submitting your claim:
                            </p>
                            <p>
                                <Badge className="section-warning-badge capitalize">
                                    {'Insured Info'}
                                </Badge>
                                <Badge className="section-warning-badge capitalize">
                                    {'Incident Details'}
                                </Badge>
                            </p>
                        </div>
                        <Button
                            color="secondary"
                            size="sm"
                            onClick={confirmSubmit}
                            disabled={state.isDisabled}>
                            {state.saving && state.hasChanges ? 'Creating Claim...' : 'Create Claim'}
                        </Button>
                        {' '}
                        <Button color="secondary" size="sm" onClick={reset} disabled={state.hasChanges}>
                            Reset
                        </Button>
                        {' '}
                        <Button color="secondary" size="sm" onClick={onCancel}>Cancel</Button>
                    </div>
                </div>
            </>
        );
    };

    return (
        renderClaim()
    );
};

export default connect(null, {
    updateNewClaimValue,
    initializeNewClaimValue,
    submitNewClaim,
    resetClaim,
    whatsappUploadFile,
    removeFiles,
})(ManualClaim);
