/* eslint-disable prettier/prettier */
import '../../styles/LoadingPage.scss';
import Helmet from 'react-helmet';
import Loader from 'react-loaders';
import React from 'react';
import qclaim from '../../images/QC.png';

const LoadingPage: React.FC = () => {
  return (
    <div className="fill flex center loading-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Loading...</title>
      </Helmet>
      <div className="loading-container">
        <img src={qclaim} className="logo loading" alt="logo" />
        <Loader type="ball-pulse-sync" active={true} />
      </div>
    </div>
  );
};

export default LoadingPage;
