/* eslint-disable prettier/prettier */
import {
  CURRENCY_CHANGE_OBJ,
  CURRENCY_UPDATE_VALUE,
  NC_INIT_VALUE,
  NC_RESET_CLAIM,
  NC_UPDATE_VALUE,
} from './types';
import { IClaimGroup } from '../../components/claims/new/typings';
// import { INewClaimPayload } from '../../../../typings';
import {IWhatsappClaimGroup} from '../../components/claims/whatsapp/typings'
import { IWhatsappClaimPayload } from '../../../../typings';
import { convertFirebaseDates } from './helpers/DateHelper';
import { functions } from '../../config/firebase';

export const updateNewClaimValue = (
  group: string,
  key: string,
  value: any,
  // validation: IWhatsappClaimGroup,
) => {
  return (dispatch: any) => {
    dispatch({
      type: NC_UPDATE_VALUE,
      payload: { group, key, value },
    });
  };
};

export const updateCurrencyValues = (key: string, value: any) => {
  return (dispatch: any) => {
    dispatch({
      type: CURRENCY_UPDATE_VALUE,
      payload: { key, value },
    });
  };
};

export const updateCurrencyChangeObj = (currencyChangeObj: any) => {
  return (dispatch: any) => {
    dispatch({
      type: CURRENCY_CHANGE_OBJ,
      payload: currencyChangeObj,
    });
  };
};

export const initializeNewClaimValue = (
  group: string,
  key: string,
  value: any,
  validation: IClaimGroup,
) => {
  return (dispatch: any) => {
    dispatch({
      type: NC_INIT_VALUE,
      payload: { group, key, value, validation },
    });
  };
};

export const resetClaim = (empty: string[]) => {
  return (dispatch: any) => {
    dispatch({ type: NC_RESET_CLAIM });
  };
};

export const submitNewClaim = (
  claim: IWhatsappClaimPayload,
  callback: Function,
) => {
  return (dispatch: any) => {
    const payload = convertFirebaseDates(claim);
    const createNewClaim = functions.httpsCallable('createManualClaim');
    createNewClaim({ ...payload })
    .then((data) => {
      callback(data.data);
    })
      .catch((err) => {
        console.error(err);
        console.error('FAILED');
        callback(err);
      });
  };
};
