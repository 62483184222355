/* eslint-disable prettier/prettier */
import { IWhatsappClaimGroup, WhatsappClaimFormProps } from '../whatsapp/typings';
import { Nav, TabContent } from 'reactstrap';
import ClaimFormSection from '../whatsapp/ClaimFormSection';
import FormNavItem from '../whatsapp/FormNavItem';
import React, { useState } from 'react';
import _ from 'lodash';

const WhatsappClaimForm: React.FC<WhatsappClaimFormProps> = ({
  whatsappConfig,
  whatsappClaim,
  partner,
}) => {
  const [activeTab, setActiveTab] = useState('0'); 

  const toggle = (tab: string) => {
    if (activeTab !== tab) setActiveTab(tab); 
  };

  const next = () => {
    // Clamp activeTab between max tabs and increment
    const maxTab = _.size(whatsappConfig) - 1;
    const nextTab: number = Number(activeTab) + 1;
    if (nextTab > maxTab) {
      return false;
    }
    return () => {
      setActiveTab(String(nextTab));
    };
  };
  const prev = () => {
    // Clamp activeTab between max tabs and 0
    const minTab = 0;
    const prevTab: number = Number(activeTab) - 1;
    if (prevTab < minTab) {
      return false;
    }
    return () => {
      setActiveTab(String(prevTab));
    };
  };
  const invalidGroups = _.keys(whatsappClaim.invalid);
  return (
    <div>
      <div>
        <h1 className="form-title">Whatsapp Journey</h1>
        <p className="lead subtitle">
          Fields marked with <span className="text-danger">*</span> are required.
        </p>
      </div>
      <hr></hr>
      {/* <Nav tabs>
        {_.map(whatsappConfig, (group: IWhatsappClaimGroup, index: number) => {
          return (
            <FormNavItem
              key={`nav-item-${index}`}
              group={group}
              valid={!_.includes(invalidGroups, group.key)}
              ready={!_.includes(invalidGroups, group.key) && _.includes(whatsappClaim.valid, group.key)}
              id={index}
              toggle={toggle}
              active={activeTab === String(index)}
            />
          );
        })}
      </Nav> */}
      <TabContent activeTab={activeTab}>
        {_.map(whatsappConfig, (group: IWhatsappClaimGroup, index: number) => {
          return (
            <ClaimFormSection
              key={`nav-item-${index}`}
              group={group}
              id={index}
              whatsappClaim={whatsappClaim}
              next={next()}
              prev={prev()}
              partner={partner}
            />
          );
        })}
      </TabContent>
    </div>
  );
};

export default WhatsappClaimForm;
