/* eslint-disable prettier/prettier */
/* eslint-disable import/named */
import '../../../styles/IncomingClaimPage.scss';
import { Button, Input } from 'reactstrap/lib';
import { DropdownOption } from '../new/typings';
import {
  FirebaseReducer,
  FirestoreReducer,
  ReduxFirestoreQueries,
  firestoreConnect,
  isLoaded,
} from 'react-redux-firebase';
import { IUser } from '../../../../../typings';
import { UserPageProps } from '../typings';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createUser, deleteUser, resetUserPassword } from '../../../actions/users';
import { edit, loader, refreshCw, trash2 } from 'react-icons-kit/feather';
import { toast } from 'react-toastify';
import EmptyState from '../empty-state/EmptyState';
import Helmet from 'react-helmet';
import Icon from 'react-icons-kit';
import LoadingPage from '../../loading/LoadingPage';
import React, { useState } from 'react';
import Select, { ValueType } from 'react-select';
import _ from 'lodash';

const UsersPage: React.FC<UserPageProps> = ({
  createUser,
  deleteUser,
  resetUserPassword,
  users,
  loading: loadingProp,
  partnerOptions,
}) => {
  const [email, setEmail] = useState('');
  const [partnerId, setPartnerId] = useState({ value: '', label: '' });
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState('');
  const [resetPasswordLoading, setResetPasswordLoading] = useState('');
  const [editPassword, setEditPassword] = useState('');

  const createNewUser = () => {
    if (partnerId.value === '') {
      toast.error('Select Valid Partner');
      return;
    }
    setLoading(true);
    createUser(email, partnerId.value, password, (response: any) => {
      if (response?.data?.errorInfo) {
        console.error(JSON.stringify(response?.data?.errorInfo?.message));
        toast.error(JSON.stringify(response?.data?.errorInfo?.message));
        setLoading(false);
        return;
      }
      if (typeof response.data === 'string') {
        toast.success('User created successfully.');
        setEditPassword('');
      }
      setLoading(false);
    });
  };

  const deleteUserFn = (id: string) => {
    setDeleteLoading(id);
    deleteUser(id, (response: any) => {
      if (response?.data?.errorInfo) {
        toast.error(JSON.stringify(response?.data?.errorInfo?.message));
        setDeleteLoading('');
        return;
      } else toast.success('User deleted successfully.');
      setDeleteLoading('');
    });
  };

  const resetPasswordUserFn = (id: string) => {
    if (newPassword !== '' && newPassword === confirmNewPassword) {
      setResetPasswordLoading(id);
      resetUserPassword(id, newPassword, (response: any) => {
        if (response?.data?.errorInfo) {
          toast.error(JSON.stringify(response?.data?.errorInfo?.message));
          setResetPasswordLoading('');
          return;
        } else toast.success(`Password reset done successfully. New password is ${newPassword}`);
        setResetPasswordLoading('');
      });
    } else {
      toast.error('Password field is not set correctly for the user selected');
    }
  };

  if (loadingProp) {
    return <LoadingPage />;
  }

  return (
    <div className="usersContainer">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Quick Claims | Users</title>
      </Helmet>
      <h5>Create New User</h5>
      <div className="create-user-form">
        <label>Email: </label>
        <Input
          value={email}
          placeholder="Email"
          className="user-input"
          onChange={(event) => setEmail(event.target.value)}
        />
        <label>Partner: </label>
        <Select
          styles={{
            control: (provided, state) => ({
              ...provided,
              width: '250px',
              marginRight: '40px',
            }),
            menu: (provided, state) => ({
              ...provided,
              width: '250px',
            }),
            menuList: (provided, state) => ({
              ...provided,
              width: '250px',
              overflowX: 'hidden',
              textAlign: 'start',
              zIndex: 99,
            }),
          }}
          options={partnerOptions}
          value={partnerId}
          onChange={(selectedOption: ValueType<DropdownOption>) => {
            setPartnerId(selectedOption);
          }}
        />
        <label>Password: </label>
        <Input
          type="password"
          value={password}
          placeholder="Password"
          className="user-input"
          onChange={(event) => setPassword(event.target.value)}
        />
        <Button onClick={createNewUser} className="user-btn">
          Add User {loading ? <Icon icon={loader} size={20} className="spin" /> : null}
        </Button>
      </div>
      {_.isEmpty(users) ? (
        <EmptyState message={'No users to show'} />
      ) : (
        <>
          <div className="table-all-header-user">
            <div>
              <div>User</div>
              <div>Partner Id</div>
              <div>Reset Password</div>
              <div>Delete</div>
            </div>
          </div>
          <div id="list-claims" className="all-users-list">
            {!_.isEmpty(users) && (
              <>
                {_.map(users, (user: IUser, id) => {
                  return (
                    user?.email && (
                      <div className="table-all-row-user">
                        <div> {user?.email} </div>
                        <div> {user?.partnerId} </div>
                        <div>
                          {editPassword === id ? (
                            <div>
                              <Input
                                type="password"
                                value={newPassword}
                                placeholder="Enter New Password"
                                className="password-input"
                                onChange={(event) => setNewPassword(event.target.value)}
                              />
                              <Input
                                type="password"
                                value={confirmNewPassword}
                                placeholder="Confirm New Password"
                                className="password-input"
                                onChange={(event) => setConfirmNewPassword(event.target.value)}
                              />
                              <div className="display-flex">
                                <Button
                                  onClick={resetPasswordUserFn.bind(this, id)}
                                  className="user-btn">
                                  Reset{' '}
                                  {resetPasswordLoading === id ? (
                                    <Icon icon={loader} size={20} className="spin" />
                                  ) : null}
                                </Button>
                                <Button
                                  outline
                                  onClick={() => setEditPassword('')}
                                  className="pass-btn">
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          ) : null}

                          {editPassword !== id ? (
                            <Icon
                              onClick={() => setEditPassword(id)}
                              className="reset-user"
                              icon={edit}
                              size={16}
                            />
                          ) : null}
                        </div>
                        <div>
                          {deleteLoading !== id ? (
                            <Icon
                              onClick={deleteUserFn.bind(this, id)}
                              className="trash-user"
                              icon={trash2}
                              size={16}
                            />
                          ) : (
                            <Icon icon={loader} size={16} className="spin user-loader" />
                          )}
                        </div>
                      </div>
                    )
                  );
                })}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

const mapQueryToProps = (): ReduxFirestoreQueries => {
  return [
    {
      collection: 'users',
      storeAs: 'users',
    },
    {
      collection: 'partners',
      storeAs: 'partners',
    },
  ];
};

const mapStateToProps = (state: any) => {
  const {
    firestore,
    firebase: { auth },
  }: {
    firestore: FirestoreReducer.Reducer;
    firebase: FirebaseReducer.Reducer;
  } = state;
  const users = firestore.data.users;
  const partnersData = firestore.data.partners;
  const partnerOptions: Array<{ value: string; label: string }> = [];
  _.map(partnersData, (partner, id) => {
    partnerOptions.push({ value: id, label: partner.name });
  });
  const loading = !isLoaded(users);
  return {
    loading,
    users,
    auth: auth as FirebaseReducer.AuthState,
    partnerOptions,
  };
};

export default compose(
  firestoreConnect(mapQueryToProps),
  connect(mapStateToProps, { createUser, deleteUser, resetUserPassword }),
)(UsersPage);
